import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useVerifyEmailForgetPasswordMutation } from "../../redux/services/DZapi";
import { useTranslation } from "react-i18next";

function VerifyEmailForgetPassword() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [verifyForgetCode, { isError , isLoading}] = useVerifyEmailForgetPasswordMutation();
  const { t } = useTranslation();
  const [data, setData] = useState({
    code: "",
    email: "", // Initialize with an empty string
  });

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setData({ ...data, email: storedEmail });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const verificationCodeSubmit = async (e) => {
    e.preventDefault();

    if (!data.code) {
      setShowPopup(true);
      setMessage("Please add your verification_code.");
    } else {
      try {
        const response = await verifyForgetCode({email: data.email ,  verification_code: data.code });
        if (response?.data?.success) {
          // Save email to local storage
          localStorage.setItem("verification_code", data?.code);
          navigate("/forgetPasswordNewPassword");
        } else if(response?.error) {
          setMessage(response?.error?.data?.message);
          setShowPopup(true);
        }
      } catch (error) {
        // Handle any error from your API call here
        console.error(error);
        setShowPopup(true);
        setMessage("Something went wrong. Please try again later.");
      }
    }
  };
  return (

    <>
      <div className="login__section">
        <div className="login__img">
          <img src="img/loginimg.png" alt="Food 3d" />
        </div>
        <form className="login__form">
          <h1 className="title login__title">
            {t("VeryifyCode.heading1")}
            <br />
            <span>{t("VeryifyCode.heading2")}</span>
          </h1>
          <div className="form__inputs">
            <div className="input">
              <label htmlFor="input">{t("VeryifyCode.code")}</label>
              <input
                id="emailInput"
                type="text"
                name="code"
                value={data.code}
                placeholder={t("VeryifyCode.code")}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <button
            className="btn"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={verificationCodeSubmit}
          >
            {isLoading? <img
                  style={{
                    height: "22px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                  alt="noImg"
                />:t("VeryifyCode.submit")}
          </button>
        </form>
        {showPopup && (
          <div className="universal__popup">
            <h2 className="small__heading">Error</h2>
            <p className="paragraph">{message}</p>
            <button className="btn" onClick={() => setShowPopup(false)}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default VerifyEmailForgetPassword;
