const serverUrl = process.env.REACT_APP_SERVER_URL;
const endPointPath = process.env.REACT_APP_END_POINT_PATH;
const appName=process.env.REACT_APP_NAME;
const name=process.env.REACT_APP_PATH;
const googleClientId=process.env.REACT_APP_GOOGLE_CLIENT_ID;
const translation_path=process.env.REACT_APP_TRANSLATION_PATH;
// const serverUrl = "http://127.0.0.1:8000/";
export const Config = {
  secretPass: "XkhZG4fW2t2W",
  endPointPath:endPointPath,
 translation_path:translation_path,
  projectName:appName,
  serverUrl: serverUrl,
  serverApiUrl: serverUrl + "api/",
  serverUrlProduct: serverUrl + "api/products",
  serverUrlMe: serverUrl + "api/me",
  serverUrlImages: serverUrl + "public/images/",
  serverUrlCategories: serverUrl + "api/categories?restaurant_id=1",
  serverUrlCategoryImages: serverUrl + "images/categories/",
  serverUrlProductImages: serverUrl + "public/images/products/",
  serverUrlUserImages: serverUrl + "public/images/users/",
  userToken:name+ "userLoginToken",
  userTableToken: name+"userTableLoginToken",
  roleName: name+"role",
  email: name+"email",
  verificationCode: name+"verifiactionCode",
  googleClientId:googleClientId,
  userApiTokenName: name+"user-login-token", 
  googleApiKey: "AIzaSyD4BUDTEpTPIuhhJ2MyQ4AiQ0u3CyFlWOo",
  googleMapApiKey: "AIzaSyCvR1vK8n16g2u44S-PYZvtvJ5w9n00big",
  tableReservation: name+"tableReservation",
  orderType: name+"orderType",
  // Used in whole app
  currency_symbol: "Kr.",
  currency: "Kr.",
  distance_unit: "Km",
  accessoryMaxQty: 3,
  cartItems: name+"cartItems",
  appName: appName,
  pre_set_order: name+"pre_set_order",
  pushTokenName: "device-push-token",
  guest_user_name : name+"guest_user_name",
  guest_user_phone : name+"guest_user_phone",
  tableId: name+"table_id",
  tableNumber: name+"table_number",
  selectedPaymentMethod: name+"selectedPaymentMethod",
  languages: [
    { shortName: "en", icon: "", longName: "English" },
    { shortName: "dk", icon: "", longName: "Danish" },
    { shortName: "ar", icon: "", longName: "Danish" },
    { shortName: "tr", icon: "", longName: "Danish" },
  ],
  firstVisitDone: "firstVisitDone",

  defaultActivityImg: "../select-image.jpg",
  defaultImg: "select-image.jpg",
  defaultProductImageURI:
    "https://www.thespruceeats.com/thmb/vJUFf6L4p8y9Cn_1pE9Z7Ua9uok=/3000x2001/filters:fill(auto,1)/indian-style-burger-1957599-hero-01-266103a4bb4e4ee7b5feb4da2d2e99da.jpg",
  // digitalOceanLinkProductSmallImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/products/small/",
  digitalOceanLinkProductLargeImg: process.env.REACT_APP_PRODUCT_IMAGE_LARGE_LINK,//use
  // digitalOceanLinkProductMediumImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/products/medium/",
  // // category paths
  // digitalOceanLinkCategorySmallImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/categories/small/",
  // digitalOceanLinkCategoryLargeImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/categories/large/",
  // digitalOceanLinkCategoryMediumImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/categories/medium/",
  // // Admin image paths
  // digitalOceanLinkAdminSmallImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/admin/small/",
  // digitalOceanLinkAdminLargeImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/admin/large",
  // digitalOceanLinkAdminMediumImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/admin/medium",
  // // User image paths
  // digitalOceanLinkUserSmallImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/user/small/",
  // digitalOceanLinkUserLargeImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/user/large/",
  digitalOceanLinkUserMediumImg: process.env.REACT_APP_USER_IMAGE_MEDIUM_LINK, //use
  digitalOceanLinkGalleryMediumImg: process.env.REACT_APP_GALLERY_IMAGE_LARGE_LINK, //use
  

  // Restaurant image paths
  // digitalOceanLinkRestaurantSmallImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/restaurant/small/",
  // digitalOceanLinkRestaurantLargeImg:
  //   "https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/restaurant/large/",
  digitalOceanLinkRestaurantMediumImg: process.env.REACT_APP_RESTAURANT_IMAGE_MEDIUM_LINK,
};
const PAGE_URLS = {
  BASE_URL: "https://dzfood.danzeetech.com/",  
};

export default PAGE_URLS;