import React from "react";
import { useTranslation } from "react-i18next";

import "./QuickPayPrivacy.css";

const QuickPayPrivacy = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy__page">

      <div className="inner__div">

        <div className="inner__container">
          <h3 className="normal__heading">{t("QuickPay.terms")}</h3>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.ConclusionOfAgreement.heading")}
          </h3>
          <p>{t("QuickPay.ConclusionOfAgreement.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.PlacingAnOrder.heading")}
          </h3>
          <p>{t("QuickPay.PlacingAnOrder.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">{t("QuickPay.Prices.heading")}</h3>
          <p>{t("QuickPay.Prices.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">{t("QuickPay.Payment.heading")}</h3>
          <p>{t("QuickPay.Payment.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.PaymentOptions.heading")}
          </h3>
          <p>{t("QuickPay.PaymentOptions.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">{t("QuickPay.3DSecure.heading")}</h3>
          <p>{t("QuickPay.3DSecure.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
          {t("QuickPay.SubjectToChanges.heading")}
          
          </h3>
          <p>{t("QuickPay.SubjectToChanges.paragraph", { domain_name: process.env.REACT_APP_DOMAIN })}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
        
          {t("QuickPay.Abuse.heading")}
          </h3>
          <p>{t("QuickPay.Abuse.paragraph", { domain_name: process.env.REACT_APP_DOMAIN })}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.InformationPrivacy.heading")}
          </h3>
          <p>{t("QuickPay.InformationPrivacy.paragraph", { app_name: process.env.REACT_APP_NAME })}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
          
          {t("QuickPay.IPAndCookies.heading")}
       
          </h3>
          <p>{t("QuickPay.IPAndCookies.paragraph",{ domain_name: process.env.REACT_APP_DOMAIN })}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.Responsibility.heading")}
          </h3>
          <p>{t("QuickPay.Responsibility.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.DeliveryTime.heading")}
          </h3>
          <p>{t("QuickPay.DeliveryTime.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.RightOfCancellation.heading")}
          </h3>
          <p>{t("QuickPay.RightOfCancellation.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">
            {t("QuickPay.RightOfComplaint.heading")}
          </h3>
          <p>{t("QuickPay.RightOfComplaint.paragraph")}</p>
        </div>

        <div className="inner__container">
          <h3 className="small__heading">{t("QuickPay.Disclaimer.heading")}</h3>
          <p>{t("QuickPay.Disclaimer.paragraph")}</p>
        </div>

      </div>

    </div>
  );
};

export default QuickPayPrivacy;
