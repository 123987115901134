import React, { useState, useEffect, Fragment } from "react";
import "./card.css";
import {
  useGetUserFavoritesQuery,
  useAddToFavoritesMutation,
  useRemoveFromFavoritesMutation,
} from "../../redux/services/DZapi";
import { Config } from "../../constant/Index";
import { useTranslation } from "react-i18next";
import Snackbar from "../snackbar/Snackbar";
import { getLocalStorage } from "../local_storage";
import { useNavigate } from "react-router-dom";
function Card({
  grid = "horizontal",
  name,
  price,
  image,
  id,
  deal,
  showPopup,
  description,
  key,
}) {
  const { t } = useTranslation();
  const { data: favorite, refetch } = useGetUserFavoritesQuery();
  const [addFavorite] = useAddToFavoritesMutation();
  const [removeFavorite] = useRemoveFromFavoritesMutation();
  const [fdata, setFdata] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [massage, setMassage] = useState();
  const Navigate = useNavigate();
  const handleShowSnackbar = (massage) => {
    setMassage(massage);
    setShowSnackbar(true);
  };

  useEffect(() => {
    refetch();
    setFdata(favorite?.data);
  }, [addFavorite, removeFavorite, favorite]);

  const isProductFavorite = (productId) => {
    return fdata?.some((favorite) => favorite?.product_id == productId);
  };

  const handleFavoriteClick = async (productId) => {
    if (!getLocalStorage(Config?.userToken)) {
      handleShowSnackbar("First Login");
      notLogin();
    } else {
      try {
        const isFavorite = isProductFavorite(productId);
        if (isFavorite) {
          await removeFromFavorites(productId);
          handleShowSnackbar(t("popup.FavRemoveMassage"));
        } else {
          await addToFavorites(productId);
          handleShowSnackbar(t("popup.FavAddMassage"));
        }
      } catch (error) {
        console.error("Error:", error);
      }
      refetch();
    }
  };

  const addToFavorites = async (productId, callback) => {
    const response = await addFavorite({
      product_id: productId,
    });

    if (response?.success) {
      callback();
    }
  };

  const removeFromFavorites = async (productId) => {
    await removeFavorite(productId);
    await refetch();
  };
  // https://danzee.fra1.digitaloceanspaces.com/dzfood/admin/images/products/large/
  const renderImage = () => {
    const imageUrl = `${Config.digitalOceanLinkProductLargeImg}${image}`;
    const errorImageUrl =
      "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png";
    return (
      <>
        {!imageUrl ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ height: "100px", width: "100px" }}
              src="img/loader.gif"
              alt="Loading..."
            />
          </div>
        ) : (
          <img
            onClick={() => showPopup(id)}
            src={imageUrl}
            alt="product img"
            onError={(event) => (event.target.src = errorImageUrl)}
          />
        )}
      </>
    );
  };
  const notLogin = () => {
    Navigate("/login");
  };
  return (
    <Fragment key={key}>
      <Snackbar
        message={massage}
        setShowSnackbar={setShowSnackbar}
        showSnackbar={showSnackbar}
      />

      {grid === "horizontal" ? (
        <div className="card vertical__card" key={id}>
          {deal == 1 ? (
            <div className="rating">
              <h4 style={{ color: "var(--dark-color)" }}>{t("Menu.Deals")}</h4>
            </div>
          ) : null}
          <div
            className="heart"
            onClick={(e) => {
              e?.stopPropagation();
              handleFavoriteClick(id);
            }}
          >
            <i
              className={`fa-heart ${isProductFavorite(id) ? "fa-solid" : "fa-regular"
                }`}
            ></i>
          </div>
          <div className="vertical__card__img">{renderImage()}</div>
          <div className="menu__content" onMouseDown={() => showPopup(id)}>
            <div className="title__price">
              <h3 className="card__title">{name}</h3>
              <span className="price">
                {Config?.currency_symbol}
                {price}
              </span>
            </div>
          </div>
        </div>
      ) : grid === "vertical" ? (
        <div
          className="horizental__card"
          key={id}
          onClick={() => showPopup(id)}
        >
          <div className="horizental__card__img ">{renderImage()}</div>

          <div className="horizentalcard__content">
            <div className="basic__content">
              <h1 className="food__name">
                <span className="food__name">
                  {name}
                </span>
                {deal == 1 ? (
                  <span className="deal__tag">{t("Menu.Deals")}</span>
                ) : null}
              </h1>
              <p className="product__name">{description}</p>

              <div
                className="heart__ic horizental-heart"
                onClick={(e) => {
                  e?.stopPropagation();
                  handleFavoriteClick(id);
                }}
              >
                <i
                  className={`fa-heart ${isProductFavorite(id) ? "fa-solid" : "fa-regular"
                    }`}
                ></i>
              </div>
            </div>

            <div className="card__price__heart">
              <span className="product__price">
                {Config?.currency_symbol}
                {price}
                <i className="ri-add-line"></i>
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

export default Card;
