import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../../../Component/local_storage";
import { Config } from "../../../../constant/Index";
import { useGetRestaurantDetailQuery } from "../../../../redux/services/DZapi";
import { useTranslation } from "react-i18next";
import useGetRestaurantFeatures from "../../../../Hooks/useGetRestaurantFeatures";

function SideCard({ }) {
  const { t } = useTranslation();
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const {webDiscount} = useGetRestaurantFeatures()
  const [cartData, setCartData] = useState([]);
  useEffect(() => {
    const localStorageData = JSON.parse(getLocalStorage(Config.cartItems));
    if (Array.isArray(localStorageData)) {
      setCartData(localStorageData);
    }
  }, []);
  const totalPrice = cartData?.reduce((total, item) => {
    const itemPrice =
      item?.price * item?.quantity +
      (item?.accessories?.length > 0
        ? item?.accessories?.reduce(
            (acc, accessory) => acc + accessory?.price * accessory?.quantity,
            0
          )
        : 0);
    return total + itemPrice;
  }, 0);

  return (
    <div className="checkout__cart">
      <div className="header">
        <span className="coupon">
          {t("CheckoutSideCard.haveCouponCode")}&nbsp;<span>{t("CheckoutSideCard.apply")}</span>
        </span>

        <div className="input__field">
          <input type="text" placeholder="Coupon Code" />

          <button className="btn">{t("CheckoutSideCard.apply")}</button>
        </div>

        <span className="price__row">
          <span className="price__head">{t("CheckoutSideCard.subTotal")}:</span>

          <span className="price">Kr {totalPrice || 0}</span>
        </span>

        <span className="price__row">
          <span className="price__head">{t("CheckoutSideCard.discount")}:</span>

          <span className="price">Kr - {webDiscount ? ((webDiscount / 100) * totalPrice).toFixed(2): 0}</span>
        </span>
      </div>

      <span className="price__total">
        <span className="price__head">{t("CheckoutSideCard.total")}:</span>

        <span className="price">Kr {totalPrice - ((webDiscount / 100) * totalPrice).toFixed(2) || 0}</span>
      </span>
    </div>
  );
}

export default SideCard;
