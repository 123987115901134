import React, { useEffect, useState } from "react";
import PreSetOrder from "./PreSetOrder";
import { getLocalStorage, setLocalStorage } from "../../../../Component/local_storage";
import { Config } from "../../../../constant/Index";
import {
  useGetRestaurantDetailQuery,
  useUserAddressQuery,
} from "../../../../redux/services/DZapi";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const OrderType = ({
  orderType,
  setOrderType,
  setShowPreSetOrder,
  showPreSetOrder,
  setShowSnackbar,
  setMassage,
  setOrderDateTime,
  orderDateTime,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: restaurantDetail } = useGetRestaurantDetailQuery();
  const [restaurantFeatures, setRestaurantFeatures] = useState();
  const { data: userAddress } = useUserAddressQuery();
  const [deliveryAddresses, setDeliveryAddresses] = useState();
  const [deliveryAddressesId, setDeliveryAddressesId] = useState(
    location?.state?.addressId ? location?.state?.addressId : null
  );
  useEffect(() => {
    if (restaurantDetail) {
      setRestaurantFeatures(restaurantDetail?.data?.features);
    }
    if (!location?.state?.addressId) {
      setDeliveryAddressesId(userAddress?.data[0]?.id);
    }
    setDeliveryAddressesId(location?.state?.addressId);

    setDeliveryAddresses(userAddress?.data);
  }, [userAddress, restaurantDetail, location?.state?.addressId]);
  const filterDeliveryAddress = () => {
    return deliveryAddresses?.find(
      (address) => address.id == deliveryAddressesId
    );
  };
  useEffect(() => {
    if (getLocalStorage(Config.orderType)) {
      setOrderType(getLocalStorage(Config.orderType));
    }
  }, [])
  const handleShowPreSetOrder = (type) => {
    setOrderType(type);
    setLocalStorage(Config.orderType, type);
  }
  const deliveryAddress = filterDeliveryAddress();
  return (
    <>
      <h2 className="w-full container__heading">
        {t("Checkout.OrderPlaced")}
      </h2>
      {getLocalStorage(Config.tableId) && (
        <>
          <div className="selector__containers">
            <label htmlFor={Config.tableReservation} className="radio__selector ">
              <input
                style={{ pointerEvents: "none" }}
                type="radio"
                name="orderType"
                checked={orderType === Config.tableReservation}
                onClick={() => handleShowPreSetOrder(Config.tableReservation)}
                id={Config.tableReservation}
              />

              <div className="radio__content">
                <span className="radio__heading">{t("Checkout.TableReservation")}</span>

                <div className="radio__description">{t("Checkout.TableReservationMessage")}</div>
              </div>
            </label>
          </div>
        </>
      )}
      {!getLocalStorage(Config.guest_user_name) && (
        <>
          <div className="selector__containers row">
            <label htmlFor="pickUp" className="radio__selector">
              <input
                type="radio"
                name="orderType"
                checked={orderType === "pickup"}
                onClick={() => handleShowPreSetOrder("pickup")}
                id="pickUp"
              />

              <div className="radio__content">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"var(--primary-color)"} fill={"none"}>
                  <path d="M13 22C12.1818 22 11.4002 21.6588 9.83691 20.9764C8.01233 20.18 6.61554 19.5703 5.64648 19H2M13 22C13.8182 22 14.5998 21.6588 16.1631 20.9764C20.0544 19.2779 22 18.4286 22 17V6.5M13 22L13 11M4 6.5L4 9.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M9.32592 9.69138L6.40472 8.27785C4.80157 7.5021 4 7.11423 4 6.5C4 5.88577 4.80157 5.4979 6.40472 4.72215L9.32592 3.30862C11.1288 2.43621 12.0303 2 13 2C13.9697 2 14.8712 2.4362 16.6741 3.30862L19.5953 4.72215C21.1984 5.4979 22 5.88577 22 6.5C22 7.11423 21.1984 7.5021 19.5953 8.27785L16.6741 9.69138C14.8712 10.5638 13.9697 11 13 11C12.0303 11 11.1288 10.5638 9.32592 9.69138Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18.1366 4.01563L7.86719 8.98485" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2 13H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2 16H5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="radio__heading">{t("Checkout.PickUp")}</span>

                <div className="radio__description">{t("Checkout.PickUpMessage")}</div>
              </div>
            </label>
            <label
              htmlFor="delivery"
              className={`radio__selector ${!restaurantFeatures?.delivery && "disabled__selector"
                }`}
            >
              <input
                style={{ pointerEvents: "none" }}
                type="radio"
                name="orderType"
                checked={restaurantFeatures?.delivery && orderType === "delivery"}
                onClick={() => {
                  if (restaurantFeatures?.delivery) {
                    if (deliveryAddress) {
                      handleShowPreSetOrder("delivery");
                    } else {
                      setShowSnackbar(true);
                      setMassage(t("Checkout.DeliveryMessage"));
                    }
                  }
                }}
                id="delivery"
              />
              <div className="radio__content">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"var(--primary-color)"} fill={"none"}>
                  <path d="M19.5 17.5C19.5 18.8807 18.3807 20 17 20C15.6193 20 14.5 18.8807 14.5 17.5C14.5 16.1193 15.6193 15 17 15C18.3807 15 19.5 16.1193 19.5 17.5Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M9.5 17.5C9.5 18.8807 8.38071 20 7 20C5.61929 20 4.5 18.8807 4.5 17.5C4.5 16.1193 5.61929 15 7 15C8.38071 15 9.5 16.1193 9.5 17.5Z" stroke="currentColor" strokeWidth="1.5" />
                  <path d="M14.5 17.5H9.5M19.5 17.5H20.2632C20.4831 17.5 20.5931 17.5 20.6855 17.4885C21.3669 17.4036 21.9036 16.8669 21.9885 16.1855C22 16.0931 22 15.9831 22 15.7632V13C22 9.41015 19.0899 6.5 15.5 6.5M15 15.5V7C15 5.58579 15 4.87868 14.5607 4.43934C14.1213 4 13.4142 4 12 4H5C3.58579 4 2.87868 4 2.43934 4.43934C2 4.87868 2 5.58579 2 7V15C2 15.9346 2 16.4019 2.20096 16.75C2.33261 16.978 2.52197 17.1674 2.75 17.299C3.09808 17.5 3.56538 17.5 4.5 17.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M6.5 7V10.9998M10.5 7V10.9998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <span className="radio__heading">{t("Checkout.Delivery")}</span>

                <div className="radio__description">
                  {restaurantFeatures?.delivery
                    ? (
                      <span>
                        {deliveryAddress?.street || t("Checkout.DeliveryMessage")}{" "}
                        <Link to="/cart" className="edit__address">
                          {" "}
                          <i class="ri-pencil-line"></i>
                        </Link>
                      </span>
                    )
                    : t("Checkout.DeliveryErrorMessage")}
                </div>
              </div>
            </label>
          </div>
        </>
      )}
      <h2 className="w-full container__heading">
        {t("Checkout.OrderDateHeading")}
      </h2>
      {orderType !== "tableReservation" && (
        <PreSetOrder
          setOrderDateTime={setOrderDateTime}
          orderDateTime={orderDateTime}
          setShowPreSetOrder={setShowPreSetOrder}
          showPreSetOrder={showPreSetOrder}
          setShowSnackbar={setShowSnackbar}
          setMassage={setMassage}
        />
      )}
    </>
  );
};
export default OrderType;