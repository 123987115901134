import React from 'react'

const DashboardWhite = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M1 17C1 15.4596 1 14.6893 1.34673 14.1235C1.54074 13.8069 1.80693 13.5407 2.12353 13.3467C2.68934 13 3.45956 13 5 13C6.54044 13 7.31066 13 7.87647 13.3467C8.19307 13.5407 8.45926 13.8069 8.65327 14.1235C9 14.6893 9 15.4596 9 17C9 18.5404 9 19.3107 8.65327 19.8765C8.45926 20.1931 8.19307 20.4593 7.87647 20.6533C7.31066 21 6.54044 21 5 21C3.45956 21 2.68934 21 2.12353 20.6533C1.80693 20.4593 1.54074 20.1931 1.34673 19.8765C1 19.3107 1 18.5404 1 17Z" stroke="white" stroke-width="1.5" />
            <path d="M13 17C13 15.4596 13 14.6893 13.3467 14.1235C13.5407 13.8069 13.8069 13.5407 14.1235 13.3467C14.6893 13 15.4596 13 17 13C18.5404 13 19.3107 13 19.8765 13.3467C20.1931 13.5407 20.4593 13.8069 20.6533 14.1235C21 14.6893 21 15.4596 21 17C21 18.5404 21 19.3107 20.6533 19.8765C20.4593 20.1931 20.1931 20.4593 19.8765 20.6533C19.3107 21 18.5404 21 17 21C15.4596 21 14.6893 21 14.1235 20.6533C13.8069 20.4593 13.5407 20.1931 13.3467 19.8765C13 19.3107 13 18.5404 13 17Z" stroke="white" stroke-width="1.5" />
            <path d="M1 5C1 3.45956 1 2.68934 1.34673 2.12353C1.54074 1.80693 1.80693 1.54074 2.12353 1.34673C2.68934 1 3.45956 1 5 1C6.54044 1 7.31066 1 7.87647 1.34673C8.19307 1.54074 8.45926 1.80693 8.65327 2.12353C9 2.68934 9 3.45956 9 5C9 6.54044 9 7.31066 8.65327 7.87647C8.45926 8.19307 8.19307 8.45926 7.87647 8.65327C7.31066 9 6.54044 9 5 9C3.45956 9 2.68934 9 2.12353 8.65327C1.80693 8.45926 1.54074 8.19307 1.34673 7.87647C1 7.31066 1 6.54044 1 5Z" stroke="white" stroke-width="1.5" />
            <path d="M13 5C13 3.45956 13 2.68934 13.3467 2.12353C13.5407 1.80693 13.8069 1.54074 14.1235 1.34673C14.6893 1 15.4596 1 17 1C18.5404 1 19.3107 1 19.8765 1.34673C20.1931 1.54074 20.4593 1.80693 20.6533 2.12353C21 2.68934 21 3.45956 21 5C21 6.54044 21 7.31066 20.6533 7.87647C20.4593 8.19307 20.1931 8.45926 19.8765 8.65327C19.3107 9 18.5404 9 17 9C15.4596 9 14.6893 9 14.1235 8.65327C13.8069 8.45926 13.5407 8.19307 13.3467 7.87647C13 7.31066 13 6.54044 13 5Z" stroke="white" stroke-width="1.5" />
        </svg>
    )
}

export default DashboardWhite;