import { getLocalStorage } from "../local_storage";
import { Config } from "../../constant/Index";
import React, { useState } from "react";
import PopupMessages from "../popupMassages/PopupMessages";
function CartItemAccessories({ accessory, cartItems, setCartItems, item, index , key}) {
  const [showPopup, setShowPopup] = useState(false);

  const handleDeleteButtonClick = () => {
    setShowPopup(true);
  };
  const handleRemoveAccessories = (accessoryId) => {
    const updatedCartItems = cartItems.map((iitem) => { 
      if (iitem.fakeId === item.fakeId && iitem.size === item.size) {
        const updatedAccessories = iitem.accessories.filter(
          (accessory) => accessory.id !== accessoryId
        ); 
        return { ...iitem, accessories: updatedAccessories };
      }
      return iitem;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  }

  const handleIncreaseAccessoryQuantity = () => {
    const updatedCartItems = cartItems.map((iitem) => {
      if (iitem.fakeId === item.fakeId && iitem.size === item.size) {
        const updatedAccessories = iitem.accessories.map((a) => {
          if (a.id === accessory.id) {
            return { ...a, quantity: a.quantity + 1 };
          }
          return a;
        });
        if (accessory.quantity === 0) {
          updatedAccessories = updatedAccessories.filter((a) => a.id !== accessory.id);
        }
        return { ...iitem, accessories: updatedAccessories };
      }
      return iitem;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };

  const handleDecreaseAccessoryQuantity = () => {
    if (accessory.quantity > 1) {
      const updatedCartItems = cartItems.map((iitem) => {
        if (iitem.fakeId === item.fakeId && iitem.size === item.size) {
          const updatedAccessories = iitem.accessories.map((a) => {
            if (a.id === accessory.id) {
              return { ...a, quantity: a.quantity - 1 };
            }
            return a;
          });

          // Remove the accessory if its quantity becomes 0
          if (accessory.quantity === 1) {
            updatedAccessories = updatedAccessories.filter((a) => a.id !== accessory.id);
          }

          return { ...iitem, accessories: updatedAccessories };
        }
        return iitem;
      });
      setCartItems(updatedCartItems);
      localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
    }
  };

  return (
    <div className="card__name__title__price" key={index}>
      <span className="card__name__title">
        <div className="plus__or__add">
          <i
            className="ri-add-line rotated"
            onClick={() => handleDeleteButtonClick()}
          ></i>
        </div>
        {accessory?.quantity} X{" "}
        {accessory?.title?.[getLocalStorage("i18nextLng")]
          ? accessory?.title?.[getLocalStorage("i18nextLng")]
          : accessory?.title?.en}
      </span>
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }} className="add__and__minus">
        <div className="plus__or__add" onClick={handleIncreaseAccessoryQuantity}>
          <i className="ri-add-line"></i>
        </div>
        <div className="plus__or__add" onClick={handleDecreaseAccessoryQuantity}>
          <i className="ri-subtract-line"></i>
        </div>
      </div>
      <div className="add__card__product">
        <span className="card__product__price">
          {Config.currency_symbol} {+(accessory?.quantity * accessory?.price).toFixed(2)}
        </span>
      </div>
      <PopupMessages
        showPopup={showPopup}
        setShowPopup={() => setShowPopup(false)}
        confirm={() => {
          handleRemoveAccessories(accessory.id);
          setShowPopup(false);
        }}
      />
    </div>
  );
}

export default CartItemAccessories;