import React, { useState, useEffect, useRef } from "react";
import "./menu.css";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Popup from "../../Component/popups/Popup";
import {
  useGetAllProductsQuery,
  useGetCategoriesQuery,
  useGetUserFavoritesQuery,
  useAddToFavoritesMutation,
  useRemoveFromFavoritesMutation,
} from "../../redux/services/DZapi";
import { usePriceForSize } from "../../Hooks/usePriceForSize";
import Card from "../../Component/productCard/Card";
import { getLocalStorage } from "../../Component/local_storage";
import { useTranslation } from "react-i18next";
import Loader from "../../Component/loader/Loader";
import GridToggle from "../../Component/gridtoggle/GridToggle";
import CartCard from "../../Component/cart/CartCard";
import Discount from "../../Component/discount/Discount";
import PopupMessages from "../../Component/popupMassages/PopupMessages";
import MetaTags from "../../Component/SEO/MetaTags";
function Menu() {
  const cartItemCount = useSelector((state) => state.cart.cartItemCount);
  const { t } = useTranslation();
  const { data: categories, isLoading: isCategoryLoading } =
    useGetCategoriesQuery();
  const { data: products } = useGetAllProductsQuery();
  const { data: favorite, refetch: favouriteFetch } =
    useGetUserFavoritesQuery();
  const [data, setData] = useState([]);
  const [addFavorite] = useAddToFavoritesMutation();
  const [removeFavorite] = useRemoveFromFavoritesMutation();
  const [grid, setGrid] = useState("vertical");
  const location = useLocation();
  const [categoryData, setCategoryData] = useState([]);
  const [sortOption, setSortOption] = useState("Default");
  const [visibleCategory, setVisibleCategory] = useState(null);
  const [showCategory, setShowCategory] = useState(window.innerWidth >= 1083);
  const [id, setId] = useState();
  const [searchQuery, setSearchQuery] = useState(location?.state?.value || "");
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [gridLoaderVisible, setGridLoaderVisible] = useState(false);
  const [proDetPopup, setProDetPopup] = useState();
  const [productType, setProductType] = useState();
  const [getPriceForSize] = usePriceForSize();
  const toggleShowCategory = () => setShowCategory(!showCategory);
  const categoryTitleRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const description = `
  Discover authentic Algerian cuisine at DZ Food. Enjoy fast, free delivery on savory BBQ, traditional dishes, 
  and tempting sweets. Try our unique Potato Lover's Pizza or go for a lighter mix salad. 
  Families love our generous grill platter, while our crispy chicken burger is perfect on the go. Quench your thirst with CocaCola or Pepsi. 
  `

  const handleScroll = () => {
    setScrollPosition(window?.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window?.removeEventListener("scroll", handleScroll);
  }, []);

  const handleCardVisibility = (entries) => {
    entries?.forEach((entry) => {
      if (entry?.isIntersecting) {
        setVisibleCategory(entry?.target.id);
      }
    });
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleCardVisibility, options);

    const cardElements = document.querySelectorAll(".cards__card");

    cardElements?.forEach((cardElement) => {
      observer?.observe(cardElement);
    });

    return () => {
      cardElements?.forEach((cardElement) => {
        observer?.unobserve(cardElement);
      });
    };
  }, [scrollPosition]);
  const handleProductTypeChange = (type) =>
    setProductType(productType === type ? "" : type);

  const menuRef = useRef(null);

  useEffect(() => {
    setData(products?.data);
    setCategoryData(
      categories?.data?.filter((category) => category?.items?.length > 0)
    );
    const handleResize = () => setShowCategory(window?.innerWidth >= 1083);

    window.addEventListener("resize", handleResize);

    return () => window?.removeEventListener("resize", handleResize);
  }, [categories, favorite, addFavorite, removeFavorite]);

  const handleGrid = (gridType) => {
    if (gridType !== grid) {
      setGridLoaderVisible(true);
      setTimeout(() => {
        setGrid(gridType);
        setGridLoaderVisible(false);
      }, 2000);
    }
  };

  const handleCategoryClick = (category) => setVisibleCategory(category);

  const handleSearch = (event) => setSearchQuery(event?.target?.value);

  const handleSearchBarFocus = () => setSearchBarActive(true);

  const handleSearchBarBlur = () =>
    setSearchBarActive(searchQuery === "" ? false : true);

  const handleSelectClick = () => setIsOpen(!isOpen);

  const filteredCategoryData = categoryData?.map((category) => ({
    ...category,
    items: category?.items?.filter((item) => {
      const title = item?.title?.en?.toLowerCase();
      const includesQuery = title?.includes(searchQuery?.toLowerCase());
      return productType === "Deals"
        ? item?.deal && includesQuery
        : !item?.deal && includesQuery;
    }),
  }));

  const searchItems = data?.filter((item) => {
    const title = item?.title?.en?.toLowerCase();
    const includesQuery = title?.includes(searchQuery?.toLowerCase());
    return productType === "Deals"
      ? item?.deal && includesQuery
      : !item?.deal && includesQuery;
  });

  const sortItems = (event) => setSortOption(event?.target?.value);

  const sortedItems = (items) => {
    if (sortOption === "Rating")
      return [...items]?.sort((a, b) => b?.stars - a?.stars);
    if (sortOption === "Latest") return [...items].sort((a, b) => b?.id - a?.id);
    if (sortOption === "Default") return items;
    return items;
  };

  const sortedCategoryItems = filteredCategoryData?.map((category) => ({
    ...category,
    items: sortedItems(category?.items),
  }));

  const openPopup = (sid) => {
    setId(sid);
    setProDetPopup(true);
  };
  const allCategoriesEmpty = sortedCategoryItems?.every(
    (category) => category?.items?.length === 0
  );
  return (
    <>
      <MetaTags href={"menu"} pageName={"Menu"} content={description} metaTitle={"Indulge in Authentic Algerian Cuisine | DZ Food"} />

      <Discount />
      <div className="menu__section" ref={menuRef}>
        <div className="category__filter">
          <div
            onClick={toggleShowCategory}
            className="btn"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "160px",
            }}
          >
            <div>{t("Menu.category")}</div>
            <i
              className="ri-arrow-down-s-line"
              onClick={toggleShowCategory}
            ></i>
          </div>

          <div
            className={
              searchBarActive
                ? "search__bar search__bar__active"
                : "search__bar"
            }
          >
            <input
              type="text"
              placeholder={t("Menu.SearchYouWant")}
              value={searchQuery}
              onChange={handleSearch}
              onFocus={handleSearchBarFocus}
              onBlur={handleSearchBarBlur}
            />
            {searchQuery && (
              <i
                className="ri-close-line"
                style={{ marginRight: "5px", fontSize: "25px", padding: "5px" }}
                onClick={() => setSearchQuery("")}
              ></i>
            )}
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>

          <div className="sort__filter">
            {/* <span>{t("Menu.SortBy")}</span> */}
            <select
              onClick={handleSelectClick}
              className="filter"
              onChange={sortItems}
              value={sortOption}
            >
              <option className="optionS" value="Default">
                {t("Menu.Default")}
              </option>
              <option className="optionS" value="Rating">
                {t("Menu.Rating")}
              </option>
              <option className="optionS" value="Latest">
                {t("Menu.Latest")}
              </option>
            </select>
          </div>
          <GridToggle
            grid={grid}
            className="grid__changer"
            onToggle={handleGrid}
          />
        </div>
        {proDetPopup && (
          <Popup
            key={id}
            sendid={id}
            cartItemCount={cartItemCount}
            setProDetPopup={setProDetPopup}
          />
        )}
        <div className="main_div" style={{ display: "flex" }}>
          {(showCategory) && (
            <div className="categories__card">
              {showCategory && (
                <div className="categories__card__list">
                  {isCategoryLoading ? (
                    <Loader height="200px" />
                  ) : (
                    filteredCategoryData?.map((item) => {
                      if (item.items?.[0]) {
                        return (
                          <a
                            href={`#${item?.name?.en}`}
                            key={item?.id}
                            className={`categories__name ${visibleCategory === item?.id ||
                              visibleCategory === item?.name?.en
                              ? "categories__name__active"
                              : ""
                              }`}
                            onClick={() => handleCategoryClick(item?.id)}
                          >
                            {item?.name?.[getLocalStorage("i18nextLng")]
                              ? item?.name?.[getLocalStorage("i18nextLng")]
                              : item?.name?.[getLocalStorage("i18nextLng")]
                                ? item?.name?.[getLocalStorage("i18nextLng")]
                                : item?.name?.en}
                          </a>
                        );
                      }
                    })
                  )}
                </div>
              )}
              {(
                <div className="categories__card__list">
                  <div className="menu__tab">
                    <span htmlFor="Deals" name="Deals" className="tab__name">
                      {t("Menu.Deals")}
                    </span>
                    <input
                      onChange={() => handleProductTypeChange("Deals")}
                      type="checkbox"
                      value="Deals"
                      name="Deals"
                      checked={productType == "Deals"}
                    />
                    {productType == "Deals" && (
                      <i
                        className="ri-close-line"
                        onClick={() => setProductType("")}
                      ></i>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={`menu__cards ${grid === "vertical" ? "vertical-gap" : ""
              }`}
          >
            {allCategoriesEmpty ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src="img/NoItem.png" alt="NoItem" width="400px" />
              </div>
            ) : isCategoryLoading || gridLoaderVisible ? (
              <Loader />
            ) : searchQuery ? (
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h1 className="cards__categary__title">Search Result</h1>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {searchItems?.[0] ? (
                    searchItems?.map((data, index) => {
                      return (
                        <Card
                          description={
                            data?.detail?.[getLocalStorage("i18nextLng")]
                              ? data?.detail?.[getLocalStorage("i18nextLng")]
                              : data?.detail?.en
                          }
                          grid={grid}
                          name={
                            data?.title?.[getLocalStorage("i18nextLng")]
                              ? data?.title?.[getLocalStorage("i18nextLng")]
                              : data?.title?.en
                          }
                          price={getPriceForSize(data)}
                          image={data?.image}
                          stars={data?.stars}
                          id={data?.id}
                          deal={data?.deal}
                          showPopup={openPopup}
                          state={data?.state}
                          index={index}
                        />
                      );
                    })
                  ) : (
                    <div
                      className=""
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        width="400px"
                        height="400px"
                        src="img/nosearch.jpg"
                        alt="No Search"
                      />
                      <h1 className="cards__categary__title">
                        No Search Available
                      </h1>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              sortedCategoryItems?.map((cItem, index) => {
                const productsData = cItem?.items;
                if (productsData?.[0]) {
                  const sortedProductsData = [...productsData].sort((a, b) => {
                    if (sortOption == "Rating") {
                      return b?.stars - a?.stars;
                    } else if (sortOption == "Latest") {
                      return b?.id - a?.id;
                    } else if (sortOption == "Price") {
                      return a?.price - b?.price;
                    } else if (sortOption == "Oldest") {
                      return a?.id - b?.id;
                    }
                    return 0;
                  });
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                      id={cItem?.name?.en}
                      key={cItem.id}
                      className="cards__card"
                    >
                      <h1
                        className="cards__categary__title"
                        ref={categoryTitleRef}
                      >
                        {cItem?.name?.[getLocalStorage("i18nextLng")]
                          ? cItem?.name?.[getLocalStorage("i18nextLng")]
                          : cItem?.name?.en}
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        {sortedProductsData.map((data, index) => {
                          return (
                            <Card
                              key={index}
                              description={
                                data?.detail?.[getLocalStorage("i18nextLng")]
                                  ? data?.detail?.[getLocalStorage("i18nextLng")]
                                  : data?.detail?.en
                              }
                              grid={grid}
                              name={
                                data?.title?.[getLocalStorage("i18nextLng")]
                                  ? data?.title?.[getLocalStorage("i18nextLng")]
                                  : data?.title?.en
                              }
                              price={getPriceForSize(data)}
                              image={data?.image}
                              stars={data?.stars}
                              id={data?.id}
                              deal={data?.deal}
                              showPopup={openPopup}
                              state={data?.state}
                              index={index}
                              favouriteFetch={favouriteFetch}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            )}
          </div>
          <CartCard />
        </div>
      </div>
      <div className="mobile__popup__card">
        <CartCard />
      </div>
    </>
  );
}

export default Menu;
