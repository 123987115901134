// PaymentMethodPopup.js
import React, { useEffect, useState } from "react";
import {
  useAddUserPaymentMethodsMutation,
  useUpdateUserPaymentMethodMutation,
} from "../../redux/services/DZapi";
import PaymentForm from "./PaymentForm.js";
import Snackbar from "../snackbar/Snackbar.js";
import { useTranslation } from "react-i18next";
function PaymentMethodPopup({
  popup,
  setPopup,
  cardId,
  setCardId,
  cardData,
  refetch,
}) {
  const { t } = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [message, setMessage] = useState()
   const [updateUserPaymentMethod] = useUpdateUserPaymentMethodMutation();
  const [addUserPaymentMethod] = useAddUserPaymentMethodsMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({
    title: "",
    account_holder: "nothing",
    card_number: "",
    card_expiration: "",
    cvc: "",
  });
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  
  useEffect(() => {
    if (cardId && cardData) {
      setPaymentData({
        title: cardData.title,
        account_holder: cardData.account_holder,
        card_number: `**** **** ${cardData.card_number_last_4}`,
        card_expiration: cardData.card_expiration,
      });
    }
  }, [cardData, cardId]);
  useEffect(() => {
    const formattedDate = `${year}${month}`;
    setPaymentData((prevPaymentData) => ({
      ...prevPaymentData,
      card_expiration: formattedDate,
    }));
  }, [month, year]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the name is "card_expiration"
    if (name == "card_expiration") {
      // Extract the first two digits as month and the last two digits as year
      const month = value.substring(0, 2);
      const year = value.substring(2, 4);
  
      // Concatenate the values to form YYMM
      const formattedExpiration = `${year}${month}`;
  
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        [name]: formattedExpiration,
      }));
    } else {
      // For other fields, update the state normally
      setPaymentData((prevPaymentData) => ({
        ...prevPaymentData,
        [name]: value,
      }));
    }
  };
  

  const handleClose = () => {
    setPopup(false);
    setCardId(null);
    setPaymentData({
      title: "",
      account_holder: "",
      card_number: "",
      card_expiration: "",
      cvc: "",
    });
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    if(!paymentData.card_number || paymentData.card_expiration.length < 4 || !paymentData.cvc || !paymentData.title){
      setShowSnackbar(true)
      setMessage(t("FormFillMassages.paymentMethodAllField"))
      setIsLoading(false);
      return;
    }
    const updateData = {
      title: paymentData.title,
      account_holder: paymentData.account_holder,
    };

    const updateBody = {
      updateData: updateData,
      id: cardId,
    };

    try {
      if (cardId) { 
        await updateUserPaymentMethod(updateBody);
      } else {
        const response = await addUserPaymentMethod({ ...paymentData, account_holder: "nothing" })
        if(response?.data?.success){
          refetch();
          setIsLoading(false);
          setPopup(false);
          setCardId(null);
          setShowSnackbar(true)
          setMessage(t("FormFillMassages.cardAddedSuccessfully"))
        }else{

          // console.log(response?.error?.data?.message);

          const errorDetails = response?.error?.data?.message;
          
  
            const errorDetailsObject = JSON.parse(errorDetails);
            if (errorDetailsObject && errorDetailsObject.errors) {
              const cardExpirationError = errorDetailsObject?.errors['card[expiration]']?.[0];
              const invalidNumberError = errorDetailsObject?.errors['card[number]']?.[0];
              if (cardExpirationError) {
                setMessage(`${t("FormFillMassages.cardExpirationDate")}${cardExpirationError}`)
                
              }else if(invalidNumberError){
                setMessage(`${t("FormFillMassages.cardNumber")}${invalidNumberError}`)

              }else{
                setMessage(t("FormFillMassages.cardError1"));
              }
              // console.log('Card Expiration Error:', cardExpirationError);
            }else{
              setMessage(`${t("FormFillMassages.cardError1")} ${response?.error?.data?.message}`)

            }
            

          setIsLoading(false);
          setShowSnackbar(true)
        }
      }

    } catch (error) {
      setMessage(t("FormFillMassages.cardError2"), response.error) 
      setShowSnackbar(true)
      console.error("Error updating/adding payment method:", error);
      // Handle errors if needed
    }
  };

  return (
    <>
      {showSnackbar &&<Snackbar setShowSnackbar={setShowSnackbar} showSnackbar={setShowSnackbar} message={message} time="10000" />}
      {popup && (
        <div className="payment__method">
          <div className="small__heading">
            {cardId ? t("paymentMethod.EditCard") : t("paymentMethod.AddCard")}
          </div>
          <PaymentForm
            paymentData={paymentData}
            handleChange={handleChange}
            setPaymentData={setPaymentData}
            setYear={setYear}
            setMonth={setMonth}
            cardId={cardId}
          />
          <div className="btns">
            <button className="btn" onClick={handleSubmit}>
              {isLoading ? (
                <img
                  style={{
                    height: "28px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                  alt="noImg"
                />
              ) : (
                t("paymentMethod.Submit")
              )}
            </button>
            <button className="btn" onClick={handleClose}>
              {t("paymentMethod.Cancel")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PaymentMethodPopup;
