import React, { useEffect, useState } from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";
import { useGetRestaurantDetailQuery } from "../../redux/services/DZapi";
import SocialIcon from "../socialIcon/SocialIcon";
import { Link } from "react-router-dom";

function Footer() {
  const { t } = useTranslation();
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [message, setMessage] = useState("")
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [resData, setResData] = useState();
  const [currentDay, setCurrentDay] = useState(
    new Date().toLocaleDateString("en-US", { weekday: "long" })
  );
  useEffect(() => {
    setResData(RestaurantDetail?.data);
  }, [RestaurantDetail]);
  return (
    <footer className="footer__section">
   <div className="footer">
        <div className="footer__logo">
          {/* <img src="img/logotop.png" alt="DZ food Logo" /> */}
          <img src={process.env.REACT_APP_LOGO_PATH} alt={process.env.REACT_APP_NAME} />
          <p className="paragraph">{t("Footer.it_is_our")}</p>
          <div className="footer__icons">
            <SocialIcon />
          </div>
          <a
            href={resData?.health_care_link}
            target="_blank"
            className="link"
            style={{ color: "var(--primary-color)", fontWeight: "600" }}
          >
            {t("Footer.HealtCare")}
            <i
              style={{ color: "var(--primary-color)", fontWeight: "600" }}
              class="ri-external-link-fill"
            ></i>
          </a>
        </div>
        <div className="footer__linksection">
          <div className="footer__links">
            <span className="footer__linkheading">{t("Footer.company")}</span>
            {/* <Link className="footer__link" to="/">
              {t("Footer.why_dz")}
            </Link> */}
            {/* <Link className="footer__link" to="/about">
              {t("Footer.about")}
            </Link> */}
            <Link className="footer__link" to="/menu">
              {t("Footer.menu")}
            </Link>
            <Link className="footer__link" to="/contact">
              {t("Footer.contact")}
            </Link>
            <Link className="footer__link" to="/privacy">
              {t("QuickPay.terms")}
            </Link>
          </div>
          <div className="footer__links">
            <span className="footer__linkheading">
              {t("Footer.WorkingHours")}
            </span>
            {resData && (
              <div>
                {resData?.opening_hours && (
                  <>
                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "sunday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Sunday")}
                      <span>
                        {resData?.opening_hours?.sun_from
                          ? `${resData?.opening_hours?.sun_from} - ${resData?.opening_hours?.sun_to}`
                          : t("Footer.Closed")}
                      </span>
                    </div>

                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "monday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Monday")}
                      <span>
                        {resData?.opening_hours?.mon_from
                          ? `${resData?.opening_hours?.mon_from} - ${resData?.opening_hours?.mon_to}`
                          : t("Footer.Closed")}
                      </span>
                    </div>

                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "tuesday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Tuesday")}

                      <span>
                        {resData?.opening_hours?.tue_from
                          ? `${resData?.opening_hours?.tue_from} - ${resData?.opening_hours?.tue_to}`
                          : t("Footer.Closed")}
                      </span>
                    </div>

                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "wednesday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Wednesday")}

                      <span>
                        {resData?.opening_hours?.wed_from
                          ? `${resData?.opening_hours?.wed_from} - ${resData?.opening_hours?.wed_to}`
                          : t("Footer.Closed")}
                      </span>
                    </div>

                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "thursday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Thursday")}
                      <span>{`${resData?.opening_hours?.thu_from} - ${resData?.opening_hours?.thu_to}`}</span>
                    </div>

                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "friday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Friday")}

                      <span>
                        {resData?.opening_hours?.fri_from
                          ? `${resData?.opening_hours?.fri_from} - ${resData?.opening_hours?.fri_to}`
                          : t("Footer.Closed")}
                      </span>
                    </div>

                    <div
                      className={`footer__link ${
                        currentDay?.toLowerCase() === "saturday" ? "active" : ""
                      }`}
                    >
                      {t("Footer.Saturday")}
                      <span>
                        {resData?.opening_hours?.sat_from
                          ? `${resData?.opening_hours?.sat_from} - ${resData?.opening_hours?.sat_to}`
                          : t("Footer.Closed")}
                      </span>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
