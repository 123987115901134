import React, { useEffect } from "react";
import "./Snackbar.css";

function Snackbar({
  message = "message",
  setShowSnackbar,
  showSnackbar,
  time = 3000,
  error,
}) {
  useEffect(() => {
    if (showSnackbar) {
      const timeout = setTimeout(() => {
        setShowSnackbar(false);
      }, time);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showSnackbar]);
  return (
    <div
      style={error && { color: "var(--red-note)" }}
      className={`snackbar ${showSnackbar ? "show" : ""}`}
    >
      {message}
    </div>
  );
}

export default Snackbar;
