// OrderInfo.js
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import OrderDetail from "./OrderDetail";
import OrderList from "./OrderList";
import { useSelector } from "react-redux";
import { Config } from "../../constant/Index";
function TableorderInfo() {
  const cartItemCount = useSelector((state) => state.cart.cartItemCount);
  const { t } = useTranslation();
  const [userorder, setUserorder] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  
  const handleDateChange = (e) => {
    setSelectedDate(e?.target?.value);
  };
  const filteredOrders = useMemo(() => {
    return userorder?.filter((data) => {
      const date = new Date(data?.created_at);
      date.setHours(0, 0, 0, 0);
      const formattedDate = date?.toISOString().split("OrderInfo.T")[0];
      return (
        (selectedStatus === "All" ||
          data?.order_status === selectedStatus ||
          (selectedStatus === "pending" &&
            data?.order_status === "in-process") ||
          (selectedStatus === "pending" && data?.order_status === "ready")) &&
        (searchTerm === "" ||
          data.id.toString().includes(searchTerm) ||
          data?.amount?.includes(searchTerm) ||
          data?.order_detail?.some((detail) =>
            detail?.product?.title?.en
              .toLowerCase()
              .includes(searchTerm?.toLowerCase())
          )) &&
        (!selectedDate ||
          new Date(formattedDate).toLocaleDateString() ==
            new Date(selectedDate).toLocaleDateString())
      );
    });
  }, [userorder, selectedStatus, selectedDate, searchTerm]);
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = filteredOrders?.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const getPageNumbers = () => {
    const totalPageCount = Math.ceil(filteredOrders?.length / itemsPerPage);
    const maxPagesToShow = 5;
    const middlePage = Math.min(
      Math.max(currentPage, maxPagesToShow),
      totalPageCount - maxPagesToShow + 1
    );
    const pageNumbers = [];
    for (
      let i = middlePage - maxPagesToShow + 1;
      i <= middlePage + maxPagesToShow - 1;
      i++
    ) {
      if (i > 0 && i <= totalPageCount) {
        pageNumbers.push(i);
      }
    }
    return pageNumbers;
  };
  useEffect(() => {
    setSelectedOrder(currentOrders?.[0]);
  }, [userorder]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${Config.serverUrl}api/user-order-detail`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem(Config?.userTableToken)}`,
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
  
        const data = await response.json();
        const reversedUserOrder = data?.data?.user_order
          ? [...data?.data.user_order].reverse()
          : [];
        setUserorder(reversedUserOrder);
        setIsLoading(false)

      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false)
      }
    };
  
    fetchData();
  }, [currentPage, selectedStatus, selectedDate, searchTerm]);
  
  return (
    <>
      <div className="profile__page">
        <div className="profile__section">
          <div className="profile__tab">
            <Link to="/user" className="tab">
              {t("OrderInfo.Personal")}
            </Link>
            {/* <Link to="/Address" className="tab">
              {t("OrderInfo.Address")}
            </Link> */}
            <Link to="/orderinfo" className="tab active__tab">
              {t("OrderInfo.OrderInfo")}
            </Link>
          </div>
          <div className="orders__header">
            <h1 className="order__heading">{t("OrderInfo.Orders")}</h1>
            <Link to="/menu">
              <div className="btn">
                <i
                  className="ri-add-line"
                  style={{
                    color: "var(--dark-color)",
                  }}
                />
                <span
                  className="name"
                  style={{
                    color: "var(--dark-color)",
                  }}
                >
                  {t("OrderInfo.NewOrder")}
                </span>
              </div>
            </Link>
          </div>
          <div className="all__order__detail">
            <OrderList
              userorder={userorder}
              selectedOrder={selectedOrder}
              selectedStatus={selectedStatus}
              setSelectedStatus={setSelectedStatus}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              setSelectedOrder={setSelectedOrder}
              handleDateChange={handleDateChange}
              currentOrders={currentOrders}
              filteredOrders={filteredOrders}
              getPageNumbers={getPageNumbers}
              handlePageChange={handlePageChange}
              isLoading={isLoading}
            />
            <OrderDetail
              selectedStatus={selectedStatus}
              selectedOrder={selectedOrder}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TableorderInfo;
