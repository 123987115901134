import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { DZapi } from "./services/DZapi";
import userReducer from "./slices/userSlice";
import cartReducer from "./slices/cartSlice";
export const store = configureStore({
  reducer: {
    [DZapi.reducerPath]: DZapi.reducer,
    user: userReducer,
    cart: cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(DZapi.middleware),
});
setupListeners(store.dispatch);