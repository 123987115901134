import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
function CartIcon({ activeRoute }) {
  const cartItemCount = useSelector((state) => state.cart.cartItemCount);
  return (
    <Link to="/cart" className="active_icon">
      <i
        className={`bx bx-shopping-bag cart__icon ${
          activeRoute == "/cart" && "active_icon" 
        }`}
      ></i>
      {cartItemCount > 0 && <span className="icon-count">{cartItemCount}</span>}
    </Link>
  );
}
export default CartIcon;