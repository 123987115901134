import React, { useEffect, useState, useRef } from "react";
import "./preSetOrder.css";
import { useGetRestaurantDetailQuery } from "../../redux/services/DZapi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function PreSetOrder({
  orderDateTime,
  setOrderDateTime,
  showPreSetOrder,
  setShowPreSetOrder,
  setShowSnackbar,
  setMassage,
  t,
}) {
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [openingHours, setOpeningHours] = useState();
  const [minDate, setMinDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const datePickerRef = useRef(null);
  const formattedSelectedDate = selectedDate
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(/\//g, "-");

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(false);
    }
  };

  const isRestaurantOpen = () => {
    if (!openingHours) return false;

    const currentDay = new Date().getDay();
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][
      currentDay
    ].toLowerCase();
    const todayOpeningHours = openingHours[`${dayOfWeek}_from`];
    if (!todayOpeningHours) return false;
    const openingTime = openingHours[`${dayOfWeek}_from`] || "";
    const closingTime = openingHours[`${dayOfWeek}_to`] || "";
    return formattedTime >= openingTime && formattedTime <= closingTime;
  };

  useEffect(() => {
    if (isRestaurantOpen()) {
      setOrderDateTime(null);
      setShowPreSetOrder(false);
    } else if (!isRestaurantOpen()) {
      setShowPreSetOrder(true);
    }

    if (isRestaurantOpen()) {
      const currentDay = new Date().getDay();
      const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][
        currentDay
      ].toLowerCase();
      setOpeningTime(openingHours[`${dayOfWeek}_from`] || "");
      setClosingTime(openingHours[`${dayOfWeek}_to`] || "");
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isRestaurantOpen()]);
  useEffect(() => {
    setOpeningHours(RestaurantDetail?.data?.opening_hours);
  }, [RestaurantDetail]);

  useEffect(() => {
    const currentDate = new Date();
    const formattedDateTime = `${currentDate.toISOString().split("T")[0]}T${
      currentDate.toTimeString().split(" ")[0]
    }`;
    setOrderDateTime(formattedDateTime);
    setMinDate(currentDate);
  }, [setOrderDateTime]);

  const handleDateTimeChange = (date) => {
    const year = date.getFullYear().toString();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    const finalFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    
    setOrderDateTime(finalFormattedDate);
    setSelectedDate(date);
  };
  
  return (
    <>
      <div className="section__container">
        <div
          className={`container__detail sec__type ${
            !isRestaurantOpen() && "disabled"
          }`}
        >
          <input
            type="radio"
            name="preSetOrder"
            checked={!showPreSetOrder}
            onClick={() => {
              if (isRestaurantOpen()) {
                setShowPreSetOrder(false);
              } else {
                setMassage("Restaurant is closed");
                setShowSnackbar(true);
              }
            }}
          />
          <div className="radio">
            <span className="text">{t("Cashtypeselect.asSoonAsPossible")}</span>
          </div>
        </div>

        <div className="container__detail sec__type">
          <input
            type="radio"
            name="preSetOrder"
            checked={showPreSetOrder}
            onClick={() => {
              setShowPreSetOrder(true);
            }}
          />
          <div className="radio">
            <div
              className="svg datePicker "
              ref={datePickerRef}
              onClick={toggleDatePicker}
            >
              <i style={{ fontSize: "24px" }} class="ri-calendar-line"></i>
              <span className="text">{formattedSelectedDate}</span>
              {isDatePickerOpen && (
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateTimeChange}
                  minDate={minDate}
                  maxDate={
                    new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
                  }
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="yyyy-MM-dd HH:mm"
                  inline
                  minTime={
                    openingTime ? new Date(`2000-01-01T${openingTime}`) : null
                  }
                  maxTime={
                    closingTime ? new Date(`2000-01-01T${closingTime}`) : null
                  }
                  customInput={
                    <input
                      className="custom-datepicker-input"
                      onClick={toggleDatePicker}
                      value={formattedSelectedDate}
                      readOnly
                    />
                  }
                  popperContainer={(popperProps) => (
                    <div {...popperProps} style={{ zIndex: 1 }}>
                      <div className="custom-calendar">
                        {popperProps.children}
                      </div>
                    </div>
                  )}
                  calendarClassName="custom-calendar-container"
                  dayClassName={(date) => {
                    return date.getDay() === 0 ? "disabled-sunday" : null;
                  }}
                />
              )}
            </div>
            <p className="paragraph__detail">
              {t("Cashtypeselect.dateDetail")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreSetOrder;
