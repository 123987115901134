import React, { useEffect, useState } from "react";
import "./Checkout.css";
import OrderType from "./Components/OrderType/OrderType";
import SelectPayment from "./Components/SelectPayment/SelectPayment.jsx";
import OrderCompleted from "./Components/OrderCompleted/OrderCompleted.jsx";
import ProgressBar from "./Components/ProgressBar/ProgressBar.js";
import SideCard from "./Components/SideCard/SideCard.js";
import {
  useAddOrderMutation,
  useAddTableOrderMutation,
  useGetRestaurantDetailQuery,
  useGetLoginUserQuery,
  useAddUserPaymentMethodsMutation,
  useGetUserPaymentMethodsQuery,
} from "../../redux/services/DZapi.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Config } from "../../constant/Index.js";
import TableUserPopup from "../../Component/TableUserPopup/TableUserPopup.jsx";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../Component/local_storage/index.js";
import Snackbar from "../../Component/snackbar/Snackbar.js";
import useRestaurantStatus from "../../Hooks/useRestaurantStatus.js";
import useGetRestaurantFeatures from "../../Hooks/useGetRestaurantFeatures.js";
const Checkout = () => {
  const {
    openingHours,
    payLater,
    tableReservation,
    delivery,
    payment,
    webDiscount,
  } = useGetRestaurantFeatures();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const card_id = searchParams?.get("id");
  const [userData, setUserData] = useState({
    name: "",
    number: "",
  });
  const { data: loginUser } = useGetLoginUserQuery();
  const { t } = useTranslation();
  const { data: restaurantDetail } = useGetRestaurantDetailQuery();
  const [addOrder, responseOrder] = useAddOrderMutation();
  const [addTableOrder, responseTableOrder, isTableOrderLoading] =
    useAddTableOrderMutation();
  const [addUserPaymentMethod, responseCardSave, isLoadingCard] =
    useAddUserPaymentMethodsMutation();
  const { data: paymentMethods } = useGetUserPaymentMethodsQuery();
  const restaurantStatus = useRestaurantStatus();
  const navigate = useNavigate();
  const [message, setMassage] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [orderDateTime, setOrderDateTime] = useState("");
  const [showPreSetOrder, setShowPreSetOrder] = useState("");
  const [creaditCardId, setCreaditCardId] = useState();
  const [orderType, setOrderType] = useState("pickup");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cod");
  const [cartItems, setCartItems] = useState([]);
  const [showTableUserPopup, setShowTableUserPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userCards, setUserCards] = useState();
  const [activeSection, setActiveSection] = useState("orderType");
  const [addCardForSection, setAddCardForSection] = useState(null);
  const [comment, setComment] = useState(
    location?.state?.comment ? location?.state?.comment : null
  );
  console.log(selectedPaymentMethod);
  const handleShowSnackbar = (message) => {
    setMassage(message);
    setShowSnackbar(true);
  };
  const cardCheck = (cardSave = false) => {
    console.log(cardSave, "cardSave");
    if (
      !cardSave &&
      userCards?.length == 0 &&
      selectedPaymentMethod == "card" &&
      !card_id &&
      activeSection === "selectPayment"
    ) {
      // saveCard();
    }
  };
  useEffect(() => {
    if (card_id) {
      setActiveSection("selectPayment");
      setSelectedPaymentMethod("card");
    }
    if (location.state?.activeSection) {
      if (!addCardForSection) {
        setActiveSection("selectPayment");
      }
    }
    setAddCardForSection(location?.state?.activeSection);
    cardCheck(location?.state?.cardSave);
  }, [
    selectedPaymentMethod,
    card_id,
    restaurantDetail,
    activeSection,
    addCardForSection,
  ]);
  const handleSectionChange = (type) => {
    if (type === "next") {
      if (activeSection === "orderType") {
        setActiveSection("selectPayment");
        cardCheck(location?.state?.cardSave);
      } else if (activeSection === "selectPayment") {
        addNewOrder();
      }
    } else if (type === "prev") {
      if (activeSection === "orderType") {
        navigate("/menu");
      } else if (activeSection === "selectPayment") {
        setActiveSection("orderType");
      }
    }
  };
  const saveCard = async () => {
    setIsSubmitting(true);
    try {
      const response = await addUserPaymentMethod();
      if (response?.data?.success) {
        const link = response?.data?.data?.url;
        console.log(response?.data);
        console.log(link);
        window.location.href = link;
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };
  const addNewOrder = () => {
    if (
      !getLocalStorage(Config.userToken) &&
      getLocalStorage(Config.tableId) &&
      orderType === Config.tableReservation
    ) {
      setShowTableUserPopup(true);
    } else {
      addPayment();
    }
  };
  async function addPayment() {
    setIsSubmitting(true);
    try {
      const totalPrice = cartItems?.reduce((total, item) => {
        const itemPrice =
          item.price * item.quantity +
          (item.accessories?.length > 0
            ? item.accessories.reduce(
                (acc, accessory) => acc + accessory.price * accessory.quantity,
                0
              )
            : 0);
        return total + itemPrice;
      }, 0);
      const cartData = localStorage.getItem(Config.cartItems);
      const parsedCartItems = JSON.parse(cartData);
      const allAccessories = [];
      parsedCartItems?.forEach((item) => {
        item?.accessories?.forEach((accessory) => {
          if (accessory?.checked) {
            allAccessories.push(accessory);
          }
        });
      });
      const form = new FormData();
      if (showPreSetOrder == "preSetOrder") {
        form.append("order_date", orderDateTime ? orderDateTime : null);
      }
      form.append("amount", totalPrice);
      form.append("payment_type", selectedPaymentMethod);
      form.append("comments", comment);
      form.append("discount", webDiscount);
      form.append("delivery_charges", location?.state?.deliveryPrice ? location?.state?.deliveryPrice : 0);
      form.append("cartItems", JSON.stringify(parsedCartItems));
      if (orderType == "delivery" && !getLocalStorage(Config.tableId)) {
        if (location?.state?.addressId) {
          form.append("address_id", location?.state?.addressId);
        } else {
          handleShowSnackbar(t("Checkout.Error1"));
          return false;
        }
      }
      form.append("order_type", orderType ? orderType : "pickup");
      if (selectedPaymentMethod == "card") {
        if (creaditCardId) {
          form.append("card_id", creaditCardId);
        } else {
          handleShowSnackbar(t("Checkout.Error2"));
          return false;
        }
      }
      if (
        getLocalStorage(Config.tableId) &&
        orderType === Config.tableReservation
      ) {
        form.append("name", userData?.name);
        form.append("phone", userData?.number);
      }
      if (
        orderType === Config.tableReservation &&
        getLocalStorage(Config.tableId)
      ) {
        const response = await fetch(
          `${Config.serverUrl}api/user-table-add-order`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${getLocalStorage(Config?.userToken)}`,
              Accept: "application/json",
            },
            body: form,
          }
        );
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success) {
            removeLocalStorage(Config.cartItems);
            removeLocalStorage(Config.orderType);
            removeLocalStorage(Config.pre_set_order);
            removeLocalStorage(Config.selectedPaymentMethod);
            setActiveSection("orderCompleted");
            handleShowSnackbar(
              responseData?.message || t("Checkout.orderCompletedMessage")
            );
          } else {
            console.error("Unsuccessful response:", responseData);
            setMassage(responseData?.message || t("Checkout.Error3"));
            setShowSnackbar(true);
          }
        } else {
          console.error("Error:", response.status);
          setMassage(error?.message || t("Checkout.Error4"));
          setShowSnackbar(true);
        }
      } else if (getLocalStorage(Config.userToken)) {
        addOrder(form)
          .unwrap()
          .then((response) => {
            if (response?.success) {
              removeLocalStorage(Config.cartItems);
              removeLocalStorage(Config.orderType);
              removeLocalStorage(Config.pre_set_order);
              removeLocalStorage(Config.selectedPaymentMethod);
              setActiveSection("orderCompleted");
              handleShowSnackbar(
                response?.message || t("Checkout.orderCompletedMessage")
              );
              console.log(response);
            } else {
              console.log(response);
              handleShowSnackbar(response?.message || t("Checkout.Error3"));
            }
          })
          .catch((error) => {
            console.log(error?.data?.message);
            handleShowSnackbar(error?.data?.message || t("Checkout.Error4"));
          });
      } else {
        navigate("/login");
      }
    } catch (error) {
      handleShowSnackbar(error?.data?.message || t("Checkout.Error4"));
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  }
  useEffect(() => {
    setComment(location?.state?.comment ?? null);
    const storedCartItems =
      JSON.parse(localStorage.getItem(Config.cartItems)) || [];
    setCartItems(storedCartItems);
    setOrderType(location?.state?.typeDelivery || "pickup");
    if (getLocalStorage(Config.tableId)) {
      setOrderType(Config.tableReservation || "tableReservation");
    }
    setUserData({
      name: loginUser?.data?.user?.name || "",
      number: loginUser?.data?.user?.mobile || "",
    });
    // if (activeSection === "selectPayment") {
    //   if (getLocalStorage(Config.selectedPaymentMethod)) {
    //     setSelectedPaymentMethod(getLocalStorage(Config.selectedPaymentMethod));
    //   } else if (payLater == 0) {
    //     setSelectedPaymentMethod(
    //       payLater == 0 ? "card" : "cod"
    //     );
    //   } else if (payment == 0) {
    //     setSelectedPaymentMethod(
    //       payment == 0 ? "cod" : "card"
    //     );
    //   }
    // }
    setUserCards(paymentMethods?.data);
  }, [activeSection, paymentMethods, restaurantDetail]);
  return (
    <div className="checkout__page">
      <h1 className="heading" style={{ color: "var(--text-color)" }}>
        {t("Checkout.Heading1")}{" "}
        <span style={{ color: "var(--primary-color)" }}>
          {t("Checkout.Heading2")}
        </span>
      </h1>
      <div className="checkout__container">
        <div className="selected__container">
          <ProgressBar activeSection={activeSection} />
          {activeSection === "orderType" && (
            <OrderType
              orderType={orderType}
              setOrderType={setOrderType}
              setOrderDateTime={setOrderDateTime}
              orderDateTime={orderDateTime}
              setShowPreSetOrder={setShowPreSetOrder}
              showPreSetOrder={showPreSetOrder}
              setShowSnackbar={setShowSnackbar}
              setMassage={setMassage}
            />
          )}
          {activeSection === "selectPayment" && (
            <SelectPayment
              restaurantDetail={restaurantDetail}
              setCreaditCardId={setCreaditCardId}
              addPayment={addPayment}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
          )}
          {activeSection === "orderCompleted" && <OrderCompleted />}
          {activeSection !== "orderCompleted" && (
            <div className="btn__container">
              <button
                className={`btn  
                  ${activeSection !== "orderType" && "left__btn"}`}
                onClick={() => handleSectionChange("prev")}
              >
                <i class="ri-arrow-left-line"></i>
                {t("Checkout.Back")}
              </button>
              <button
                className="btn right__btn"
                onClick={() => handleSectionChange("next")}
              >
                {responseOrder.isLoading || isSubmitting ? (
                  <img
                    style={{
                      height: "22px",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                    }}
                    src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                    alt="noImg"
                  />
                ) : activeSection === "selectPayment" ? (
                  t("Checkout.OrderPlaced")
                ) : (
                  t("Checkout.Next")
                )}
                <i class="ri-arrow-right-line"></i>
              </button>
            </div>
          )}
        </div>
        {activeSection !== "orderCompleted" && <SideCard />}
      </div>
      {showTableUserPopup && (
        <TableUserPopup
          setShowTableUserPopup={setShowTableUserPopup}
          comment={comment}
          selectedPaymentMethod={selectedPaymentMethod}
          setMassage={setMassage}
          showSnackbar={showSnackbar}
          setShowSnackbar={setShowSnackbar}
          setActiveSection={setActiveSection}
        />
      )}
      <Snackbar
        time="10000"
        message={message}
        showSnackbar={showSnackbar}
        setShowSnackbar={setShowSnackbar}
      />
    </div>
  );
};
export default Checkout;
