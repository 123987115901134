import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddUserMutation, useLoginWithFacebookMutation } from "../../redux/services/DZapi";
import { setLocalStorage } from "../../Component/local_storage";
import { Config } from "../../constant/Index";
import "./signup.css";
import Snackbar from "../../Component/snackbar/Snackbar";
import { useTranslation } from "react-i18next";
import FacebookLogin from "react-facebook-login";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { GoogleLogin } from '@react-oauth/google';
const firebaseConfig = {
  apiKey: "AIzaSyB6wyYJrNuyjVdw8GL5DwsboBauqdcZ3Ow",
  authDomain: "dz-foods.firebaseapp.com",
  projectId: "dz-foods",
  storageBucket: "dz-foods.appspot.com",
  messagingSenderId: "222586656529",
  appId: "1:222586656529:web:a158ae8f13867c12681eb0",
  measurementId: "G-0ND0TKP95T",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });


 
function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState();
  const [adduser, { isALoading, isAError }] = useAddUserMutation();
  const [addFacebookUser, { isFLoading, isFError }] = useLoginWithFacebookMutation();
  const [data, setData] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    password_confirmation: "",
  });
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const responseFacebook = async (response) => {
    if (response.accessToken) {
      try {
        const facebookResponse = await addFacebookUser({ token: response.accessToken });
        if (facebookResponse.data.message == "successfully") {
          setLocalStorage(Config.userToken, facebookResponse?.data?.data?.access_token);
          navigate("/menu");
        } else {
        }
      } catch (error) {
      }
    }
  };
  // window.onload = function () {
  //   FB.init({
  //     appId: '828249542379738',
  //     xfbml: true,
  //     version: 'v18.0'
  //   });
  //   FB.AppEvents.logPageView();
  // };

  // Google Login Button
  // function GoogleLogin() {
  //   return (
  //     <button className="google__button" onClick={signInWithGoogle}>
  //       <i className="fab fa-google"></i> Sign in with google
  //     </button>
  //   );
  // }
  // Google Login Functionality
  const signInWithGoogle = async (data) => {
    // e.preventDefault();
    try {
      // const result = await signInWithPopup(auth, provider);
      // const user = result.user;
      const response = await fetch(`${Config.serverUrl}api/google-login-web`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      });
      if (response.ok) {
        const data = await response.json();
       
        // if (location?.state && location?.state?.from) {
        //   navigate(location?.state?.from);
        // } else {
          if (data?.data?.access_token) {
            setLocalStorage(Config.userToken, data?.data?.access_token);
            navigate("/menu");
          }
        // }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const validateForm = () => {
    const errors = {};
    if (!data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email format";
    }
    if (data.password.length < 6) {
      errors.password = "Password must be at least 6 characters";
    }
    if (data.password !== data.password_confirmation) {
      errors.password_confirmation = "Passwords do not match";
    }
    if (!data.mobile.trim() && !data.mobile) {
      errors.mobile = "Number is required";
    }
    return errors;
  };
  const isValidEmail = (email) => {
    return email.includes("@");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setValidationError(validationErrors);
      setShowSnackbar(true);
    } else {
      setValidationError({});
      try {
        const response = await adduser({
          name: data.name,
          email: data.email,
          mobile: "+45 " + data.mobile,
          password: data.password,
          password_confirmation: data.password_confirmation,
        });
        if (response.data.message === "Registration Successfully") {
          setLocalStorage(Config.userToken, response?.data?.token);
          navigate("/menu");
        } else {
          setError(response.data.message);
        }
      } catch (error) {
        setError("An error occurred. Please try again.");
      }
    }
  };
  return (
    <div className="login__section">
      <Snackbar
        setShowSnackbar={setShowSnackbar}
        time={6000}
        showSnackbar={showSnackbar}
        message={
          validationError.name ||
          validationError.email ||
          validationError.mobile ||
          validationError.password ||
          validationError.password_confirmation
        }
      />
      {/* <div className="login__img">
        <img src="img/loginimg.png" alt="Food 3d image" />
      </div> */}
      <form className="login__form sign__up">
        <h1 className="login__title title">
          <span>{process.env.REACT_APP_NAME}</span>
          {t("SignUp.WelcomeTo")}
          <br />
        </h1>

        <div className="form__inputs">
          <div className="input">
            <label htmlFor="name">{t("SignUp.Name")}</label>
            <input
              required
              type="text"
              value={data.name}
              name="name"
              placeholder={t("SignUp.Name")} 
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          {validationError.name && (
            <span className="validation-error">{validationError.name}</span>
          )}

          <div className="input">
            <label htmlFor="email">{t("SignUp.Email")}</label>
            <input
              type="text"
              value={data.email}
              name="email"
              placeholder={t("SignUp.Email")}
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          {validationError.email && (
            <span className="validation-error">{validationError.email}</span>
          )}
          {error && <div className="error validation-error">{error}</div>}

          <div className="input phone__inputField relative__input">
            <label htmlFor="mobile">{t("SignUp.PhoneNo")}</label>

            <span className="number__country">+45</span>

            <input
              type="text"
              value={data.mobile}
              name="mobile"
              placeholder={t("SignUp.PhoneNo")}
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>
          {validationError.mobile && (
            <span className="validation-error">{validationError.mobile}</span>
          )}

          <div className="input relative__input">
            <label htmlFor="password">{t("SignUp.Password")}</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={data.password}
              placeholder={t("SignUp.Password")}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="show-hide-button hideshow"
            >
              {showPassword ? (
                <i className="ri-eye-line"></i>
              ) : (
                <i className="ri-eye-off-line"></i>
              )}
            </button>
          </div>
          {validationError.password && (
            <span className="validation-error">{validationError.password}</span>
          )}

          <div className="input relative__input">
            <label htmlFor="password_confirmation">
              {t("SignUp.ConfirmPassword")}
            </label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="password_confirmation"
              value={data.password_confirmation}
              placeholder={t("SignUp.ConfirmPassword")}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="show-hide-button hideshow"
            >
              {showConfirmPassword ? (
                <i className="ri-eye-line"></i>
              ) : (
                <i className="ri-eye-off-line"></i>
              )}
            </button>
          </div>

          {validationError.password_confirmation && (
            <span className="validation-error">
              {validationError.password_confirmation}
            </span>
          )}

          <div className="checkbox">
            <input type="checkbox" />
            {t("SignUp.Stay")}
            <span className="underline">{t("SignUp.LoggedIn")}</span>
          </div>
        </div>
        <button className="btn" onClick={handleSubmit}>
          {t("SignUp.SignUp")}
        </button>
        <span className="sign__up">
          {t("SignUp.HaveAnAccount")}
          <Link to="/login" className="underline">
            {t("SignUp.Login")}
          </Link>
        </span>

        <div className="api__btn">
        <GoogleLogin
      onSuccess={credentialResponse => {
        const response = jwtDecode(credentialResponse.credential);
      
        if(response){
          signInWithGoogle(response);
        }
      }}
      onError={() => {
      }}
    />
          {/* <GoogleLogin /> */}
          {/* <FacebookLogin
            appId="194428200375416"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            cssclassName="facebook__btn"
            icon="ri-facebook-box-fill"
            textButton={"Facebook Login"}
          /> */}
        </div>
      </form>
    </div>
  );
}

export default SignUp;



