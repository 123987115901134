
export function useCustomColor() {

  const primary_color = process.env.REACT_APP_PRIMARY_COLOR;
  const text_white = process.env.REACT_APP_TEXT_WHITE;
  document.documentElement.style.setProperty('--primary-color', primary_color);
  document.documentElement.style.setProperty('--text-white', text_white);
  
    return [primary_color,text_white];
  }
  