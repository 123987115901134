import React, { useEffect, useState, useRef } from "react";
import { useGetRestaurantDetailQuery } from "../../../../redux/services/DZapi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./PreSetOrder.css";
import { useTranslation } from "react-i18next";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../../Component/local_storage";
import { Config } from "../../../../constant/Index";
function PreSetOrder({
  setOrderDateTime,
  orderDateTime,
  setShowPreSetOrder,
  showPreSetOrder,
  setShowSnackbar,
  setMassage,
}) {
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [openingHours, setOpeningHours] = useState();
  const [minDate, setMinDate] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const datePickerRef = useRef(null);
  const { t } = useTranslation();
  const [selectedDay, setSelectedDay] = useState();
  const formattedSelectedDate = selectedDate
    ?.toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .replace(/\//g, "-");

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsDatePickerOpen(false);
    }
  };
  const isRestaurantOpen = () => {
    if (!openingHours) return false;

    const currentDay = new Date().getDay();
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][
      currentDay
    ].toLowerCase();

    const todayOpeningHours = openingHours[`${dayOfWeek}_from`];
    if (!todayOpeningHours) return false;
    const openingTime = openingHours[`${dayOfWeek}_from`] || "";
    const closingTime = openingHours[`${dayOfWeek}_to`] || "";
    return formattedTime >= openingTime && formattedTime <= closingTime;
  };
  useEffect(() => {
    if (openingHours) {
      setOpeningTime(openingHours[`${selectedDay}_from`] || "");
      setClosingTime(openingHours[`${selectedDay}_to`] || "");
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isRestaurantOpen(), selectedDay]);
  useEffect(() => {
    const currentDay = new Date().getDay();
    const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][
      currentDay
    ]?.toLowerCase();
    setSelectedDay(dayOfWeek);
  }, []);
  useEffect(() => {
    setOpeningHours(RestaurantDetail?.data?.opening_hours);
  }, [RestaurantDetail]);
  useEffect(() => {
    const currentDate = new Date();
    const formattedDateTime = `${currentDate.toISOString().split("T")[0]}T${
      currentDate.toTimeString().split(" ")[0]
    }`;
    setOrderDateTime(formattedDateTime);
    setMinDate(currentDate);
  }, [setOrderDateTime]);
  const handleDateTimeChange = (date) => {
    const year = date.getFullYear().toString();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const finalFormattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    const dayOfWeek = date
      .toLocaleDateString("en-US", { weekday: "short" })
      .toLowerCase();

    const openingTime = parseInt(openingHours[`${dayOfWeek}_from`]) || 0;
    const closingTime = parseInt(openingHours[`${dayOfWeek}_to`]) || 0;
    const selectedHours = date.getHours();
    if (selectedHours >= openingTime && selectedHours < closingTime) {
      setSelectedDay(dayOfWeek);
      setSelectedDate(date);
      setOrderDateTime(finalFormattedDate);
    } else {
      setMassage(t("Checkout.restaurantClosed"));
      setShowSnackbar(true);
    }
  };
  const handlePreSetOrder = (value) => {
    if (value == "nowOrder") {
      if (isRestaurantOpen()) {
        setShowPreSetOrder(value);
        setLocalStorage(Config.pre_set_order, value);
      } else {
        setMassage(t("Checkout.restaurantClosed")); 
        setShowSnackbar("preSetOrder");
      }
    } else {
      setShowPreSetOrder(value);
      setLocalStorage(Config.pre_set_order, value);
    }
  };
  useEffect(() => {
    // if (isRestaurantOpen()) {
    //   if (getLocalStorage(Config.pre_set_order)) {
    //     setShowPreSetOrder(getLocalStorage(Config.pre_set_order) || "nowOrder");
    //   }
    // } else if(!isRestaurantOpen()) {
    //   setShowPreSetOrder("preSetOrder");
    // }

    if (getLocalStorage(Config.pre_set_order)) {
      setShowPreSetOrder(getLocalStorage(Config.pre_set_order));
    } else {
      if (isRestaurantOpen()) {
        setShowPreSetOrder("nowOrder");
      } else {
        setShowPreSetOrder("preSetOrder");
      }
    }
  }, [openingHours, isRestaurantOpen(), RestaurantDetail, ]);
  return (
    <div className="selector__containers row">
      <label htmlFor="nowOrder" className="radio__selector">
        <input
          type="radio"
          name="nowOrder"
          checked={showPreSetOrder == "nowOrder"}
          onClick={() => handlePreSetOrder("nowOrder")}
          id="nowOrder"
        />

        <div className="radio__content">
          <span className="radio__heading">
            {t("Checkout.asSoonAsPossible")}
          </span>
          <div className="radio__description">
            {t("Checkout.asSoonAsPossibleMessage")}
          </div>
        </div>
      </label>

      <label
        htmlFor="preSetOrder"
        className="radio__selector calender__selector"
      >
        <input
          type="radio"
          name="preSetOrder"
          id="preSetOrder"
          checked={showPreSetOrder == "preSetOrder"}
          onClick={() => handlePreSetOrder("preSetOrder")}
        />
        <div
          className="radio__content"
          ref={datePickerRef}
          onClick={toggleDatePicker}
        >
          <span className="radio__heading">
            <i class="ri-calendar-fill"></i>
            &nbsp;{" "}
            {showPreSetOrder && formattedSelectedDate
              ? formattedSelectedDate
              : t("Checkout.SelectDate")}
          </span>
          {isDatePickerOpen && (
            <DatePicker
              selected={selectedDate}
              onChange={handleDateTimeChange}
              minDate={minDate}
              maxDate={new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="yyyy-MM-dd HH:mm"
              inline
              minTime={
                openingTime ? new Date(`2000-01-01T${openingTime}`) : null
              }
              maxTime={
                closingTime ? new Date(`2000-01-01T${closingTime}`) : null
              }
              customInput={
                <input
                  className="custom-datepicker-input"
                  onClick={toggleDatePicker}
                  value={formattedSelectedDate}
                  readOnly
                />
              }
              popperContainer={(popperProps) => (
                <div {...popperProps} style={{ zIndex: 1 }}>
                  <div className="custom-calendar">{popperProps.children}</div>
                </div>
              )}
              calendarClassName="custom-calendar-container"
              dayClassName={(date) => {
                return date.getDay() === 0 ? "disabled-sunday" : null;
              }}
            />
          )}
          <div className="radio__description">
            {t("Checkout.SelectDateMessage")}
          </div>
        </div>
      </label>
    </div>
  );
}
export default PreSetOrder;
