import React, { useState,useEffect } from "react";
import { useGetDeleteAccountQuery } from "../../redux/services/DZapi";
import { Config } from "../../constant/Index";




function DeleteAccount() {
  
  const [apiResponse, setApiResponse] = useState('');

  useEffect(() => {
    const apiUrl = Config.serverUrl + "api/delete-account";

    fetch(apiUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }

        // Check if the response is JSON
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          return response.json();
        } else {
          return response.text(); // If not JSON, treat it as plain text
        }
      })
      .then(data => setApiResponse(data))
      .catch(error => setApiResponse(`Error: ${error.message}`));
  }, []);

  return (
    <>
      <h1>{apiResponse}</h1>
    </>
  );
}
export default DeleteAccount;
