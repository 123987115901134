import React from "react";
import { getLocalStorage } from "../local_storage";

const SizeChange = ({ data, productSize, handleSizeChange }) => {
  return (
    <div className="product__size">
      {data?.prices?.length > 1 &&  data?.prices?.map((price) => (
        <button
          key={price?.size_id}
          className={`size__button ${productSize === price?.size_id && "active"}`}
          onClick={() => handleSizeChange(price?.size_id)}
        >
          {price?.size?.title?.[getLocalStorage("i18nextLng")]
            ? price?.size?.title?.[getLocalStorage("i18nextLng")]
            : price?.size?.title?.en}
        </button>
      ))}
    </div>
  );
};

export default SizeChange;