import React, { useEffect, useState } from "react";
import "./TableUserPopup.css";
import InputField from "../InputField/InputField";
import { useNavigate } from "react-router-dom";
import { Config } from "../../constant/Index";
import Snackbar from "../snackbar/Snackbar";
import {
  setLocalStorage,
  getLocalStorage,
  removeLocalStorage,
} from "../local_storage";
import { useAddTableOrderMutation } from "../../redux/services/DZapi";
import useGetRestaurantFeatures from "../../Hooks/useGetRestaurantFeatures";

function TableUserPopup({
  setShowTableUserPopup,
  comment,
  selectedPaymentMethod,
  setMassage,
  setShowSnackbar,
  setActiveSection,
}) {
  const { webDiscount } = useGetRestaurantFeatures();
  const [addTableOrder, responseTableOrder, isTableOrderLoading] =
    useAddTableOrderMutation();
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState({
    name: "",
    phoneNumber: "",
  });
  const handleChangeGuestUserData = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    const storedCartItems = JSON.parse(getLocalStorage(Config.cartItems)) || [];
    setCartItems(storedCartItems);
  }, []);

  async function onSubmit() {
    setIsSubmitting(true);
    try {
      const totalPrice = cartItems?.reduce((total, item) => {
        const itemPrice =
          item.price * item.quantity +
          (item.accessories?.length > 0
            ? item.accessories.reduce(
                (acc, accessory) => acc + accessory.price * accessory.quantity,
                0
              )
            : 0);
        return total + itemPrice;
      }, 0);
      const cartData = getLocalStorage(Config.cartItems);
      const parsedCartItems = JSON.parse(cartData);
      const allAccessories = [];
      parsedCartItems?.forEach((item) => {
        item?.accessories?.forEach((accessory) => {
          if (accessory?.checked) {
            allAccessories.push(accessory);
          }
        });
      });
      const form = new FormData();
      form.append("amount", totalPrice);
      if (selectedPaymentMethod) {
        form.append("payment_type", selectedPaymentMethod);
      }
      form.append("comments", comment);
      form.append("discount", webDiscount);
      form.append("cartItems", JSON.stringify(parsedCartItems));
      form.append(Config.tableId, getLocalStorage(Config.tableId));
      form.append(Config.tableNumber, getLocalStorage(Config.tableNumber));
      form.append("name", data?.name);
      form.append("phone", data?.phoneNumber);
      setLocalStorage(Config.guest_user_name, data?.name);
      setLocalStorage(Config.guest_user_phone, data?.phoneNumber);
      addTableOrder(form)
        .unwrap()
        .then((response) => {
          if (response?.success) {
            setLocalStorage(Config.userToken, response?.data?.guest_user_token);
            removeLocalStorage(Config.cartItems);
            removeLocalStorage(Config.orderType);
            removeLocalStorage(Config.pre_set_order);
            removeLocalStorage(Config.selectedPaymentMethod);
            setActiveSection("orderCompleted");
            console.log(response);
            const loginTime = Date.now();
            setLocalStorage("loginTime", loginTime);
            setIsSubmitting(false);
          } else {
            console.error("Unsuccessful response:", response);
            setIsSubmitting(false);
            setMassage(response?.data?.massage);
            setShowSnackbar(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setMassage("An error occurred while communicating with the server.");
          setShowSnackbar(true);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
      setShowTableUserPopup(false);
    }
  }

  return (
    <div className="cart__popup">
      <h1 className="sub__heading">Guest User</h1>
      <div className="popup__inputs">
        <InputField
          darkColor={true}
          placeholder={"Name"}
          name="name"
          onChange={handleChangeGuestUserData}
          value={data?.name}
          type="text"
        />
        <InputField
          darkColor={true}
          placeholder={"Number"}
          name="phoneNumber"
          onChange={handleChangeGuestUserData}
          value={data?.phoneNumber}
          type="number"
        />
      </div>
      <div className="btns">
        <button className="btn" onClick={onSubmit}>
          {isSubmitting ? (
            <img
              style={{
                height: "22px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
              src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
              alt="loading"
            />
          ) : (
            "Submit"
          )}
        </button>
        <button
          className="btn sec__btn"
          onClick={() => setShowTableUserPopup(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default TableUserPopup;
