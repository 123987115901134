import React from "react";
import "./GridToggle.css";
function GridToggle({ grid, onToggle }) {
  return (
    <div className="grid__changer">
      <i
        className={`ri-layout-grid-fill grid__icon ${
          grid == "horizontal" && "active__grid__icon"
        } `}
        onClick={() => onToggle("horizontal")}
      ></i>
      <i
        className={`ri-align-justify grid__icon ${
          grid == "vertical" && "active__grid__icon"
        }`}
        onClick={() => onToggle("vertical")}
      ></i>
    </div>
  );
}

export default GridToggle;
