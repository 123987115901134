import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Autocomplete,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import {
  useGetRestaurantDetailQuery,
  useUserAddAddressMutation,
} from "../../redux/services/DZapi";
import "./dawa-address.css";
import useFindDistanceAvailable from "../../Hooks/useFindDistanceAvailable";
import useGetRestaurantFeatures from "../../Hooks/useGetRestaurantFeatures";
function DawaAutoAddress() {
  const [query, setQuery] = useState("");
  const { isDeliveryPerKm, deliveryDistance } = useGetRestaurantFeatures();
  const { findCalculateDistance } = useFindDistanceAvailable();
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [suggestions, setSuggestions] = useState([]);
  const [addaddress] = useUserAddAddressMutation();
  const [restaurant, setRestaurant] = useState();
  const [data, setData] = useState({
    city: "",
    postCode: "",
    street: "",
    lat: "",
    long: "",
    addresstype: "other",
  });
  const [showAddress, setShowAddress] = useState(true);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setRestaurant(RestaurantDetail?.data);
  }, [RestaurantDetail]);
  useEffect(() => {
    if (query.trim() === "") {
      setSuggestions([]);
      return;
    }
    axios
      .get(`https://dawa.aws.dk/adresser/autocomplete?q=${query}`)
      .then((response) => {
        const suggestions = response.data;
        setSuggestions(suggestions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [query]);
  const handleChange = (e) => {
    setQuery(e.target.value);
    setShowAddress(true);
  };
  const isDeliverInAddress = (postcodes, post_code) => {
    // Check postcode available in delivery or not ?
    const canDeliver =
      postcodes &&
      postcodes?.some(
        (devlivery_postcode) => devlivery_postcode?.postcode == post_code
      );
    return canDeliver;
  };
  const clickAddress = (suggestion) => {
    const address = {
      post_code: suggestion?.adresse?.postnr,
      city: suggestion?.adresse?.postnrnavn,
      lat: suggestion?.adresse?.y,
      long: suggestion?.adresse?.x,
      street: suggestion?.tekst,
    };
    const distance = findCalculateDistance(address?.lat, address?.long);
    const canDeliver = isDeliverInAddress(
      restaurant?.delivery_postcodes,
      address.post_code
    );
    
    if (isDeliveryPerKm) {
      console.log(distance, deliveryDistance);
      console.log(distance > deliveryDistance);
      if (distance > deliveryDistance) {
        alert("Dont delvier");
        return false;
      }
    } else {
      if (!canDeliver) {
        alert("Dont delvier");
        return false;
      }
    }
    setData({
      city: address.city,
      postCode: address.post_code,
      street: address.street,
      lat: address.lat,
      long: address.long,
      addresstype: "other",
    });
    setQuery(suggestion?.tekst);
    setShowAddress(false);
  };
  function handleAddAddress() {
    setLoader(true);
    const addsendata = {
      city: data.city,
      post_code: data.postCode,
      street: data.street,
      address_type: data.addresstype,
      lat: data.lat,
      long: data.long,
    };
    addaddress(addsendata)
      .unwrap()
      .then((response) => {
        setQuery("");
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error adding address:", error);
        setLoader(false);
      });
  }
  return (
    <div className="address__search">
      <input
        id="outlined-basic"
        label="Outlined"
        variant="outlined"
        placeholder="Search for addresses"
        value={query}
        onChange={handleChange}
        className="address__input"
      />
      <button className="btn" onClick={handleAddAddress}>
        {loader ? (
          <img
            style={{
              height: "22px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
            src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
            alt="noImg"
          />
        ) : (
          <i class="ri-add-line"></i>
        )}
      </button>
      {suggestions.length > 0 && showAddress && (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {suggestions.map((suggestion, index) => (
            <ListItemButton onClick={() => clickAddress(suggestion)}>
              <ListItemText key={index} primary={suggestion.tekst} />
            </ListItemButton>
          ))}
        </List>
      )}
    </div>
  );
}
export default DawaAutoAddress;