import React from "react";
import { useTranslation } from "react-i18next";
import "./PopupMassage.css";

function PopupMessages({ showPopup, confirm, setShowPopup, isLoading }) {
  const { t } = useTranslation();

  const submit = (value) => {
    if (value === "Confirm") {
      setShowPopup(false);
      confirm();
    } else if (value === "Cancel") {
      setShowPopup(false);
    }
  };

  return showPopup ? (
    <div className="delete__popup">
      <div className="delete__top">
        <h1 className="heading">
          {t("DeletePopup.DeleteMassage")}
          {"  "}
        </h1>
        <i className="ri-delete-bin-6-line"></i>
      </div>

      <div className="btns">
        <button className="btn" onClick={() => submit("Confirm")}>
          {isLoading ? (
            <img
              style={{
                height: "28px",
                paddingLeft: "8px",
                paddingRight: "8px",
              }}
              src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
              alt="noImg"
            />
          ) : (
            t("DeletePopup.Confirm")
          )}
        </button>
        <button className="btn" onClick={() => submit("Cancel")}>
          {t("DeletePopup.Cancel")}
        </button>
      </div>
    </div>
  ) : null;
}

export default PopupMessages;
