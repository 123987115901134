import React from 'react'
import ReactRoutes from './ReactRoutes/ReactRoutes'
import { Config } from './constant/Index';
function App() {

  return (
    <div>
      <ReactRoutes/>
    </div>
  )
}
export default App