import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App/App.js";
import { store } from "./App/redux/store.js";
import { Provider } from "react-redux";
import "./App/I18n/I18n.js";
import Loader from "./App/Component/loader/Loader.js";
import { Config } from "./App/constant/Index.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <BrowserRouter basename='/'>
  // <BrowserRouter basename='user-dzfood'>
  // <BrowserRouter basename='/'>
  <BrowserRouter basename={Config.endPointPath}>
    <Provider store={store}>
      <Suspense fallback={<Loader />}>
        <GoogleOAuthProvider clientId={Config.googleClientId}>
          <App />
        </GoogleOAuthProvider>
      </Suspense>
    </Provider>
  </BrowserRouter>
);