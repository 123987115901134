import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const OrderCompleted = () => {
    const {t} =useTranslation()
    return (
        <>

            <img height={350} src="./img/orderCompleted.png" alt="" />

            <Link to="/menu" className="btn">
                {t("Checkout.BackToMenu")}
            </Link>

        </>
    )
}

export default OrderCompleted