import { useEffect, useState } from "react";
import { useGetRestaurantDetailQuery } from "../redux/services/DZapi";
const useGetRestaurantFeatures = () => {
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  console.log(RestaurantDetail)
  const [webDiscount, setWebDiscount] = useState(0);
  const [restaurantData, setRestaurantData] = useState({
    address: null,
    phone: null,
    email: null,
    detail: {},
    healthCareLink: null,
    facebookLink: null,
    instagramLink: null,
    linkedinLink: null,
    youtubeLink: null,
    tiktokLink: null,
    whatsappLink: null,
    twitterLink: null,
    openingHours: {},
    stars: null,
    state: null,
    tableDiscount: null,
    poscDiscount: null,
    appDiscount: null,
    deliveryDistance: null,
    deliveryMinPrice: null,
    pickupTime: null,
    deliveryTime: null,
    lat: null,
    long: null,
    isDeliveryPerKm:  null,
    deliveryPerKmAmount: null,
    deliveryPostCodes: null,
    fixedAmountOfRadius: null,
  });
  const [restaurantFeatures, setRestaurantFeatures] = useState({
    delivery: null,
    ssk: null,
    userWeb: null,
    payment: null,
    payLater: null,
    tableReservation: null,
    userApp: null,
  });
  useEffect(() => {
    if (RestaurantDetail) {
      setRestaurantFeatures({
        delivery: RestaurantDetail?.data?.features?.delivery,
        ssk: RestaurantDetail?.data?.features?.ssk,
        userWeb: RestaurantDetail?.data?.features?.user_web,
        payment: RestaurantDetail?.data?.features?.payment,
        payLater: RestaurantDetail?.data?.features?.pay_later,
        tableReservation: RestaurantDetail?.data?.features?.table_reservation,
        userApp: RestaurantDetail?.data?.features?.user_app,
      });
      setWebDiscount(Number(RestaurantDetail?.data?.web_discount) || 0);
      setRestaurantData({
        address: RestaurantDetail?.data?.address || "",
        phone: RestaurantDetail?.data?.phone || "",
        email: RestaurantDetail?.data?.email || "",
        detail: RestaurantDetail?.data?.detail || {},
        healthCareLink: RestaurantDetail?.data?.health_care_link || "",
        facebookLink: RestaurantDetail?.data?.restaurant_link?.facebook || "",
        instagramLink: RestaurantDetail?.data?.restaurant_link?.instagram || "",
        linkedinLink: RestaurantDetail?.data?.restaurant_link?.linkedin || "",
        youtubeLink: RestaurantDetail?.data?.restaurant_link?.youtube || "",
        tiktokLink: RestaurantDetail?.data?.restaurant_link?.tiktok || "",
        whatsappLink: RestaurantDetail?.data?.restaurant_link?.whatsapp || "",
        twitterLink: RestaurantDetail?.data?.restaurant_link?.twitter || "",
        openingHours: RestaurantDetail?.data?.opening_hours || {},
        stars: RestaurantDetail?.data?.stars || "",
        state: RestaurantDetail?.data?.state || "",
        tableDiscount: RestaurantDetail?.data?.table_discount || "",
        poscDiscount: RestaurantDetail?.data?.posc_discount || "",
        appDiscount: RestaurantDetail?.data?.app_discount || "",
        deliveryDistance: RestaurantDetail?.data?.delivery_distance || "",
        deliveryMinPrice: RestaurantDetail?.data?.delivery_min_price || "",
        pickupTime: RestaurantDetail?.data?.pickup_time || "",
        deliveryTime: RestaurantDetail?.data?.delivery_time || "",
        lat: RestaurantDetail?.data?.lat || "",
        long: RestaurantDetail?.data?.long || "",
        isDeliveryPerKm: RestaurantDetail?.data?.is_delivery_per_km || false,
        deliveryPerKmAmount: RestaurantDetail?.data?.delivery_per_km_amount || "",
        deliveryPostCodes: RestaurantDetail?.data?.delivery_postcodes || [],
        fixedAmountOfRadius: RestaurantDetail?.data?.fixed_amount_of_radius || "",
      });
    }
  }, [RestaurantDetail]);
  return { ...restaurantFeatures, webDiscount, ...restaurantData, restaurantFeatures };
};
export default useGetRestaurantFeatures;