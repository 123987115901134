import React, { useEffect, useState } from "react";
import {
  useGetUserPaymentMethodsQuery,
  useRemoveUserPaymentMethodMutation,
} from "../../redux/services/DZapi";
import PaymentMethodPopup from "../../Component/paymentMethodPopup/PaymentMethodPopup";
import Loader from "../../Component/loader/Loader";
import SingleCard from "./SingleCard";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import CreaditCard from "./CreaditCard/CreaditCard";
function PaymentMethodsCard({
  options = true,
  setCreaditCardId,
  cardHeight = "270px",
  t,
  saveCard,
  responseCardSave
}) {
  const {
    data: PaymentMethods,
    refetch,
    isLoading,
  } = useGetUserPaymentMethodsQuery();
  const [removeUserPaymentMethod] = useRemoveUserPaymentMethodMutation();
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [popup, setPopup] = useState(false);
  const [cardId, setCardId] = useState();
  const [cardData, setCardData] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [isRemovingCard, setIsRemovingCard] = useState(false);
  useEffect(() => {
    if (PaymentMethods?.data) {
      setAllPaymentMethods(PaymentMethods?.data);
      if (options && PaymentMethods?.data?.length > 0) {
        if (setCreaditCardId) {
          setCreaditCardId(PaymentMethods?.data?.[0]?.id);
        }
        setSelectedCard(PaymentMethods?.data?.[0]?.id);
      }
    }
    refetch();
  }, [PaymentMethods, options]);

  const editCard = (id, data) => {
    setCardId(id);
    setCardData(data);
    setPopup(true);
  };

  const deleteCard = async ( id) => {
    setIsRemovingCard(true);
    try {
      await removeUserPaymentMethod(id);
      setIsRemovingCard(false);
      refetch();
    } catch (error) {
      console.error("Error deleting payment method:", error);
    } 
  };

  const handleCardSelect = (id) => {
    setSelectedCard(id);
    if (setCreaditCardId) {
      setCreaditCardId(id);
    }
  };

  return (
    <>
      <div className="payment__table table">
        <div className="payemnt__header">
          <h1 className="section__heading">{t("Cashtypeselect.paymentCards")}</h1>
          {responseCardSave?.isLoading ? (
             <h1>Loading...</h1>
            ) : (
              <i className="ri-add-line" onClick={() => saveCard()}></i>
            )}
       
        </div>

        <div className="payment__contanier" style={{ maxHeight: cardHeight }}>
          {isLoading ? (
            <Loader />
          ) : allPaymentMethods.length == 0 ? (
            <span
              style={{
                color: "var(--primary-color)",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {t("Cashtypeselect.noCardMassage")}
            </span>
          ) : (
            allPaymentMethods.map((data) => {
              return (
                <CreaditCard
                  isRemovingCard={isRemovingCard}
                  key={data?.id}
                  data={data}
                  deleteCard={deleteCard}
                  editCard={editCard}
                  isSelected={selectedCard == data?.id}
                  onSelect={() => handleCardSelect(data?.id)}
                />
              );
            })
          )}
        </div>
      </div>
      {/* <PaymentMethodPopup
        refetch={refetch}
        popup={popup}
        cardId={cardId}
        setCardId={setCardId}
        setPopup={setPopup}
        cardData={cardData}
      /> */}
    </>
  );
}

export default PaymentMethodsCard;
