// PaymentForm.js
import React, { useState } from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

function PaymentForm({
  paymentData,
  handleChange,
}) {
  const [focus, setFocus] = useState("");

  const handleInputFocus = (e) => {
    setFocus(e.target.name);
  };

  return (
    <div className="payment__form" id="PaymentForm">
      <Cards
        cvc={paymentData.cvc}
        expiry={paymentData.card_expiration}
        focused={focus}
        name={paymentData.title}
        number={paymentData.card_number}
      />
      <form>
        <div className="input">
          <input
            type="tel"
            name="card_number"
            placeholder="Card Number"
            onChange={handleChange}
            onFocus={handleInputFocus}
            maxLength="16"
          />
        </div>

        <div className="input">
          <input
            type="text"
            name="title"
            placeholder="Card Title (Home)"
            onChange={handleChange}
            onFocus={handleInputFocus}
          />
        </div>

        <div className="input">
          <input
            type="tel"
            name="card_expiration"
            placeholder="MM/YY Expiry"
            onChange={handleChange}
            onFocus={handleInputFocus}
            maxLength="4"
          />
        </div>

        <div className="input">
          <input
            type="tel"
            name="cvc"
            placeholder="CVC"
            onChange={handleChange}
            onFocus={handleInputFocus}
            maxLength="4"
          />
        </div>
      </form>
    </div>
  );
}

export default PaymentForm;
