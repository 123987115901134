import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForgetPasswordMutation } from "../../redux/services/DZapi";
import { useTranslation } from "react-i18next";

function ForgetPassword() {
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [forgetPassword, { isError, isLoading }] = useForgetPasswordMutation();
  const [data, setData] = useState({
    email: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const forgetPasswordSubmit = async (e) => {
    e.preventDefault();

    if (!data.email) {
      setShowPopup(true);
      setMessage("Please add your email.");
    } else if (!isValidEmail(data.email)) {
      setShowPopup(true);
      setMessage("Email is not in the correct format.");
    } else {
      try {
        const response = await forgetPassword({ email: data.email });
        if (response?.data?.success) {
          // Save email to local storage
          localStorage.setItem("email", data?.email);
          navigate("/verifyEmailForgetPassword");
        } else if (response?.error?.data?.message) {
          setMessage(response?.error?.data?.message);
          setShowPopup(true);
        }
      } catch (error) {
        console.error(error);
        setShowPopup(true);
        setMessage("Something went wrong. Please try again later.");
      }
    }
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  return (
    <>
      <div className="login__section">
        <div className="login__img">
          <img src="img/loginimg.png" alt="Food 3d" />
        </div>
        <form className="login__form">
          <h1 className="title login__title">
            {t("Forget.Heading1")}
            <br />
            <span>{t("Forget.Heading2")}</span>
          </h1>
          <div className="form__inputs">
            <div className="input">
              <label htmlFor="emailInput">{t("Forget.Email")}</label>
              <input
                id="emailInput"
                type="text"
                name="email"
                value={data.email}
                placeholder={t("Forget.Email")}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <button
            className="btn"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={forgetPasswordSubmit}
          >
            {isLoading ? (
              <img
                style={{
                  height: "22px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                alt="noImg"
              />
            ) : (
              t("Forget.Email")
            )}
          </button>
        </form>
      </div>
      {showPopup && (
        <div className="universal__popup">
          <h2 className="small__heading">Error</h2>
          <p className="paragraph">{message}</p>
          <button className="btn" onClick={() => setShowPopup(false)}>
            OK
          </button>
        </div>
      )}
    </>
  );
}

export default ForgetPassword;
