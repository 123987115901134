import React from "react";
import { getLocalStorage } from "../../local_storage";
import { Config } from "../../../constant/Index";
import { Link } from "react-router-dom";
function FavIcon({ activeRoute , t}) {
  return (
    <>
      {getLocalStorage(Config.userToken) ? (
        <Link to="/favourite" className={`span ${activeRoute == "/favourite" && "user__drop__active"} `}>
          <i
            className={`fa-regular fa-heart`}
          ></i>
          {t("Navbar.Favourite")}
        </Link>
      ) : null}
    </>
  );
}

export default FavIcon;