import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./discount.css";
import { useGetRestaurantDetailQuery } from "../../redux/services/DZapi";

function Discount({ animationFile }) {
  const { t } = useTranslation();
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [resData, setResData] = useState();
  useEffect(() => {
    setResData(RestaurantDetail?.data);
  }, [RestaurantDetail]);

  return (
    <> 
      {
        !(resData?.web_discount == 0) && resData?.web_discount
          &&
          <div className="discount__banner">
            <div className="discount__img">
              {/* <img src="./img/discountimg.png" /> */}
              <div className="discount__text">
                <h1 className="heading">
                  {t("Discount.Discount")}
                </h1>
                <p className="discount__paragraph">
                  {t("Discount.DiscountText1") + ' '} 
                  {resData?.web_discount}%
                  {' ' + t("Discount.DiscountText2")}
                  {/* {t("Discount.DiscountText2")} */}
                </p>
              </div> 
            </div>

            <span className="discount">
              {
                resData?.web_discount ?
                  <span>
                    {resData?.web_discount}%
                  </span>
                  :
                  <span style={{fontSize:"36px"}}>
                    {t("Discount.NoDiscount")}
                  </span>
              }
              <span>

              </span>

            </span>

          </div>

      }

    </>
  );
}

export default Discount;
