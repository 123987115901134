import React, { useState, useEffect } from "react";
import "./contact.css";
import { useTranslation } from "react-i18next";
import {
  useGetRestaurantDetailQuery,
  useGetLoginUserQuery,
  useAddContactMassageMutation
} from "../../redux/services/DZapi";
import SocialIcon from "../../Component/socialIcon/SocialIcon";
import Snackbar from "../../Component/snackbar/Snackbar";
import MetaTags from "../../Component/SEO/MetaTags";

function Contact() {
  const { data: getUser } = useGetLoginUserQuery();
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [sendContactMassage] = useAddContactMassageMutation();
  const [userData, setUserData] = useState();
  const [resData, setResData] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    massage: "",
  });

  const { t } = useTranslation();

  const description = `
  Contact DZ Food for delicious Algerian cuisine. Call us at +45 3035878452 or email dzfood@gmail.com. 
  Visit us at Louisevej 3, 4600 Køge for a taste of Algeria!
  `

  useEffect(() => {
    setResData(RestaurantDetail?.data);
  }, [RestaurantDetail]);
 
  useEffect(() => {
    setUserData(getUser?.data?.user);
    setData({
      name: userData?.name || "",
      email: userData?.email || "",
    });
  }, [getUser, userData]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { name, email, subject, massage } = data;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex pattern
  
    if (!name || !email || !subject || !massage) {
      setSnackbarMessage("Please fill in all fields.");
      setShowSnackbar(true);
      return false;
    }
  
    if (!emailRegex.test(email)) {
      setSnackbarMessage("Please enter a valid email address.");
      setShowSnackbar(true);
      return false;
    }
  
    return true;
  };
  

  const submitMassage = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      await sendContactMassage(data);
      setSnackbarMessage("Message sent successfully!");
      setShowSnackbar(true);
      setData({
        name: "",
        email: "",
        subject: "",
        massage: "",
      });
    } catch (error) {
      setSnackbarMessage("Error sending message. Please try again.");
      setShowSnackbar(true);
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="contact">
        <MetaTags href={"contact"} pageName={"Contact"} content={description} metaTitle={"Contact DZ Food for Authentic Algerian Cuisine | DZ Food"} />

      <Snackbar
        showSnackbar={showSnackbar}
        setShowSnackbar={setShowSnackbar}
        message={snackbarMessage}
      />
      <div className="contact__detail">
        <div className="contact__content">
          <h1 className="contact__heading">{t("Contact.contact_information")}</h1>

          <p className="pargarph">{t("Contact.say_something")}</p>
        </div>

        <div className="contact__information">

          {((resData?.phone) && !(resData?.phone == "null")) &&  <div className="info">
            <i className="bx bxs-phone"></i>
            <p className="contact">{resData?.phone}</p>
          </div>}

          {((resData?.email) && !(resData?.email == "null")) && <div className="info">
            <i className="bx bxs-envelope"></i>
            <p className="contact">{resData?.email}</p>
          </div>}

          {((resData?.address) && !(resData?.address == "null")) && <div className="info">
            <i className="bx bx-current-location"></i>
            <p className="contact">{resData?.address}</p>
          </div>}
        </div>

        <div className="contact__icon">
          <SocialIcon />
        </div>
      </div>

      <div className="contact__form">
        <h1 className="subheading">{t("Contact.contact_form")}</h1>

        <div className="form__input">
          <div className="input">
            <label htmlFor="input">{t("Contact.name")}</label>

            <input
              id="emailInput"
              type="text"
              name="name"
              value={data?.name}
              placeholder={t("Contact.name")}
              onChange={handleChange}
            />
          </div>

          <div className="input">
            <label htmlFor="input">{t("Contact.email")}</label>

            <input
              id="passwordInput"
              type="email"
              name="email"
              value={data?.email}
              placeholder={t("Contact.email")}
              onChange={handleChange}
            />
          </div>

          <div className="input">
            <label htmlFor="input">{t("Contact.subject")}</label>

            <input
              id="emailInput"
              type="text"
              name="subject"
              placeholder={t("Contact.subject")}
              onChange={handleChange}
              value={data?.subject}
            />
          </div>

          <div className="input">
            <label htmlFor="input">{t("Contact.message")}</label>

            <input
              id="passwordInput"
              type="email"
              name="massage"
              placeholder={t("Contact.message")}
              onChange={handleChange}
              value={data?.massage}
            />
          </div>

          <div onClick={submitMassage} className="btn">
            {t("Contact.submit")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
