import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Config } from "../../constant/Index";

const TAG_TYPES = {
  Product: "Product",
  Favorite: "Favorite",
  Address: "Address",
  UserCard: "UserCard",
};

function providesList(resultsWithIds, tagType) {
  return resultsWithIds
    ? [
        { type: tagType, id: "LIST" },
        ...resultsWithIds?.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: "LIST" }];
}
export const DZapi = createApi({
  reducerPath: "DZapi",
  tagTypes: [
    TAG_TYPES.Product,
    TAG_TYPES.Favorite,
    TAG_TYPES.Address,
    TAG_TYPES.UserCard,
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: Config.serverUrl,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      if (localStorage.getItem(Config.userToken) && endpoint !== "refresh")
        headers.set(
          "Authorization",
          `Bearer ${localStorage.getItem(Config?.userToken)}`
        );
      headers.set("Accept", "application/json");
      return headers;
    },
  }),

  //******************Products api******************//

  endpoints: (builder) => ({
    getRestaurantDetail: builder.query({
      query: () => "api/restaurants/1",
    }),
    getAllProducts: builder.query({
      query: () => "api/products",
      providesTags: (result) => providesList(result?.data, TAG_TYPES.Product),
    }),
    getGalleries: builder.query({
      query: () => "api/gallery-images",
      providesTags: (result) => providesList(result?.data?.gallery_images,TAG_TYPES.Product),
    }),
    getDeleteAccount: builder.query({
      query: () => "api/delete-account",
      providesTags: (result) => providesList(result),
    }),
    getAllDeals: builder.query({
      query: () => "/api/deals-products",
      providesTags: (result) => providesList(result?.data, TAG_TYPES.Product),
    }),
    getProductById: builder.query({
      query: (id) => `api/products/${id}`,
    }),
    getUserFavorites: builder.query({
      query: () => "api/user-favourites",
      providesTags: (result) => providesList(result?.data, TAG_TYPES.Favorite),
    }),
    addToFavorites: builder.mutation({
      query(body) {
        return { url: `api/user-favourites`, method: "POST", body };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: TAG_TYPES.Product, id: "LIST" },
        { type: TAG_TYPES.Product, id },
      ],
    }),
    getCategories: builder.query({
      query: () => "api/categories?restaurant_id=1",
    }),
    removeFromFavorites: builder.mutation({
      query: (productId) => ({
        url: `api/user-favourites/${productId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(Config.userToken)}`,
          "Content-Type": "application/json",
        },
      }),
    }),

    // Get User password

    Updateuserpassword: builder.mutation({
      // Update the query to use the correct URL for image upload
      query: (pData) => ({
        url: `api/user/change-password`,
        method: "POST",
        body: pData, // Pass the FormData object directly as the body
      }),
    }),
    userImageUpload: builder.mutation({
      // Update the query to use the correct URL for image upload
      query: (img) => ({
        url: `api/user/image-upload`,
        method: "POST",
        body: img, // Pass the FormData object directly as the body
      }),
    }),

    userPersnoalDataUpdate: builder.mutation({
      // Update the query to use the correct URL for image upload
      query: (pData) => ({
        url: `api/userupdate`,
        method: "POST",
        body: pData, // Pass the FormData object directly as the body
      }),
    }),

    // user-address
    userAddress: builder.query({
      query: () => ({
        url: `api/user-address`,
      }),
      providesTags: [TAG_TYPES.Address],
    }),

    userAddAddress: builder.mutation({
      query: (data) => ({
        url: `api/user-address`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.Address],
    }),

    userAddressUpdate: builder.mutation({
      query: (data) => ({
        url: `api/user-address/${data.id}`,
        method: "PUT",
        body: data.sdata,
      }),
    }),

    userDeleteAddress: builder.mutation({
      // Update the query to use the correct URL for image upload
      query: (id) => ({
        url: `api/user-address/${id}`,
        method: "DELETE", // Pass the FormData object directly as the body
      }),
    }),
    //** Order Check Out */
    AddOrder: builder.mutation({
      // Update the query to use the correct URL for image upload
      query: (data) => ({
        url: `api/add-orders`,
        method: "POST",
        body: data, // Pass the FormData object directly as the body
      }),
    }),

    AddTableOrder: builder.mutation({
      query: (data) => ({
        url: `api/table-add-order`,
        method: "POST",
        body: data,
      }),
    }),

    AddTableOrderUser: builder.mutation({
      query: (data) => ({
        url: `api/user-table-add-order`,
        method: "POST",
        body: data,
      }),
    }),

    getPublishableKey: builder.query({
      query: () => "api/public-key",
    }),
    getSecretKey: builder.query({
      query: () => "api/secret-key",
    }),
    addStripePayment: builder.mutation({
      query: (data) => ({
        url: `api/payment`,
        method: "POST",
        body: data, // Pass the FormData object directly as the body
      }),
    }),

    addUser: builder.mutation({
      query: (data) => ({
        url: `api/signup`,
        method: "POST",
        body: data,
      }),
    }),
    loginWithFacebook: builder.mutation({
      query: (data) => ({
        url: `api/login-with-facebook`,
        method: "POST",
        body: data,
      }),
    }),

    forgetPassword: builder.mutation({
      query: (data) => ({
        url: `api/forgot-password`,
        method: "POST",
        body: data,
      }),
    }),

    verifyEmailForgetPassword: builder.mutation({
      query: (data) => ({
        url: `api/verify-email-forgot-password`,
        method: "POST",
        body: data,
      }),
    }),

    forgetPasswordNewPassword: builder.mutation({
      query: (key) => ({
        url: `/api/new-password`,
        method: "POST",
        body: key,
      }),
    }),

    getSetupPaymentIntent: builder.query({
      query: () => "api/setup-payment-intent-web",
    }),

    //Get user login //me
    getLoginUser: builder.query({
      query: () => `api/me`,
      method: "GET",
    }),

    getRevokeToken: builder.query({
      query: (id) => `api/logout`,
      method: "GET",
      refetchOnMount: false,
    }),

    getAllOrdersList: builder.query({
      query: () => "api/user-order-detail",
    }),

    // user-payment-methods
    // user-payment-methods

    getUserPaymentMethods: builder.query({
      query: () => ({
        url: "api/user-payment-methods",
      }),
      providesTags: [TAG_TYPES.UserCard],
    }),
    getUserPaymentLinkMethods: builder.query({
      query: () => "api/get-link",
    }),
    getUserUpdateCardMethod: builder.query({
      query: (id) => ({
        url: `api/update-card/${id}`,
      }),
      invalidatesTags: [TAG_TYPES.UserCard],
    }),
    addUserPaymentMethods: builder.mutation({
      query: (data) => ({
        url: `api/user-payment-methods`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [TAG_TYPES.UserCard],
    }),
    updateUserPaymentMethod: builder.mutation({
      query: (body) => ({
        url: `api/user-payment-methods/${body.id}`,
        method: "PUT",
        body: body.updateData,
      }),
    }),
    removeUserPaymentMethod: builder.mutation({
      query: (id) => ({
        url: `api/user-payment-methods/${id}`,
        method: "DELETE",
      }),
    }),
    addContactMassage: builder.mutation({
      query: (data) => ({
        url: `api/query-send`,
        method: "POST",
        body: data,
      }),
    }),
    // Language Api
    getLanguages: builder.query({ query: () => `api/languages` }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetGalleriesQuery,
  useGetDeleteAccountQuery,
  useGetAllDealsQuery,
  useGetProductByIdQuery,
  useGetCategoriesQuery,
  useGetUserFavoritesQuery,
  useAddToFavoritesMutation,
  useRemoveFromFavoritesMutation,
  useGetPublishableKeyQuery,
  useGetSecretKeyQuery,
  useAddStripePaymentMutation,
  useGetLoginUserQuery,
  useUpdateuserpasswordMutation,
  useGetRestaurantDetailQuery,
  useAddOrderMutation,
  useAddTableOrderMutation,
  useAddTableOrderUserMutation,
  useGetRevokeTokenQuery,
  useUserImageUploadMutation,
  useUserPersnoalDataUpdateMutation,
  useUserAddressUpdateMutation,
  useUserDeleteAddressMutation,
  useUserAddAddressMutation,
  useUserAddressQuery,
  useGetSetupPaymentIntentQuery,
  useGetAllOrdersListQuery,
  useAddUserMutation,
  useForgetPasswordMutation,
  useForgetPasswordNewPasswordMutation,
  useVerifyEmailForgetPasswordMutation,
  useLoginWithFacebookMutation,
  useGetUserPaymentMethodsQuery,
  useGetUserPaymentLinkMethodsQuery,
  useGetUserUpdateCardMethodQuery,
  useAddUserPaymentMethodsMutation,
  useUpdateUserPaymentMethodMutation,
  useRemoveUserPaymentMethodMutation,
  useAddContactMassageMutation,
  useGetLanguagesQuery,
} = DZapi;
