import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import "./payment.css";
import { useAddStripePaymentMutation, useGetLoginUserQuery, useGetSetupPaymentIntentQuery } from "../../redux/services/DZapi";
import { Config } from "../../constant/Index";
function Payment(props) {

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const { data: isLoggedIn } = useGetLoginUserQuery();
  const { data: setUpIntent } = useGetSetupPaymentIntentQuery();

  const [addPayment, responsePayment]= useAddStripePaymentMutation();

  const location = useLocation();
  const total = location.state?.totalammount ? location.state?.totalammount : 5655 

  useEffect(() => {

    fetch(`${Config.serverUrl}api/public-key`).then(async (r) => {
      const { publishKey } = await r.json();
      setStripePromise(loadStripe(publishKey));
    });

    const setUpIntentPayment = async () => {
      // await setUpIntent();


      setClientSecret(setUpIntent)

    }
  
    // call the function
    setUpIntentPayment({amount: 500})   

  }, [isLoggedIn, setUpIntent]);
 
  const doPayment = async () => {

    await addPayment({amount: 500, paymenmt_method_id:'pm_1NhRZRJLwNwGyG1NVwvpbtJJ'})

  }

  return (
    <div className="payment_card">
      <div className="inner_payment_card">
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
      <div>
        <button onClick={ () => doPayment()}>pay now</button>
      </div>
    </div>
  );
}

export default Payment;
