import React, { useState, useEffect, useRef } from "react";
import "./navbar.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCartItemCount } from "../../redux/slices/cartSlice";
import { useTranslation } from "react-i18next";
import OrderIcon from "./ordericon/OrderIcon";
import LangIcon from "./langicon/LangIcon";
import FavIcon from "./favicon/FavIcon";
import UserImg from "./userimg/UserImg";
import CartIcon from "./carticon/CartIcon";
import Links from "./links/Links";
import i18next from "i18next";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../local_storage";
import { Config } from "../../constant/Index";
import useAuthCheck from "../../ReactRoutes/useAuthCheck";
import Snackbar from "../snackbar/Snackbar";
import { useGetRestaurantDetailQuery } from "../../redux/services/DZapi";
import { useCustomColor } from "../../Hooks/useCustomColor";
function Navbar() {


  

  const status = useAuthCheck();
  const { t } = useTranslation();
  const location = useLocation();
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const searchParams = new URLSearchParams(location.search);
  const tableNumber = searchParams.get("table_number");
  const tableId = searchParams.get("table_id");
  const [activeRoute, setActiveRoute] = useState();
  const dispatch = useDispatch();
  const [showNav, setShowNav] = useState();
  const [orderDrop, setOrderDrop] = useState();
  const [showUserDrop, setShowUserDrop] = useState(false);
  const [showLanDrop, setShowLanDrop] = useState(false);
  const userDropRef = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [tableReservation, setTableReservation] = useState(1);
  const logout = async () => {
    try {
      // signOut(auth);
      const response = await fetch(`${Config.serverUrl}api/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(Config.userToken)}`,
        },
      });
      if (response?.status === 200) {
        localStorage.removeItem(Config.userToken);
        navigate("/");
        window.location.reload();
      } else {
        localStorage.removeItem(Config.userToken);
        console.error("Logout Error:", response);
      }
    } catch (error) {
      console.error("Logout Error:", error);
    } finally {
      localStorage.removeItem(Config.userToken);
    }
  };
  useEffect(() => {
    setTableReservation(RestaurantDetail?.data?.features?.table_reservation);
    if (tableId) {
      if (tableReservation === 1) {
        if (tableId) {
          setLocalStorage(Config.tableId, tableId);
        }
        if (tableNumber) {
          setLocalStorage(Config.tableNumber, tableNumber);
        }
        if (tableId && tableNumber) {
          logout();
        }
      } else if (tableReservation === 0) {
        setShowSnackbar(true);
        setMessage("Sorry table reservation is not available");
        removeLocalStorage(Config.tableId);
        removeLocalStorage(Config.tableNumber);
        navigate("/menu");
      }
    }
    useCustomColor();
  }, [RestaurantDetail, tableId, tableNumber, tableReservation]);

  useEffect(() => {
    if (status !== null) {
      setIsLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (getLocalStorage(Config.userToken)) {
      if (status == null) {
      } else if (status !== 200) {
        const activeRoute = window.location.pathname;
        removeLocalStorage(Config.userToken);
        navigate("/", { state: { from: activeRoute } });
      }
    }
  }, [isLoading, activeRoute]);
  useEffect(() => {
    i18next.changeLanguage("dk");
  }, []);
  useEffect(() => {
    if (getLocalStorage(Config.userTableToken)) {
      const loginTime = parseInt(getLocalStorage("loginTime")) || 0;
      const currentTime = Date.now();
      const timeElapsed = currentTime - loginTime;

      const logoutAfter = 4 * 60 * 60 * 1000;

      if (timeElapsed > logoutAfter) {
        logoutUser();
      } else {
        const remainingTime = logoutAfter - timeElapsed;

        const timeoutId = setTimeout(() => {
          logoutUser();
        }, remainingTime);

        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  });
  const logoutUser = () => {
    removeLocalStorage(Config.userTableToken);
    removeLocalStorage(Config.tableId);
    removeLocalStorage(Config.tableNumber);
    removeLocalStorage("customerName");
    removeLocalStorage("customerPhoneNumber");
    removeLocalStorage("loginTime");
  };
  useEffect(() => {
    const storedCartItems =
      JSON.parse(localStorage.getItem(Config.cartItems)) || [];
    dispatch(setCartItemCount(storedCartItems.length));
    const handleClickOutside = (event) => {
      if (userDropRef.current && !userDropRef.current.contains(event.target)) {
        setShowUserDrop(false);
        setShowLanDrop(false);
        setOrderDrop(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  const toggleShowNavbar = () => {
    setShowNav(!showNav);
  };
  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);
  useEffect(() => {
    const storedCartItems =
      JSON.parse(localStorage.getItem(Config.cartItems)) || [];
    dispatch(setCartItemCount(storedCartItems.length));
  });
  return (
    <>
      <Snackbar
        time="10000"
        message={message}
        showSnackbar={showSnackbar}
        setShowSnackbar={setShowSnackbar}
      />
      <nav className="nav__bar">
        <Link to="/" className="logo">
          {/* <img src="img/logotop.png" alt="noImg" /> */}
          <img src={process.env.REACT_APP_LOGO_PATH} alt="noImg" />
        </Link>
        <Links
          logout={logout}
          setShowLanDrop={setShowLanDrop}
          showLanDrop={showLanDrop}
          userDropRef={userDropRef}
          activeRoute={activeRoute}
          setShowNav={setShowNav}
          t={t}
          showNav={showNav}
        />
        <div className="nav__icons">
          <Link
            to="/menu"
            className={`user__icon menu__icon ${
              activeRoute == "/menu" && "active_icon"
            }`}
          >
            <i
              className="fa-solid fa-utensils"
              style={{
                color: "#fff",
              }}
            ></i>
          </Link>
          {window.innerWidth > 714 && (
            <LangIcon
              placeuse="icon__lang"
              setShowLanDrop={setShowLanDrop}
              showLanDrop={showLanDrop}
              userDropRef={userDropRef}
            />
          )}
          <CartIcon activeRoute={activeRoute} />
          <OrderIcon
            placeuse="icon__lang"
            setOrderDrop={setOrderDrop}
            orderDrop={orderDrop}
            userDropRef={userDropRef}
            activeRoute={activeRoute}
          />
          <UserImg
            t={t}
            activeRoute={activeRoute}
            setShowUserDrop={setShowUserDrop}
            showUserDrop={showUserDrop}
            userDropRef={userDropRef}
            setOrderDrop={setOrderDrop}
            orderDrop={orderDrop}
          />
        </div>
        <div className="menu" id="menu" onClick={() => toggleShowNavbar()}>
          <i className={`fa-solid ${showNav ? " fa-xmark" : " fa-bars"}`}></i>
        </div>
      </nav>

      <div className="qr__bar">
        <Link to="/">
          <i
            className={`ri-home-2-fill ${activeRoute == "/" && "active_icon"}`}
          ></i>
        </Link>

        <Link to="/menu">
          <i
            className={`fa-solid fa-utensils ${
              activeRoute == "/menu" && "active_icon"
            }`}
          ></i>
        </Link>

        <CartIcon activeRoute={activeRoute} />
        {getLocalStorage(Config.userToken) && (
          <Link to="/orderinfo">
            <i
              style={{ fontSize: "26px" }}
              class={`ri-git-repository-line ${
                activeRoute == "/orderinfo" && "active_icon"
              }`}
            ></i>
          </Link>
        )}
        {window.innerWidth <= 714 && (
          <UserImg
            t={t}
            activeRoute={activeRoute}
            setShowUserDrop={setShowUserDrop}
            showUserDrop={showUserDrop}
            userDropRef={userDropRef}
            setOrderDrop={setOrderDrop}
            orderDrop={orderDrop}
          />
        )}
      </div>
    </>
  );
}

export default Navbar;
