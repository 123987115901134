import React, { useState, Fragment } from "react";
import { Config } from "../../constant/Index";
import { getLocalStorage } from "../../Component/local_storage";
import PopupMessages from "../../Component/popupMassages/PopupMessages";
import CartItemAccessories from "./CartItemAccessories";
const CartItem = ({
  item,
  sizename,
  size,
  size_group_id,
  cartItems,
  setCartItems,
}) => {
  const errorImageUrl =
    "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png";
  const [showAccessories, setShowAccessories] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const handleToggleAccessories = () => {
    setShowAccessories(!showAccessories);
  };
  const [quantity, setQuantity] = useState(item?.quantity || 1);
  const handleDeleteButtonClick = () => {
    setShowPopup(true);
  };
  const handleRemoveItem = (itemId, sizeGroupId) => {
    const updatedCartItems = cartItems.filter(
      (itema) => itema?.fakeId !== itemId || itema.size !== sizeGroupId
    );
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };
  const handleDecreaseQuantity = (itemId, sizeGroupId) => {
    const updatedQuantity = quantity - 1;
    if (updatedQuantity > 0) {
      setQuantity(updatedQuantity);
    } else {
      handleDeleteButtonClick();
    }
    if (updatedQuantity > 0) {
      updateCartItems(itemId, updatedQuantity, sizeGroupId);
    }
  };
  const handleIncreaseQuantity = (itemId, sizeGroupId) => {
    const updatedQuantity = quantity + 1;
    setQuantity(updatedQuantity);
    updateCartItems(itemId, updatedQuantity, sizeGroupId);
  };
  const updateCartItems = (itemId, updatedQuantity, sizeGroupId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item?.fakeId === itemId && item?.size === sizeGroupId) {
        return {
          ...item,
          quantity: updatedQuantity,
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };
  const handleDecreaseAccessoryQuantity = (
    itemId,
    accessoryId,
    sizeGroupId
  ) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item?.fakeId === itemId && item?.size === sizeGroupId) {
        const updatedAccessories = item.accessories.map((accessory) => {
          if (accessory.id === accessoryId) {
            const updatedQuantity = accessory.quantity - 1;
            return {
              ...accessory,
              quantity: updatedQuantity < 1 ? 1 : updatedQuantity,
            };
          }
          return accessory;
        });
        return { ...item, accessories: updatedAccessories };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };
  const handleIncreaseAccessoryQuantity = (
    itemId,
    accessoryId,
    sizeGroupId
  ) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item?.fakeId === itemId && item?.size === sizeGroupId) {
        const updatedAccessories = item.accessories.map((accessory) => {
          if (accessory.id === accessoryId) {
            if (accessory.quantity < Config.accessoryMaxQty) {
              return {
                ...accessory,
                quantity: accessory.quantity + 1,
              };
            }
          }
          return accessory;
        });
        return {
          ...item,
          accessories: updatedAccessories,
        };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };
  const handleRemoveAccessories = (itemId, accessoryId, sizeGroupId) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item?.fakeId === itemId && item?.size === sizeGroupId) {
        const updatedAccessories = item.accessories.filter(
          (accessory) => accessory.id !== accessoryId
        );
        return { ...item, accessories: updatedAccessories };
      }
      return item;
    });
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };

  return (
    <Fragment key={item?.fakeId}>
      <>
        <div className="cart__card__detail">
          <div className="card__img">
            <img
              src={item?.image}
              onError={(event) => (event.target.src = "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png")}
              alt="Product Img"
            />
          </div>

          <div className="card__detail">
            <div className="title__price">
              <h2 className="card__title">
                {item?.title?.[getLocalStorage("i18nextLng")]
                  ? item?.title?.[getLocalStorage("i18nextLng")]
                  : item?.title?.en}
              </h2>

              <span className="card__price">
                {Config?.currency}
                {item?.price ? item?.price : 0}
                <i
                  className="cart__trash fa-solid fa-trash"
                  onClick={handleDeleteButtonClick}
                ></i>
              </span>
              <h4 className="size">{sizename}</h4>
            </div>
            <div className="add__remove">
              <div
                className="minus"
                onClick={() => handleDecreaseQuantity(item?.fakeId, item?.size)}
              >
                <i className="fa-solid fa-minus" />
              </div>
              <span className="quantity">
                <span>{item?.quantity}</span>
              </span>
              <div
                className="plus"
                onClick={() => handleIncreaseQuantity(item?.fakeId, item?.size)}
              >
                <i className="fa-solid fa-plus" />
              </div>
            </div>

            {item?.accessories?.[0] ? (
              <button
                className="detail__btn btn"
                onClick={handleToggleAccessories}
              >
                <i className="bx bx-chevron-down"></i>
              </button>
            ) : null}

            <span className="card__price total__price">
              {Config?.currency} {item?.quantity * item?.price}
            </span>
          </div>
        </div>

        {/* <div className="cart__product">
          
          <div className="product__img">
            <img src={item?.image} alt="noImg" />
          </div>

          <div className="product__content">
            <span className="product__title">
              
            </span>
          </div>
          
        </div> */}

        {showAccessories && item?.accessories?.length > 0 && (
          <div className="card__accessories">
            {showAccessories && item?.accessories?.length > 0 && (
              <>
                {item.accessories
                  ?.filter((data) => data.quantity > 0)
                  .map((accessory) => (
                    <CartItemAccessories
                      accessory={accessory}
                      handleRemoveAccessories={() =>
                        handleRemoveAccessories(
                          item.fakeId,
                          accessory?.id,
                          item?.size
                        )
                      }
                      handleDecreaseAccessoryQuantity={() =>
                        handleDecreaseAccessoryQuantity(
                          item.fakeId,
                          accessory?.id,
                          item?.size
                        )
                      }
                      handleIncreaseAccessoryQuantity={() =>
                        handleIncreaseAccessoryQuantity(
                          item.fakeId,
                          accessory.id,
                          item?.size
                        )
                      }
                    />
                  ))}
              </>
            )}
          </div>
        )}
      </>
      <PopupMessages
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        confirm={() => {
          handleRemoveItem(item?.fakeId, item?.size);
          setShowPopup(false);
        }}
      />
    </Fragment>
  );
};

export default CartItem;
