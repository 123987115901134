import React, { useEffect, useState } from "react";
import { useGetRestaurantDetailQuery } from "../../redux/services/DZapi";

function SocialIcon() {
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [resData, setResData] = useState();

  useEffect(() => {
    setResData(RestaurantDetail?.data);
  }, [RestaurantDetail]);
  return (
    <>
      {((resData?.restaurant_link?.instagram) && !(resData?.restaurant_link?.instagram == "null")) && (
        <a target="_blank" rel="noreferrer" href={resData?.restaurant_link?.instagram}>
          <i className="bx bxl-instagram-alt" />
        </a>
      )}
      {((resData?.restaurant_link?.facebook) && !(resData?.restaurant_link?.facebook == "null")) && (
        <a target="_blank" rel="noreferrer" href={resData?.restaurant_link?.facebook}>
          <i className="bx bxl-facebook" />
        </a>
      )}
      {((resData?.restaurant_link?.twitter) && !(resData?.restaurant_link?.twitter == "null")) && (
        <a target="_blank" rel="noreferrer" href={resData?.restaurant_link?.twitter}>
          <i className="bx bxl-twitter" />
        </a>
      )}
      {((resData?.restaurant_link?.linkedin) && !(resData?.restaurant_link?.linkedin == "null")) && (
        <a target="_blank" rel="noreferrer" href={resData?.restaurant_link?.linkedin}>
          <i className="bx bxl-linkedin" />
        </a>
      )}
      {((resData?.restaurant_link?.youtube) && !(resData?.restaurant_link?.youtube == "null")) && (
        <a target="_blank" rel="noreferrer" href={resData?.restaurant_link?.youtube}>
          <i className="bx bxl-youtube" />
        </a>
      )}
      {((resData?.restaurant_link?.tiktok) && !(resData?.restaurant_link?.tiktok == "null")) && (
        <a target="_blank" rel="noreferrer" href={resData?.restaurant_link?.tiktok}>
          <i className="bx bxl-tiktok" />
        </a>
      )}
    </>
  );
}

export default SocialIcon;
