import React, { useState, useEffect, useMemo } from "react";
import "./inputField.css";

const InputField = ({
  placeholder,
  type = "text",
  name,
  onChange,
  value,
  showPassword,
  togglePasswordVisibility,
  autofill,
  darkColor,
  className = " ",
  required = false,
  errorText = "Please enter a valid value",
  disabled = false
}) => {
  const [touched, setTouched] = useState(false);
  const [unFilled, setUnFilled] = useState(false);

  useEffect(() => {
    if(value){
      setUnFilled(false);
    }else if (touched && !value && required) {
      setUnFilled(true);
    } else {
      setUnFilled(false);
    }
  }, [touched, value, required]);


  return (
    <>
      <div className={`input__container ${className}`}>
        <input
          value={value}
          onChange={onChange}
          // onBlur={() => setTouched(false)}
          disabled={disabled}
          onFocus={() => setTouched(true)}
          name={name}
          className={`${darkColor ? "input__field__dark" : ""} ${
            (unFilled && required) && "red__color"
          } input__field`}
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          autoComplete={autofill}
        />
        {type === "password" && (
          <>
            {showPassword ? (
              <i
                className="ri-eye-off-fill"
                onClick={togglePasswordVisibility}
              ></i>
            ) : (
              <i className="ri-eye-fill" onClick={togglePasswordVisibility}></i>
            )}
          </>
        )}
      </div>
      {unFilled && required && (
        <span className="error__message red__color">{errorText}</span>
      )}
    </>
  );
};

export default InputField;