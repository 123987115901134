import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Home from "../pages/home/Home";

import Login from "../pages/login/login.js";
import Menu from "../pages/menu/Menu";
import Favourite from "../pages/favourite/Favourite";
import Cart from "../pages/cart/Cart";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Payment from "../pages/payment/Payment";
import Completion from "../pages/payment/Completion";
import User from "../pages/user/User";
import PrivateRoutes from "./PrivateRoutes";
import NotFound from "../pages/notfound/NotFound";
import Layout from "./Layout";
import Orderplaced from "../pages/orderposted/Orderplaced";
import Address from "../pages/Address/Address";
import AddressCheckOut from "../pages/AddressCheckOut/AddressCheckOut";
import Chashtypeselect from "../pages/Chashtypeselect/Chashtypeselect";
import OrderInfo from "../pages/orderList/OrderInfo";
import SignUp from "../pages/signup/SignUp";
import ForgetPassword from "../pages/forgetPassword/ForgetPassword";
import VerifyEmailForgetPassword from "../pages/forgetPassword/VerifyEmailForgetPassword";
import ForgetPasswordNewPassword from "../pages/forgetPassword/ForgetPasswordNewPassword";
import { Config } from "../constant/Index";
import NonPrivateRoutes from "./NonPrivateRoutes";
import TableorderInfo from "../pages/tableOrderList/TableorderInfo";
import PaymentMethod from "../pages/PaymentMethod/PaymentMethod.js";
import { useSelector } from "react-redux";
import PrivacyPolicy from "../pages/login/PrivacyPolicy.js";
import DeleteAccount from "../pages/login/DeleteAccount.js";
import Checkout from './../pages/Checkout/Checkout';
import QuickPayPrivacy from "../pages/termsAndCondition/QuickPayPrivacy.js";
function ReactRoutes() {
  const [activeRoute, setActiveRoute] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const cartItemCount = useSelector((state) => state.cart.cartItemCount)
  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);
  const [isLoggedIn, setIsLoading] = useState(
    localStorage.getItem(Config.userToken)
  );
  const [isGuestLoggedIn, setIsGuestLoggedIn] = useState(
    localStorage.getItem(Config.userTableToken)
  );
  useEffect(() => {
    setIsLoading(localStorage.getItem(Config.userToken));
    setIsGuestLoggedIn(localStorage.getItem(Config.userTableToken));
  });
  const LoginRoute = () => (isLoggedIn ? navigate("/") : <Login />);

  const SignupRoute = () => (isLoggedIn ? navigate("/") : <SignUp />);

  const TableOrderRoute = () =>
    isGuestLoggedIn ? <TableorderInfo /> : navigate("/");

  // const PlaceOrderRoute = () => (cartItemCount  ? <Chashtypeselect /> : <Menu />);
  const PlaceOrderRoute = () => (cartItemCount  ? <Checkout /> : <Menu />);

  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignupRoute />} />
          <Route path="/login" element={<LoginRoute />} />
          <Route path="/privacy" element={<QuickPayPrivacy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route
            path="/verifyEmailForgetPassword"
            element={<VerifyEmailForgetPassword />}
          />
          <Route
            path="/forgetPasswordNewPassword"
            element={<ForgetPasswordNewPassword />}
          />
          <Route path="/completion" element={<Completion />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/about" element={<About />} /> */}
          <Route path="/orderplaced" element={<Orderplaced />} />
          <Route path="/tableorderinfo" element={<TableOrderRoute />} />
          <Route element={<PrivateRoutes activeRoute={activeRoute} />}>
            <Route path="/user" element={<User />} />
            <Route path="/orderinfo" element={<OrderInfo />} />
            <Route path="/address" element={<Address />} />
            <Route path="/favourite" element={<Favourite />} />
            <Route path="/place-order" element={<PlaceOrderRoute />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/PaymentMethod" element={<PaymentMethod/> } />
          </Route>
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </>
  );
}

export default ReactRoutes;
