import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./orderposted.css"
import { useTranslation } from 'react-i18next';
import { useGetRestaurantDetailQuery } from '../../redux/services/DZapi';
function Orderplaced() {
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const [resData, setResData] = useState();
  useEffect(() => {
    setResData(RestaurantDetail?.data);
  }, [RestaurantDetail]);
  const { t } = useTranslation();
  return (
    <div className="complete">
      <div className="tick">
        <i className="fa-solid fa-check" />
      </div>
      <h3 className="subheading">{t('Orderplaced.your_order')}</h3>
      <p className="paragraph">
        {t('Orderplaced.thank_you')}{" "}{resData?.pickup_time}.
      </p>
      <Link to="/menu" className="btn">
        {t('Orderplaced.continue_shoppeing')}
      </Link>
    </div>
  )
}
export default Orderplaced