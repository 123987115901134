import React, { useState, useEffect } from "react";
import Navbar from "../Component/navbar/Navbar";
import Footer from "../Component/footer/Footer";
import { Outlet } from "react-router-dom";

import './TopIcon.css'
import Gdpr from "../Component/GDPR/Gdpr";

export default function Layout() {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to check the scroll position
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return ( 
    <>
      <Navbar />
      {/* <h1 style={{textAlign:"center"}}>Coming Soon</h1> */}
      <Outlet />  
      {showScrollButton && (
        <a
          href="#top-of-page"
          className="goingtoparrow"
          onClick={handleScrollToTop}
        >
          <i 
            className="ri-arrow-up-s-line" 
            style={{ 
              color: 'var(--white-color)',
              fontSize: '20px',
            }}
          ></i>
        </a>
      )}
      <Gdpr/>
      <Footer />
    </>
  );
}
