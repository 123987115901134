import React, { useEffect, useState } from "react";
import CartPItem from "./CartPItem";
import { Config } from "../../constant/Index";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setCartItemCount } from "../../redux/slices/cartSlice";
import Snackbar from "../snackbar/Snackbar";
import { useGetRestaurantDetailQuery } from "../../redux/services/DZapi";
import { useTranslation } from "react-i18next";
import { getLocalStorage, setLocalStorage } from "../local_storage";
import MenuCartAddress from "./MenuCartAddress/MenuCartAddress";
import DawaAutoAddress from "../dawa-address/DawaAutoAddress";
import useRestaurantStatus from "../../Hooks/useRestaurantStatus";
import useDistanceCalculator from "../../Hooks/useDistanceCalculator";
import useFindDistanceAvailable from "../../Hooks/useFindDistanceAvailable";
import useGetRestaurantFeatures from "../../Hooks/useGetRestaurantFeatures";
import useFindAddressId from "../../Hooks/useFindAddressId";
function CartCard() {
  const { findDistanceIsAvailable } = useFindDistanceAvailable();
  const { t } = useTranslation();
  const { isDeliveryPerKm, deliveryPerKmAmount, deliveryPostCodes, fixedAmountOfRadius } =
    useGetRestaurantFeatures();
  const { data: restaurantDetail } = useGetRestaurantDetailQuery();
  const { findAddressId } = useFindAddressId();
  const restaurantStatus = useRestaurantStatus();
  const [restaurantFeatures, setRestaurantFeatures] = useState();
  const [resData, setResData] = useState();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [typeDelivery, setTypeDelivery] = useState("pickup");
  const [showCart, setShowCart] = useState(window.innerWidth >= 1083);
  const dispatch = useDispatch();
  const cartItemCount = useSelector((state) => state.cart.cartItemCount);
  const [comment, setComment] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [massage, setMassage] = useState();
  const [tableNumber, setTableNumber] = useState();
  const [tableId, setTableId] = useState();
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [addressId, setAddressId] = useState();
  const isDeliverInAddress = (post_code) => {
    const address = deliveryPostCodes?.find(
      (devlivery) => devlivery?.postcode == post_code
    );
    return address?.price || 0;
  };
  const isDeliverInAddressByPostalCode = (postcodes, post_code) => {
    const canDeliver =
      postcodes &&
      postcodes?.some((devlivery) => devlivery?.postcode == post_code);
    return canDeliver;
  };
  const handleSetAddressId = (id) => {
    if (
      isDeliverInAddressByPostalCode(
        deliveryPostCodes,
        findAddressId(id)?.post_code
      )
    ) {
      setAddressId(id);
    } else {
      alert("Don't deliver");
    } 
  };
  useEffect(() => {
    setTableNumber(getLocalStorage(Config.tableNumber));
    setTableId(getLocalStorage(Config.tableId));
    if (getLocalStorage(Config.orderType)) {
      if (restaurantStatus) {
        setTypeDelivery(getLocalStorage(Config.orderType));
      } else {
        setTypeDelivery("pickup");
      }
    } else {
      setTypeDelivery("pickup");
    }
  }, []);
  useEffect(() => {
    setDeliveryPrice(
      isDeliveryPerKm
        ? findDistanceIsAvailable(addressId) * deliveryPerKmAmount || fixedAmountOfRadius
        : isDeliverInAddress(findAddressId(addressId)?.post_code)
    );
  }, [addressId]);
  console.log(findDistanceIsAvailable(addressId) * deliveryPerKmAmount)
  const handleShowSnackbar = (massage) => {
    setMassage(massage);
    setShowSnackbar(true);
  };
  const toggleDeliveryType = (type) => {
    setTypeDelivery(type);
    setLocalStorage(Config.orderType, type);
  };
  const toggleshowCart = () => {
    setShowCart(!showCart);
  };
  useEffect(() => {
    setResData(restaurantDetail?.data);
    setRestaurantFeatures(restaurantDetail?.data?.features);
  }, [restaurantDetail]);
  const handleCheckout = () => {
    if (!getLocalStorage(Config.userToken)) {
      navigate("/login");
      return;
    }
    if (cartItemCount) {
      navigate("/place-order", {
        state: {
          typeDelivery: typeDelivery,
          comment: comment,
          addressId: addressId,
          deliveryPrice: deliveryPrice,
        },
      });
    } else {
      handleShowSnackbar(t("popup.CartIsEmpty"));
    }
  };
  const totalPrice = cartItems?.reduce((total, item) => {
    const itemPrice =
      item.price * item.quantity +
      (item.accessories?.length > 0
        ? item.accessories.reduce(
            (acc, accessory) => acc + accessory.price * accessory.quantity,
            0
          )
        : 0);
    return total + itemPrice;
  }, 0);
  useEffect(() => {
    const storedCartItems =
      JSON.parse(localStorage.getItem(Config.cartItems)) || [];
    setCartItems(storedCartItems);
    dispatch(setCartItemCount(storedCartItems.length));
    const handleResize = () => {
      setShowCart(window.innerWidth >= 1083);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [cartItems.length, cartItems.accessories, cartItemCount, totalPrice]);
  const handleRemoveItem = (itemId, sizegroupid) => {
    const updatedCartItems = cartItems.filter(
      (item) => !(item?.fakeId === itemId && item.size === sizegroupid)
    );
    setCartItems(updatedCartItems);
    localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };
  return (
    <>
      <div className="cart__card">
        <div className="header">
          <Snackbar
            message={massage}
            setShowSnackbar={setShowSnackbar}
            showSnackbar={showSnackbar}
          />
          <div
            className="cart__card__title"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ color: "var(--dark-color)" }}>
              {t("CartCard.CartItem")}
            </h4>

            <span className="card__top__price" onClick={toggleshowCart}>
              {Config.currency_symbol}{" "}
              {totalPrice
                ? totalPrice +
                  (typeDelivery === "delivery" ? 50 : 0) -
                  (resData?.web_discount
                    ? (resData?.web_discount / 100) * totalPrice
                    : 0)
                : 0}
            </span>

            <i
              onClick={toggleshowCart}
              style={{
                color: "var(--dark-color)",
              }}
              className="ri-arrow-down-s-line"
            ></i>
          </div>
          {showCart && (
            <div className="cart__card__products">
              {!cartItems?.[0] ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                    justifyContent: "center",
                    color: "var(--text-white)",
                  }}
                >
                  {t("CartCard.CartIsEmpty")}
                </div>
              ) : (
                cartItems?.map((item, index) => {
                  return (
                    <CartPItem
                      index={index}
                      item={item}
                      setCartItems={setCartItems}
                      cartItems={cartItems}
                      handleRemoveItem={handleRemoveItem}
                      cartItemCount={cartItemCount}
                      key={index}
                    />
                  );
                })
              )}
            </div>
          )}
        </div>
        {showCart && (
          <div
            className="total__priceof__cart"
            style={{ backgroundColor: "var(--dark-color)" }}
          >
            {typeDelivery === "delivery" && <DawaAutoAddress />}
            <MenuCartAddress
              handleSetAddressId={handleSetAddressId}
              setAddressId={setAddressId}
              typeDelivery={typeDelivery}
            />
            <div className="tab__changer">
              <div
                className={`exchange__tab ${
                  typeDelivery === "pickup" && "activ"
                }`}
                onClick={() => toggleDeliveryType("pickup")}
              >
                <i className="ri-shopping-bag-3-fill"></i>
                <span>{t("CartCard.Pickup")}</span>
              </div>
              <div
                className={`exchange__tab ${
                  typeDelivery === "delivery" && "activ"
                }`}
                onClick={() => {
                  !(restaurantFeatures?.delivery == "0") &&
                  restaurantFeatures &&
                  getLocalStorage(Config.userToken)
                    ? toggleDeliveryType("delivery")
                    : !(restaurantFeatures?.delivery == "0") &&
                      navigate("/login");
                }}
              >
                <i className="ri-e-bike-2-fill"></i>
                <br></br>
                <span>
                  {t("CartCard.Delivery")}
                  {restaurantFeatures?.delivery == "0" &&
                    restaurantFeatures && (
                      <span className="note">{t("CartCard.NotAvailable")}</span>
                    )}
                </span>
              </div>
            </div>
            <div className="totl__price">
              <div className="price">
                <span className="price__name">{t("CartCard.SubTotal")}</span>
                <span className="price__price">
                  {Config.currency_symbol} {totalPrice ? totalPrice : 0}
                </span>
              </div>
              {typeDelivery === "delivery" && (
                <div className="price">
                  <span className="price__name">
                    {t("CartCard.DeliveryCharges")}
                  </span>
                  <span className="price__price">
                    {Config.currency_symbol}{" "}
                    {typeof deliveryPrice == "decimal"
                      ? deliveryPrice?.toFixed(1)
                      : deliveryPrice}
                  </span>
                </div>
              )}
              {!(resData?.web_discount == 0) && resData?.web_discount && (
                <div className="price">
                  <span className="price__name">{t("CartCard.Discount")}</span>
                  <span className="price__price">
                    {Config.currency_symbol}{" "}
                    {resData?.web_discount
                      ? ((resData?.web_discount / 100) * totalPrice)?.toFixed(2)
                      : 0}
                  </span>
                </div>
              )}
              <div className="price">
                <span className="price__name">{t("CartCard.Total")}</span>
                <span className="price__price">
                  {Config.currency_symbol}{" "}
                  {totalPrice
                    ? (
                        totalPrice -
                        (resData?.web_discount
                          ? (
                              (resData?.web_discount / 100) *
                              totalPrice
                            ).toFixed(2)
                          : 0)
                      ).toFixed(2)
                    : 0}
                </span>
              </div>
            </div>
            {(cartItems?.[0] && tableNumber) || tableId ? (
              <Link
                to="/cart"
                className={`btn ${!cartItems?.[0] && "disable__btn"}`}
              >
                {t("CartCard.Checkout")}
              </Link>
            ) : (
              <button
                className={`btn ${!cartItems?.[0] && "disable__btn"}`}
                onClick={handleCheckout}
              >
                {t("CartCard.Checkout")}
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
}
export default CartCard;
