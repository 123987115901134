// useRestaurantStatus.js
import { useEffect, useState } from "react";
import { useGetRestaurantDetailQuery } from "../redux/services/DZapi";

const useRestaurantStatus = () => {
  const { data: restaurantDetail } = useGetRestaurantDetailQuery();
  const [isRestaurantOpen, setIsRestaurantOpen] = useState(false);
  // console.log(restaurantDetail)
  useEffect(() => {
    const checkRestaurantStatus = () => {
      if (!restaurantDetail || !restaurantDetail.data) {
        setIsRestaurantOpen(false);
        return;
      }

      const openingHours = restaurantDetail.data.opening_hours;

      if (!openingHours) {
        setIsRestaurantOpen(false);
        return;
      }

      const currentDay = new Date().getDay();
      const currentTime = new Date();
      const hours = currentTime.getHours();
      const minutes = currentTime.getMinutes();
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
      const dayOfWeek = [
        "sun",
        "mon",
        "tue",
        "wed",
        "thu",
        "fri",
        "sat",
      ][currentDay].toLowerCase();
      const todayOpeningHours = openingHours[`${dayOfWeek}_from`];

      if (!todayOpeningHours) {
        setIsRestaurantOpen(false);
        return;
      }

      const openingTime = openingHours[`${dayOfWeek}_from`] || "";
      const closingTime = openingHours[`${dayOfWeek}_to`] || "";

      setIsRestaurantOpen(
        formattedTime >= openingTime && formattedTime <= closingTime
      );
    };

    checkRestaurantStatus();
  }, [restaurantDetail]);

  return isRestaurantOpen;
};

export default useRestaurantStatus;
