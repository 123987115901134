import React, { useEffect, useRef, useState } from "react";
import OrderDrop from "./OrderDrop";
import { useGetAllOrdersListQuery } from "../../../redux/services/DZapi";
import { useSelector } from "react-redux";
import { getLocalStorage } from "../../local_storage";
import { Config } from "../../../constant/Index";
import { Link } from "react-router-dom";
function OrderIcon({ userDropRef, orderDrop, setOrderDrop, activeRoute }) {
  const cartItemCount = useSelector((state) => state.cart.cartItemCount);
  const [userorder, setUserorder] = useState([]);
  const {
    data: getorderlist,
    isLoading: loading,
    refetch,
  } = useGetAllOrdersListQuery();
  const toggleOrderDrop = () => {
    setOrderDrop(!orderDrop);
  };
  useEffect(() => {
    const reversedUserOrder = getorderlist?.data?.user_order
      ? [...getorderlist.data.user_order].reverse()
      : [];

    setUserorder(reversedUserOrder);
  }, [getorderlist]);
  useEffect(() => {
    refetch();
  }, [cartItemCount]);
  return (
    <>
      {getLocalStorage(Config.userToken) && (
        <Link
          to="/orderinfo"
          className={`user__icon profile__igm ${
            activeRoute == "/orderinfo" && "active_icon"
          } `}
        >
          <i style={{fontSize: '26px'}} class="ri-git-repository-line"></i>
          {userorder?.[0] && (
            <span className="icon-count">{userorder.filter((item) => item?.order_status === "pending")?.length}</span>
          )}
        </Link>
      )}
    </>
  );
}

export default OrderIcon;
