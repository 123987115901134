import React, { useState } from "react";

import "./CreaditCard.css";
import PopupMessages from "../../../Component/popupMassages/PopupMessages";

function CreaditCard({ data, deleteCard, isSelected, onSelect }) {
  const [showPopup, setShowPopup] = useState(false);
  const handleDeleteButtonClick = (e) => {
    e.stopPropagation();
    setShowPopup(true);
  };
  return (
    <>
      <div
        className={`creadit-_card cursor-p ${
          data?.card_type == "visa"
            ? "visa--back"
            : data?.card_type == "master" || data?.card_type == "mastercard-debet" ? "mastercard--back " : "default"
        }   ${isSelected && "active__payment"}`}
        onClick={onSelect}
      >
        <i
          class="ri-delete-bin-line delete__icon"
          onClick={handleDeleteButtonClick}
        ></i>

        <div className="card__front">
          <span className="card__nubmer">
            {"**** **** **** " + data?.card_number_last_4}
          </span>

          <div className="detail__creadit">
            <span className="card__name">
              {data?.title}
            </span>

            <div className="expiry">
              <span className="exp__month">valid thru</span>
              <span className="exp__num">{data?.expiration_date}</span>
            </div>
          </div>
        </div>

        <div className="card__back">
          <span className="cvc">000</span>
        </div>
      </div>
      <PopupMessages
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        confirm={() => {
          deleteCard(data?.id);
          setShowPopup(false);
        }}
      />
    </>
  );
}

export default CreaditCard;
