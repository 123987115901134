import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Config } from "../constant/Index";
const Languages = ["en", "ar", "tr", "dk"];
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "dk",
    debug: true,
    whitelist: Languages,
    interpolation: { 
      escapeValue: false,
    },
    // backend: {
    //   // Adjust the path to your translation files
    //   loadPath: Config.translation_path,
    // },
    // backend: {
    //   // Adjust the path to your translation files
    //   loadPath: '/user-dzfood/locales/{{lng}}/{{ns}}.json',
    // },
  });
export default i18n;