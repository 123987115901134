import React from "react";
import { useTranslation } from "react-i18next";

function GuestUserForm({
  showPopup,
  setShowPopup,
  name,
  setName,
  phoneNumber,
  setPhoneNumber,
  handlePopupSubmit,
  isSubmitting,
}) {
  const { t } = useTranslation();
  return (
    <>
      {showPopup && (
        <div className="cart__popup">
          <h1 className="title">{t("Cart.yourDetails")}</h1>
          <div className="popup__inputs">
            <div className="input">
              <label htmlFor="nameInput" required="">
                {t("Cart.yourName")} <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="input relative">
              <label htmlFor="phoneNumberInput" required="">
                {t("Cart.youNumber")} <span>*</span>
              </label>
              <span>+45</span>
              <input
                type="number"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="btns">
            <button className="btn" onClick={handlePopupSubmit}>
              {isSubmitting ? (
                <img
                  style={{
                    height: "22px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                  alt="noImg"
                />
              ) : (
                t("Cart.submit")
              )}
            </button>
            <button className="btn" onClick={() => setShowPopup(false)}>
              {t("Cart.cancel")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default GuestUserForm;
