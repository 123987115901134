import React, {  useState } from 'react'
import './AddressAdd.css'
import { useUserAddAddressMutation } from '../../redux/services/DZapi';
import { useTranslation } from 'react-i18next';
function AddressAdd({refetchAddresses}) {
  const {t} = useTranslation()
  const [addaddress] = useUserAddAddressMutation();
  const [data, setData] = useState({
    city: '',
    postCode: '',
    street: '',
    lat: '',
    long: '',
    addresstype: '',
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const openPopup = (sid) => {
    const popup = document.getElementById("address__popup");
    if (popup) {
      popup.style.visibility = "hidden";
    }
  };
  
  function handleupdateaddress() {
    const addsendata = {
      "city": data.city,
      "post_code": data.postCode,
      "street": data.street,
      "address_type": data.addresstype,
      "lat": data.lat,
      "long": data.long,
    };
    addaddress(addsendata)
      .unwrap()
      .then((response) => {
        refetchAddresses();
      })
      .catch((error) => {
        console.error('Error adding address:', error);
      });
    openPopup()
  }

  return (
    <div className="address__popup" id='address__popup'>

      <h1 className="subheading">
        {t("Address.add_address")}
      </h1>

      <div className="address__fields">

        <div className="address__section">
          <div className="input">
            <label htmlFor="cityInput" required="">
              City<span>*</span>
            </label>
            <input type="text" id="cityInput" name="city" placeholder="City" value={data.city} onChange={handleChange} />
          </div>
          <div className="input">
            <label htmlFor="postCodeInput" required="">
              post_code<span>*</span>
            </label>
            <input type="text" id="postCodeInput" name="postCode" placeholder="Post Code" value={data.postCode}
              onChange={handleChange} />
          </div>
          <div className="input">
            <label htmlFor="streetInput" required="">
              Street<span>*</span>
            </label>
            <input type="text" id="streetInput" name="street" placeholder="Street" value={data.street}
              onChange={handleChange} />
          </div>
        </div>


        <div className="address__section">
          <div className="input">
            <label htmlFor="latInput" required="">
              Latitude<span>*</span>
            </label>
            <input type="text" id="latInput" name="lat" placeholder="Latitude" value={data.lat} onChange={handleChange} />
          </div>
          <div className="input">
            <label htmlFor="longInput" required="">
              Longitude<span>*</span>
            </label>
            <input type="text" id="longInput" name="long" placeholder="Longitude" value={data.long}
              onChange={handleChange} />
          </div>
          <div className="input">
            <label htmlFor="addresstypeInput" className='option__user' required="">
              addresstype<span>*</span>
            </label>
            <select id="addresstypeInput" name="addresstype" value={data.addresstype} onChange={handleChange}>
              <option value="work">Work</option>
              <option value="home">Home</option>
              <option value="other">other</option>
            </select>
          </div>
        </div>

      </div>

      <div className="btns">
        <button className="btn" onClick={handleupdateaddress}>
          Submit
        </button>
        <button className="btn" onClick={openPopup}>
          Cancel
        </button>
      </div>
    </div>
  )
}

export default AddressAdd