import React, { useEffect } from "react";
import "./ReloadSnackbar.css";
import { useTranslation } from "react-i18next";
function ReloadSnackbar({ showReloadSnackbar, setShowReloadSnackbar }) {
  const { t } = useTranslation();
  const handleCloseSnackbar = () => {
    setShowReloadSnackbar(false);
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  return (
    <div
      className={`reload__snackbar ${
        showReloadSnackbar ? "reload__snackbar__show" : ""
      }`}
    >
      <i className="ri-close-line" onClick={handleCloseSnackbar}></i>
      <div onClick={handleReloadPage}>
        {t("ReloadSnackbar.message")}
        <i className="ri-restart-line" onClick={handleReloadPage}></i>
      </div>
    </div>
  );
}

export default ReloadSnackbar;
