import React, {useState} from "react";
import { getLocalStorage } from "../../Component/local_storage";
import { Config } from "../../constant/Index";
import PopupMessages from "../../Component/popupMassages/PopupMessages";

function CartItemAccessories({
  accessory,
  handleRemoveAccessories,
  handleDecreaseAccessoryQuantity,
  handleIncreaseAccessoryQuantity,
}) {
    const [showPopup, setShowPopup] = useState(false);
    const handleDeleteButtonClick = () => {
        setShowPopup(true);
      };
  return (
    <div className="accessoriy" key={accessory?.id}>
      <div className="acces__addrem">
        <span>
          <div>
            <i
              className="fa-solid fa-xmark"
              onClick={handleDeleteButtonClick}
            ></i>
          </div>
          {accessory?.title?.[getLocalStorage("i18nextLng")]
            ? accessory?.title?.[getLocalStorage("i18nextLng")]
            : accessory?.title?.en}
        </span>
        <div className="add__remove">
          <div
            className="minus"
            onClick={handleDecreaseAccessoryQuantity}
          >
            <i className="fa-solid fa-minus" />
          </div>
          <span className="quantity">{accessory.quantity}</span>
          <div
            className="plus"
            onClick={handleIncreaseAccessoryQuantity}
          >
            <i className="fa-solid fa-plus" />
          </div>
        </div>
      </div>
      <span className="accessories__price">
        {accessory.quantity * accessory.price}{Config.currency_symbol}
      </span>
      <PopupMessages
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        confirm={() => {
            handleRemoveAccessories()
          setShowPopup(false)
        }}
      />
    </div>
  );
}
export default CartItemAccessories;