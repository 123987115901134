import React from "react";
import { useUserAddressQuery } from "../redux/services/DZapi";

function useFindAddressId() {
  const { data: allAddress } = useUserAddressQuery();
  const findAddressId = (id) => {
    return allAddress?.data?.find((item) => item.id == id);
  };
  return {findAddressId};
}

export default useFindAddressId;
