import React from "react";

import "./Loader.css";
// import EmptyCart from "../../../../public/Icons/EmptyCart"
function Loader({ sz = "100px", height = "100%", img = "img/loader.gif" }) {
  return (
    <div className="loader" style={{ width: "100%", height: "100%"}}>
      <img style={{ width: sz, height: sz }} src={img}></img>
    </div>
  );
}

export default Loader;
