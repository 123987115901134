import React, { useEffect, useState } from "react";
import {
  useAddUserPaymentMethodsMutation,
  useGetUserPaymentMethodsQuery,
  useGetUserUpdateCardMethodQuery,
  useRemoveUserPaymentMethodMutation,
} from "../../redux/services/DZapi";
import PaymentMethodPopup from "../paymentMethodPopup/PaymentMethodPopup";
import Loader from "../loader/Loader";
import SingleCard from "./SingleCard";
import "react-credit-cards/es/styles-compiled.css";
import "./PaymentMethod.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CreaditCard from "./../../pages/PaymentMethod/CreaditCard/CreaditCard";

function PaymentMethodsCard({
  options = true,
  setCreaditCardId,
  cardHeight = "270px",
  addPayment,
}) {
  const { t } = useTranslation();
  const {
    data: PaymentMethods,
    refetch,
    isLoading,
  } = useGetUserPaymentMethodsQuery();
  const [removeUserPaymentMethod] = useRemoveUserPaymentMethodMutation();
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [popup, setPopup] = useState(false);
  const [cardId, setCardId] = useState();
  const [cardData, setCardData] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [isRemovingCard, setIsRemovingCard] = useState(false);
  const [isCreaditCardLoading, setIsCreaditCardLoading] = useState(false);
  const [addUserPaymentMethod, responseCardSave, isLoadingCard] =
    useAddUserPaymentMethodsMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const card_id = searchParams?.get("id");
  // if (card_id) {
  var {
    data: res_get,
    isLoading: isLoadingUpdateCard,
    error: errorUpdateCard,
  } = useGetUserUpdateCardMethodQuery(card_id);
  // }
  const saveCard = async () => {
    setIsCreaditCardLoading(true);
    try {
      const response = await addUserPaymentMethod();

      if (response?.data?.success) {
        const link = response?.data?.data?.url;
        console.log(response?.data);
        console.log(link);
        window.location.href = link;
      }
    } catch (error) {
    } finally {
      setIsCreaditCardLoading(false);
    }
  };
  useEffect(() => {
    if (PaymentMethods?.data) {
      setAllPaymentMethods(PaymentMethods?.data);
      if (options && PaymentMethods?.data?.length > 0) {
        if (setCreaditCardId) {
          setCreaditCardId(PaymentMethods?.data?.[0]?.id);
        }
        setSelectedCard(PaymentMethods?.data?.[0]?.id);
      } else {
        if (setCreaditCardId) {
          setCreaditCardId(null);
        }
      }
    }
    if (card_id) {
      navigate("/place-order", {
        state: { activeSection: "selectPayment", cardSave: true },
      });
    }
    refetch();
  }, [PaymentMethods, options, card_id]);
  const editCard = (id, data) => {
    setCardId(id);
    setCardData(data);
    setPopup(true);
  };
  const deleteCard = async (id) => {
    setIsRemovingCard(true);
    try {
      await removeUserPaymentMethod(id);
      setIsRemovingCard(false);
      refetch();
    } catch (error) {
      console.error("Error deleting payment method:", error);
    }
  };
  const handleCardSelect = (id) => {
    setSelectedCard(id);
    if (setCreaditCardId) {
      setCreaditCardId(id);
    }
  };
  return (
    <>
      <div className="payment__header">
        <h1 className="section__heading">{t("Cashtypeselect.paymentCards")}</h1>
        <div className="btn " onClick={() => saveCard()}>
          {isCreaditCardLoading ? (
            <Loader sz="30px" img="img/smallLoader.gif" />
          ) : (
            <span style={{ padding: "2px 4px" }}>{t("Checkout.AddCard")}</span>
          )}
        </div>
      </div>
      <div className="payment__contanier" style={{ minHeight: cardHeight }}>
        <div
          onClick={() => saveCard()}
          className="d-flex align-center justify-center addCard cursor-p"
        >
          {isCreaditCardLoading ? (
            <Loader sz="30px" img="img/smallLoader.gif" />
          ) : (
            <i class="ri-add-fill"></i>
          )}
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          allPaymentMethods.map((data) => {
            return (
              <CreaditCard
                isRemovingCard={isRemovingCard}
                key={data?.id}
                data={data}
                deleteCard={deleteCard}
                editCard={editCard}
                isSelected={selectedCard == data?.id}
                onSelect={() => handleCardSelect(data?.id)}
              />
            );
            {
              /* <SingleCard
                isRemovingCard={isRemovingCard}
                key={data?.id}
                data={data}
                deleteCard={deleteCard}
                editCard={editCard}
                isSelected={selectedCard === data?.id}
                onSelect={() => handleCardSelect(data?.id)}
              /> */
            }
          })
        )}
      </div>
      <PaymentMethodPopup
        refetch={refetch}
        popup={popup}
        cardId={cardId}
        setCardId={setCardId}
        setPopup={setPopup}
        cardData={cardData}
      />
    </>
  );
}

export default PaymentMethodsCard;
