import React from "react";
import useDistanceCalculator from "./useDistanceCalculator";
import useGetRestaurantFeatures from "./useGetRestaurantFeatures";
import useFindAddressId from "./useFindAddressId";

function useFindDistanceAvailable() {
  const { findAddressId } = useFindAddressId();
  const { lat, long, deliveryDistance, restaurantFeatures } =
    useGetRestaurantFeatures();
  const { calculateDistance } = useDistanceCalculator();
//   console.log(restaurantFeatures);
  function findDistanceIsAvailable(addressId) {
    const address = findAddressId(addressId);
    const distance = calculateDistance(lat, long, address?.lat, address?.long);
    // console.log(distance, deliveryDistance);
    return distance < deliveryDistance ? distance : 0;
  }
  const findCalculateDistance = (lat2, long2) => {
    return calculateDistance(lat, long, lat2, long2);
  };
  return { findDistanceIsAvailable, findCalculateDistance };
}
export default useFindDistanceAvailable;
