import React from "react";
import { useTranslation } from "react-i18next";
function OrderList({
  selectedStatus,
  setSelectedStatus,
  searchTerm,
  setSearchTerm,
  selectedDate,
  setSelectedDate,
  currentPage,
  itemsPerPage,
  setSelectedOrder,
  handleDateChange,
  currentOrders,
  filteredOrders,
  getPageNumbers,
  handlePageChange,
  userorder,
  selectedOrder,
  isLoading,
}) {
  const { t } = useTranslation();
  return (
    <div className="all__orders">
      <div className="order__tabs">
        <div
          className={`tab ${selectedStatus === "All" ? "active__tab" : ""}`}
          onClick={() => setSelectedStatus("All")}
        >
          <span
            className={`tab__name ${selectedStatus === "All" ? "active__tab__name" : ""
              }`}
          >
            {t("OrderList.All")}
          </span>
        </div>
        <div
          className={`tab ${selectedStatus === "pending" ? "active__tab" : ""}`}
          onClick={() => setSelectedStatus("pending")}
        >
          <span
            className={`tab__name ${selectedStatus === "pending" ? "active__tab__name" : ""
              }`}
          >
            {t("OrderList.Pending")}
          </span>
        </div>
        <div
          className={`tab ${selectedStatus === "completed" ? "active__tab" : ""
            }`}
          onClick={() => setSelectedStatus("completed")}
        >
          <span
            className={`tab__name ${selectedStatus === "completed" ? "active__tab__name" : ""
              }`}
          >
            {t("OrderList.Completed")}
          </span>
        </div>
        <div
          className={`tab ${selectedStatus === "cancelled" ? "active__tab" : ""
            }`}
          onClick={() => setSelectedStatus("cancelled")}
        >
          <span
            className={`tab__name ${selectedStatus === "cancelled" ? "active__tab__name" : ""
              }`}
          >
            {t("OrderList.Cancel")}
          </span>
        </div>
      </div>
      <div
        className="order__history__search"
        style={{ display: "flex", gap: "10px" }}
      >
        <div className="order__search">
          <i className="ri-search-line" />
          <input
            type="text"
            placeholder={t("OrderList.Search")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <span className="clear-icon" onClick={() => setSearchTerm("")}>
              <i className="ri-close-line" />
            </span>
          )}
        </div>
        <div className="order__search">
          <i className="ri-calendar-2-line"></i>
          <input
            type="date"
            placeholder={t("OrderList.Search")}
            value={selectedDate}
            onChange={handleDateChange}
          />
          {selectedDate && (
            <span className="clear-icon" onClick={() => setSelectedDate("")}>
              <i className="ri-close-line" />
            </span>
          )}
        </div>
      </div>

      <div className="order__s">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ height: "100px", width: "100px" }}
              src="img/loader.gif"
              alt={t("OrderList.Loading")}
            />
          </div>
        ) : !currentOrders?.[0] ? (
          <div className="empty__history">
            <img src="./img/ordernotavailable.png" />
          </div>
        ) : (
          currentOrders.map((data, index) => {
            const createdDate = new Date(data.created_at).toLocaleString(
              undefined,
              {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }
            );
            return (
              <div
                onClick={() => setSelectedOrder(data)}
                className={`order  ${selectedOrder?.id === data?.id ? "order-active" : null
                  }`}
                key={index}
              >
                <span className="order__subtitle">
                  {t("OrderList.OrderId")} {data?.id}
                </span>
                <span className="order__id">
                  {t("OrderList.Items")}{" "}
                  {data?.order_detail?.[0] ? data?.order_detail?.length : 0}
                </span>
                <span className="order__id">
                  {t("OrderList.Date")}
                  {createdDate}
                </span>
                <span className="order__price">
                  {data?.currency.toUpperCase()} {data?.amount}
                </span>
                <span
                  className={`  ${data?.order_status === "pending"
                    ? "pending"
                    : data?.order_status === "completed"
                      ? "completed"
                      : data?.order_status === "cancelled"
                        ? "cancelled"
                        : data?.order_status === "ready"
                          ? "ready"
                          : data?.order_status === "in-process"
                            ? "ready"
                            : data?.order_status === "deleted"
                              ? "deleted"
                              : null
                    }`}
                >
                  {data?.order_status}
                </span>
                <div className="status__invoice">
                  {data?.order_status === "pending" ? (
                    <svg
                      style={{ height: "25px", width: "25px", fill: 'white' }}
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="100"
                      height="100"
                      viewBox="0 0 50 50"
                    >
                      <path d="M 25 2 C 12.264481 2 2 12.264481 2 25 C 2 37.735519 12.264481 48 25 48 C 37.735519 48 48 37.735519 48 25 C 48 12.264481 37.735519 2 25 2 z M 25 4 C 36.664481 4 46 13.335519 46 25 C 46 36.664481 36.664481 46 25 46 C 13.335519 46 4 36.664481 4 25 C 4 13.335519 13.335519 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 C 22.81904 22.572762 22 23.655572 22 25 C 22 25.471362 22.108361 25.906202 22.289062 26.296875 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.703125 27.710938 C 24.093798 27.891639 24.528638 28 25 28 C 26.7 28 28 26.7 28 25 C 28 23.655572 27.18096 22.572762 26 22.173828 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z"></path>
                    </svg>
                  ) : data?.order_status === "completed" ? (
                    <img
                      style={{ height: "25px", width: "25px" }}
                      src="https://img.icons8.com/color/48/task-completed.png"
                      alt="task-completed"
                    />
                  ) : data?.order_status === "cancelled" ? (
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/fluency/48/000000/cancel.png"
                      alt="cancel"
                    />
                  ) : data?.order_status === "ready" ? (
                    <i className="ri-motorbike-fill" />
                  ) : data?.order_status === "in-process" ? (
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/ios-glyphs/30/000000/data-pending.png"
                      alt="data-pending"
                    />
                  ) : data?.order_status === "deleted" ? (
                    <i className="ri-delete-bin-2-line"></i>
                  ) : null}

                  <span className="name">
                    {t("OrderList.Invoice")}
                    <i className="ri-download-line" />
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className="pagination">
        {currentPage > 1 && (
          <span
            className="pagination__arrow"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            {"<"}
          </span>
        )}
        {getPageNumbers().map((pageNumber) => (
          <span
            key={pageNumber}
            className={`pagination__item ${currentPage === pageNumber ? "active__pagination" : ""
              }`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </span>
        ))}
        {currentPage < Math.ceil(filteredOrders?.length / itemsPerPage) && (
          <span
            className="pagination__arrow"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            {">"}
          </span>
        )}
      </div>
    </div>
  );
}

export default OrderList;
