import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 cartItemCount: 0,
};

const cartSlice = createSlice({
 name: "cart",
 initialState,
 reducers: {
  setCartItemCount: (state, action) => {
   state.cartItemCount = action.payload;
  },
 },
});

export const { setCartItemCount } = cartSlice.actions;

export default cartSlice.reducer;