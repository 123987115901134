import React, { useEffect, useState } from "react";
import "./googlemap.css";
import { useTranslation } from "react-i18next";
import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => <div>{text}</div>;

function GoogleMap({restaurant}) {

  const { t, ready } = useTranslation();

  const defaultProps = {
    center: {   
      
      // lat: 56.1482,
      // lng: 10.2014

      lat: restaurant?.lat * 1,
      lng: restaurant?.long * 1,
    },
    zoom: 16
  };

  return (
    <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
            // bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
            bootstrapURLKeys={{ key: "AIzaSyBE9GfnoeERuFF_ODRaocVnf39tC-Q2zxs" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            >
                <div className="marker" />

        </GoogleMapReact>
    </div>
  );
}

export default GoogleMap;
