import React from "react";
import { Link, useLocation } from "react-router-dom";
import useGetRestaurantFeatures from "../../Hooks/useGetRestaurantFeatures";
import { useTranslation } from "react-i18next";

function ProfileTabe() {
  const { delivery } = useGetRestaurantFeatures();
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div className="profile__tab">
      <Link to="/user" className={`tab ${location.pathname === "/user" && "active__tab"}`}>
        {t("Address.personal")}
      </Link>
      {delivery ? (
        <Link to="/Address" className={`tab ${location.pathname === "/Address" && "active__tab"}`} >
          {t("Address.address")}
        </Link>
      ) : ""}
      <Link to="/orderinfo" className={`tab ${location.pathname === "/orderinfo" && "active__tab"}`} >
        {t("Address.order_info")}
      </Link>
      <Link to="/paymentmethod" className={`tab ${location.pathname === "/paymentmethod" && "active__tab"}`}>
        {t("OrderDetail.PaymentMethod")}
      </Link>
    </div>
  );
}

export default ProfileTabe;
