import React, { useEffect, useState } from "react";
import "./SuggestedProduct.css";
import { useGetAllProductsQuery } from "../../redux/services/DZapi";
import { usePriceForSize } from "../../Hooks/usePriceForSize";
import { Swiper, SwiperSlide } from "swiper/react";
import { setCartItemCount } from "../../redux/slices/cartSlice";
import "swiper/css";
import { getLocalStorage } from "../local_storage";
import { Pagination } from "swiper/modules";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { Config } from "../../constant/Index";

function SuggestedProduct({ data, closePopup, cartItemCount }) {
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem(Config.cartItems)) || []);
  const [getPriceForSize] = usePriceForSize();
  const { data: products } = useGetAllProductsQuery();
  const dispatch = useDispatch();
  const suggestedProductIds = data?.suggested_products?.map((suggestedProduct) => suggestedProduct?.suggested_product_id);
  const suggestedProducts = products?.data?.filter((product) => suggestedProductIds?.includes(product.id.toString()));
  const [currentSlide, setCurrentSlide] = useState(0);
  const [upCardPerView, setUpCardPerView] = useState(3);

  useEffect(() => {
    const determineCardPerView = () => {
      if (window.innerWidth > 930) {
        return 3;
      } else if (window.innerWidth <= 930 && window.innerWidth >= 751) {
        return 2;
      } else {
        return 1;
      }
    };
  
    const determineUpCardPerView = () => {
      if (suggestedProducts.length <= determineCardPerView()) {
        return suggestedProducts.length;
      } else {
        return determineCardPerView();
      }
    };
  
    setUpCardPerView(determineUpCardPerView());
  }, [suggestedProducts, window.innerWidth]);

  const renderImage = (image) => {
    const imageUrl = `${Config.digitalOceanLinkProductLargeImg}${image}`;
    const errorImageUrl = "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png";
    return (
      <>
        {!imageUrl ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img style={{ height: "100px", width: "100px" }} src="img/loader.gif" alt="Loading..." />
          </div>
        ) : (
          <img src={imageUrl} alt="product img" onError={(event) => (event.target.src = errorImageUrl)} />
        )} 
      </>
    );
  };
  const handleCompleteOrder = () => {
    closePopup();
  };
  if(!suggestedProducts[0]){
    closePopup();
  }
  const addToCart = (data) => {
    const existingItem = cartItems.find((product) => product.id === data.id);
    if (!existingItem) {
      const imageUrl = `${Config.digitalOceanLinkProductLargeImg}${data.image}`;
      const productSize = getPriceForSize(data, "size");
      const uniqueId = uuidv4();
      const newItem = {
        id: data.id,
        fakeId: `${data.id}-${uniqueId}`,
        title: data?.title,
        price: getPriceForSize(data),
        quantity: 1,
        image: imageUrl,
        selectedsizename: data.prices.find((item) => item.size_id === productSize)?.size?.title,
        size: productSize,
        size_group_id: data.size_group_id,
        accessories: []
      };
      const updatedCartItems = [...cartItems, newItem];
      localStorage.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
      setCartItems(updatedCartItems);
      const newCartItemCount = cartItemCount + 1;
      dispatch(setCartItemCount(newCartItemCount));
    }
  };
  return (
    <div className="suggested__popup">
      <h1 className="popup__title">Suggested Products</h1>
      <div className="suggested__cards">
        <Swiper
          slidesPerView={upCardPerView}
          onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
          spaceBetween={30}
          loop={true}
          autoplay={{
            delay: 500,
            disableOnInteraction: false,
          }}
        >
          {suggestedProducts?.map((dataI, index) => {
            const alreadyAdded = cartItems.find((product) => product.id === dataI.id);
            return (
              <SwiperSlide key={index} className="suggested__card">
                {renderImage(dataI?.image)}
                <div className="card__content">
                  <div className="title__price">
                    <h3 className="card__title">
                      {dataI?.title[getLocalStorage("i18nextLng")]
                        ? dataI?.title[getLocalStorage("i18nextLng")].length < 10
                          ? dataI?.title[getLocalStorage("i18nextLng")]
                          : dataI?.title[getLocalStorage("i18nextLng")].slice(0, 9) + "..."
                        : dataI?.title?.en.length < 10
                          ? dataI?.title?.en
                          : dataI?.title?.en.slice(0, 9) + "..."}
                    </h3>
                    <span className="price min-w-80 text-end">{getPriceForSize(dataI)} kr</span>
                  </div>
                  <button
                    className={`btn ${alreadyAdded ? "active__btn" : ""}`}
                    onClick={() => addToCart(dataI)}
                  >
                    {alreadyAdded ? <i className="ri-check-double-line"></i> : ""}
                    Added to Cart
                  </button>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="popup__btns">
        <button className="btn" onClick={handleCompleteOrder}>
          Complete Order
        </button>
      </div>
    </div>
  );
}

export default SuggestedProduct;
