import React from "react";
import { Link } from "react-router-dom";
import LangIcon from "../langicon/LangIcon";
import { getLocalStorage } from "../../local_storage";
import { Config } from "../../../constant/Index";
function Links({
  activeRoute,
  t,
  setShowNav,
  showNav,
  setShowLanDrop,
  showLanDrop,
  userDropRef,
  logout,
}) {
  return (
    <div
      className={`nav__links ${showNav && "active__navbar"} `}
      id="nav__links"
    >


      <Link
        className={`link ${activeRoute == "/menu" && "active_icon"}`}
        to="/menu"
        onClick={() => setShowNav(false)}
      >
        {t("Navbar.menu")}
      </Link>

      <Link
        className={`link ${activeRoute == "/contact" && "active_icon"}`}
        to="/contact"
        onClick={() => setShowNav(false)}
      >
        {t("Navbar.contact")}
      </Link>
      <LangIcon
        placeuse="link__lang"
        setShowLanDrop={setShowLanDrop} 
        showLanDrop={showLanDrop}
        userDropRef={userDropRef}
      />
      {getLocalStorage(Config.userToken) ? (
        <></>
        // <div
        //   className={`link logout `}
        //   onClick={() => {logout();setShowNav(false);}}
        // >
        //   {t("Navbar.logout")}
        // </div>
      ) : (
        <Link
          className={`link logout`}
          to="/login"
          onClick={() => setShowNav(false)}
        >
          {t("Navbar.Login")}
        </Link>
      )}
    </div>
  );
}

export default Links;
