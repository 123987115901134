import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForgetPasswordNewPasswordMutation } from "../../redux/services/DZapi";
import "./ForgetPassword.css"
import { useTranslation } from "react-i18next";
function ForgetPasswordNewPassword() {
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [createPassword, { isError, isLoading }] = useForgetPasswordNewPasswordMutation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState({
    code: "",
    email: "",
    newPassword: "",
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const verification_code = localStorage.getItem("verification_code");
    if (storedEmail) {
      setData({ ...data, email: storedEmail, code: verification_code });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const createNewPassword = async (e) => {
    e.preventDefault();

    if (!data.newPassword) {
      setShowPopup(true);
      setMessage("Please add your new password.");
    } else {
      try {
        const response = await createPassword({
          email: data.email,
          verification_code: data.code,
          password: data.newPassword,
        });

        if (response?.data?.success) {
          // Remove email and verification_code from local storage
          localStorage.removeItem("email");
          localStorage.removeItem("verification_code");
          navigate("/login");
        } else if(response?.error?.data?.message) {
          setMessage(response?.error?.data?.message);
          setShowPopup(true);
        }
      } catch (error) {
 
        setShowPopup(true);
        setMessage("Something went wrong. Please try again later.");
      }
    }
  };
  return (
    <>
      <div className="login__section">
        <div className="login__img">
          <img src="img/loginimg.png" alt="Food 3d" />
        </div>
        <form className="login__form">
          <h1 className="title login__title">
            {t("ForgetNewPassword.heading1")}
            <br />
            <span>{t("ForgetNewPassword.heading2")}</span>
          </h1>
          <div className="form__inputs">
            <div className="input relative__input">
              <label htmlFor="password">{t("ForgetNewPassword.newPassword")}</label>
              <input
                id="newPasswordInput"
                type={showPassword ? "text" : "password"}
                name="newPassword"
                value={data.newPassword}
                placeholder={t("ForgetNewPassword.newPassword")}
                onChange={handleInputChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="show-hide-button hideshow"
              >
                {showPassword ? (
                  <i className="ri-eye-line"></i>
                ) : (
                  <i className="ri-eye-off-line"></i>
                )}
              </button>
            </div>
          </div>
          <button
            className="btn"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={createNewPassword}
          >
            {isLoading ? 
            <img
            style={{
              height: "22px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
            src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
            alt="noImg"
          />
            : t("ForgetNewPassword.submit")}
          </button>
        </form>
        {showPopup && (
          <div className="universal__popup">
            <h2 className="small__heading">Error</h2>
            <p className="paragraph">{message}</p>
            <button className="btn" onClick={() => setShowPopup(false)}>
              OK
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default ForgetPasswordNewPassword;
