import React, { useEffect, useState } from "react";
import { useUserAddressQuery } from "../../../redux/services/DZapi";

const MenuCartAddress = ({ typeDelivery, handleSetAddressId, setAddressId }) => {
  const { data: getuseraddress, refetch } = useUserAddressQuery();
  const [useraddress, setUseraddress] = useState([]);

  useEffect(() => {
    setUseraddress(getuseraddress?.data);
    setAddressId(getuseraddress?.data?.[0]?.id);
  }, [getuseraddress]);

  return (
    <>
      {typeDelivery === "delivery" && (
        <select id="" className="addressess__drop"  style={{ width: '100%' }} onChange={(e) => handleSetAddressId(e.target.value)}>
          {useraddress?.length > 0 &&
            useraddress?.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item?.street}
                </option>
              );
            })}
        </select>
      )}
    </>
  );
}

export default MenuCartAddress;