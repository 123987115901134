import React, { useEffect, useState } from "react";
import AddressAdd from "../../Component/address-popup/AddressAdd";
import { Link } from "react-router-dom";
import {
  useUserAddressUpdateMutation,
  useUserAddressQuery,
  useUserDeleteAddressMutation,
  useGetLoginUserQuery,
} from "../../redux/services/DZapi";

import { useTranslation } from "react-i18next";
import ProfileTabe from "../../Component/profileTabe/ProfileTabe";

function Address() {
  const { t } = useTranslation();
  const [updateMessage, setUpdateMessage] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [useraddress, setUseraddress] = useState([]);
  const [oneuseraddress, setOneuseraddress] = useState([]);
  const { data: getuseraddress, refetch } = useUserAddressQuery();
  const [updateuseraddress] = useUserAddressUpdateMutation();
  const [delteaddressData] = useUserDeleteAddressMutation();
  const { data: getuser } = useGetLoginUserQuery();
  const [userData, setUserData] = useState();
  const [data, setData] = useState({
    city: "",
    postCode: "",
    street: "",
    address: "",
    lat: "",
    long: "",
    addresstype: "work",
  });

  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0); // Assuming you need this state

  const showUpdateMessage = (message) => {
    setUpdateMessage(message);
    setTimeout(() => {
      setUpdateMessage("");
    }, 3000); // Display message for 3 seconds (adjust as needed)
  };

  useEffect(() => {
    setUserData(getuser?.data?.user);
    setUseraddress(getuseraddress?.data);
    setOneuseraddress(getuseraddress?.data?.[0]);
  }, [getuseraddress?.data]);

  const addressChange = (id) => {
    setOneuseraddress(getuseraddress?.data?.[id]);
    setSelectedAddressIndex(id);
  };

  useEffect(() => {
    setData({
      city: oneuseraddress?.city,
      postCode: oneuseraddress?.post_code,
      street: oneuseraddress?.street,
      address: oneuseraddress?.full_address,
      lat: oneuseraddress?.lat,
      long: oneuseraddress?.long,
      address_type: oneuseraddress?.address_type,
    });
  }, [selectedAddressIndex, useraddress]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleDeleteAddress = (id) => {
    delteaddressData(id)
      .unwrap()
      .then((response) => {
        refetch();
      })
      .catch((error) => {
        console.error("Error deleting address:", error);
      });
    setShowPopup(false);
  };

  function handleupdateaddressdata() {
    const upaddresdata = {
      city: data.city,
      post_code: data.postCode,
      street: data.street,
      address_type: data.address_type,
      lat: data.lat,
      long: data.long,
    };
    const senddata = {
      id: oneuseraddress?.id,
      sdata: upaddresdata,
    };
    updateuseraddress(senddata)
      .unwrap()
      .then((response) => {
        showUpdateMessage("Address Updated"); // Show the message
      })
      .catch((error) => {
        console.error("Error updating address:", error);
      });
  }

  const openPopup = () => {
    const popup = document.getElementById("address__popup");
    if (popup) {
      popup.style.visibility = "visible";
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleupdateaddressdata();
  };
  function deletepopup(id) {
    setShowPopup(id);
  }
  return (
    <>
      <div className="profile__page">
        <div className="profile__section">
<ProfileTabe/>
          {/* <h2 className="title">Address</h2> */}

          {/* Address Add Popup */}
          <AddressAdd userId={userData?.id} refetchAddresses={refetch} />

          <div className="table">
            <span>
              <span>{t("Address.address")}</span>
              <div>
                <i className="bx bx-plus" onClick={openPopup}></i>
              </div>
            </span>

            <div className="address__table">
              <table>
                {useraddress?.map((address, index) => (
                  <span
                    key={address.id}
                    className={
                      selectedAddressIndex === index ? "active__address" : ""
                    }
                    onClick={() => addressChange(index)}
                  >
                    {address?.full_address}
                    <i
                      className="bx bxs-trash-alt"
                      onClick={() => deletepopup(address?.id)}
                    />
                  </span>
                ))}
              </table>
            </div>
          </div>

          <div className="inputs">
            <div className="input">
              <label htmlFor="cityInput" required="">
                {t("Address.city")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="cityInput"
                name="city"
                placeholder="City"
                value={data.city}
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label htmlFor="postCodeInput" required="">
                {t("Address.post_code")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="postCodeInput"
                name="postCode"
                placeholder="Post Code"
                value={data.postCode}
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label htmlFor="streetInput" required="">
                {t("Address.street")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="streetInput"
                name="street"
                placeholder="Street"
                value={data.street}
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label htmlFor="addressInput" required="">
                {t("Address.address")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="addressInput"
                name="address"
                placeholder="Address"
                value={data.address}
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label htmlFor="latInput" required="">
                {t("Address.latitude")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="latInput"
                name="lat"
                placeholder="Latitude"
                value={data.lat}
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label htmlFor="longInput" required="">
                {t("Address.longitude")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="longInput"
                name="long"
                placeholder="Longitude"
                value={data.long}
                onChange={handleChange}
              />
            </div>
            <div className="input">
              <label
                htmlFor="addresstypeInput"
                className="option__user"
                required=""
              >
                {t("Address.address_type")}
                <span>*</span>
              </label>
              <select
                id="addresstypeInput"
                name="addresstype"
                value={data.address_type}
                onChange={handleChange}
              >
                <option value="work">{t("Address.home")}</option>
                <option value="home">{t("Address.work")}</option>
                <option value="other">{t("Address.other")}</option>
              </select>
            </div>
          </div>

          <div className="btns">
            <button className="btn" onClick={handleSubmit}>
              {t("Address.submit")}
            </button>
          </div>
        </div>
      </div>
      {updateMessage && (
        <div className="universal__popup">
          <span className="success">{updateMessage}</span>
        </div>
      )}
      {showPopup ? (
        <>
          <div className="universal__popup">
            <h2 className="small__heading">{t("Address.delete")}</h2>
            <p className="paragraph">{t("Address.yo_really")}</p>
            <button
              className="btn"
              onClick={() => handleDeleteAddress(showPopup)}
            >
              {t("Address.ok")}
            </button>
            <button className="btn" onClick={() => setShowPopup(false)}>
              {t("Address.cancel")}
            </button>
          </div>
        </>
      ) : null}
    </>
  );
}

export default Address;
