import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "./gallery.css";
import { useGetGalleriesQuery } from "../../redux/services/DZapi";
import { Config } from "../../constant/Index";
// swiper-slide-thumb-active
const Gallery = () => {
  const { data: res_get } = useGetGalleriesQuery();
  const { t } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [gallerySlider, setGallerySlider] = useState(false);
  return (
    <>
      {res_get?.data?.gallery_images?.length > 3 && (
        <div
          style={{
            padding: "64px 6vw",
            background: "#FFFFFF",
          }}
          className="flex gap-[24px] flex-col gallery__section"
        >
          <div className="flex flex-col gap-[20px]">
            {/* <h2
              style={{ color: "#101828", fontSize: "36px", fontWeight: "600" }}
            >
              {t("Home_Page.gallery")}
            </h2> */}
            {/* <p
              style={{ color: "#475467", fontSize: "20px", fontWeight: "500" }}
            >
              Description
            </p> */}
          </div>

          <div className="gallery__container">
            {res_get?.data?.gallery_images?.map((image, index) => {
              if (index > 4) return null;
              const src = image?.image;
              return (
                <div
                  className="gallery__image cursor__p"
                  onClick={() => setGallerySlider(true)}
                >
                  <img
                    src={`${Config.digitalOceanLinkGalleryMediumImg}${src}`}
                    alt="#"
                    className="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
            {res_get?.data?.gallery_images?.map((image, index) => {
              if (index > 4) return null;
              const src = image?.image;
              return (
                <div
                  className="gallery__image cursor__p"
                  onClick={() => setGallerySlider(true)}
                >
                  <img
                    src={`${Config.digitalOceanLinkGalleryMediumImg}${src}`}
                    alt="#"
                    className="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: gallerySlider ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              position: "fixed",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              padding: "32px",
              zIndex: "9999",
              backgroundColor: "rgba(0,0,0,0.95)",
            }}
          >
            <i
              onClick={() => setGallerySlider(false)}
              className="ri-close-line"
              style={{
                fontSize: "24px",
                color: "#FFF",
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
                transition: "color 0.3s",
                ":hover": { color: "#EEE" },
                "@media (max-width: 640px)": { top: "5px", right: "5px" },
              }}
            ></i>
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
                maxWidth: "1000px",
                width: "100%",
                maxHeight: "100%",
                height: "100%",
                borderRadius: "10px",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="sliderServiceDetail"
            >
              {res_get?.data?.gallery_images?.map((image, index) => {
                if (index > 4) return null;
                const src = image?.image;
                return (
                  <SwiperSlide
                    style={{
                      height: "100%",
                      overflow: "hidden",
                      borderRadius: "10px",
                    }}
                  >
                    <div style={{ height: "100%", maxWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <img
                        src={`${Config.digitalOceanLinkGalleryMediumImg}${src}`}
                        alt=""
                        style={{
                          borderRadius: "10px",
                          width: "auto",
                          maxWidth: "100%",
                          objectFit: "cover",
                          height: "100%",
                          verticalAlign: "middle",
                        }}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {window.innerWidth > 640 && (
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  400: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1024: {
                    slidesPerView: 8,
                    spaceBetween: 10,
                  },
                }}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                style={{ width: "100%", maxHeight: "220px" }}
                className="mySwiper"
              >
                {res_get?.data?.gallery_images?.map((image, index) => {
                  if (index > 4) return null;
                  const src = image?.image;
                  return (
                    <SwiperSlide
                      style={{
                        height: "100%",
                        overflow: "hidden",
                        borderRadius: "10px",
                        maxHeight: "140px",
                      }}
                    >
                      <img
                        src={`${Config.digitalOceanLinkGalleryMediumImg}${src}`}
                        alt=""
                        style={{
                          borderRadius: "10px",
                          minWidth: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Gallery;
