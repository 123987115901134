import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux"; // Import useDispatch
import { setCartItemCount } from "../../redux/slices/cartSlice";
import "./newCart.css";

import {
  useAddOrderMutation,
  useAddTableOrderMutation,
  useAddTableOrderUserMutation,
  useGetRestaurantDetailQuery,
} from "../../redux/services/DZapi";
import "./cart.css";
import { Link, useNavigate } from "react-router-dom";
import CartItem from "./CartItem";
import { useTranslation } from "react-i18next";
import { Config } from "../../constant/Index";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../Component/local_storage";
import { removeListener } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import GuestUserForm from "./GuestUserForm";
import Snackbar from "../../Component/snackbar/Snackbar";
import MenuCartAddress from "../../Component/cart/MenuCartAddress/MenuCartAddress";
import DawaAutoAddress from "../../Component/dawa-address/DawaAutoAddress";
import useGetRestaurantFeatures from "../../Hooks/useGetRestaurantFeatures";
import useFindDistanceAvailable from "../../Hooks/useFindDistanceAvailable";
import useFindAddressId from "../../Hooks/useFindAddressId";
function Cart() {
  const [addOrder, responseOrder] = useAddOrderMutation();
  const [addTableOrder, responseTableOrder, isTableOrderLoading] =
    useAddTableOrderMutation();
  const [addTableOrderUser, responseTableOrderUser] =
    useAddTableOrderUserMutation();
  const [typeDelivery, setTypeDelivery] = useState("pickup");
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();
  const { t } = useTranslation();
  const [cartItems, setCartItems] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resData, setResData] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [addressId, setAddressId] = useState();
  const { data: restaurantDetail } = useGetRestaurantDetailQuery();
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const {
    isDeliveryPerKm,
    deliveryPerKmAmount,
    deliveryPostCodes,
    fixedAmountOfRadius,
  } = useGetRestaurantFeatures();
  const { findDistanceIsAvailable } = useFindDistanceAvailable();
  const { findAddressId } = useFindAddressId();
  const isDeliverInAddress = (post_code) => {
    const address = deliveryPostCodes?.find(
      (devlivery_postcode) => devlivery_postcode?.postcode == post_code
    );
    return address?.price || 0;
  };
  useEffect(() => {
    setDeliveryPrice(
      isDeliveryPerKm
        ? findDistanceIsAvailable(addressId) * deliveryPerKmAmount || fixedAmountOfRadius
        : isDeliverInAddress(findAddressId(addressId)?.post_code)
    );
  }, [addressId]);
  console.log(typeof isDeliverInAddress(findAddressId(addressId)?.post_code));
   const isDeliverInAddressByPostalCode = (postcodes, post_code) => {
    const canDeliver =
      postcodes &&
      postcodes?.some((devlivery) => devlivery?.postcode == post_code);
    return canDeliver;
  };
  const handleSetAddressId = (id) => {
    if (
      isDeliverInAddressByPostalCode(
        deliveryPostCodes,
        findAddressId(id)?.post_code
      )
    ) {
      setAddressId(id);
    } else {
      alert("Don't deliver");
    }
  };
  function checkout() {
    if (getLocalStorage(Config.userTableToken)) {
      addPayment1();
    } else {
      setShowPopup(true);
    }
  }
  const toggleDeliveryType = (type) => {
    setTypeDelivery(type);
    setLocalStorage(Config.orderType, type);
  };
  useEffect(() => {
    setName(getLocalStorage("customerName") || "");
    setPhoneNumber(getLocalStorage("customerPhoneNumber") || "");
    setTypeDelivery(getLocalStorage(Config.orderType) || "pickup");
  }, []);
  useEffect(() => {
    setResData(RestaurantDetail?.data);
  }, [RestaurantDetail]);
  function addPayment() {
    navigate("/place-order", {
      state: {
        typeDelivery: typeDelivery,
        deliveryPrice: deliveryPrice,
        addressId: addressId,
      },
    });
  }
  useEffect(() => {
    const storedCartItems =
      JSON.parse(localStorage.getItem(Config.cartItems)) || [];
    setCartItems(storedCartItems);
    const totalQuantity = storedCartItems?.reduce(
      (total, item) => total + item?.quantity,
      0
    );
    dispatch(setCartItemCount(totalQuantity));
  }, [dispatch, cartItems?.length]);
  const handlePopupSubmit = () => {
    if (name == "" || phoneNumber == "") {
      setMessage("Please fill all the fields");
      setShowSnackbar(true);
      return;
    }
    localStorage.setItem("customerName", name);
    localStorage.setItem("customerPhoneNumber", phoneNumber);
    addPayment1();
  };
  const totalPrice = cartItems?.reduce((total, item) => {
    const itemPrice =
      item.price * item.quantity +
      (item.accessories?.length > 0
        ? item.accessories.reduce(
            (acc, accessory) => acc + accessory.price * accessory.quantity,
            0
          )
        : 0);

    return total + itemPrice;
  }, 0);
  async function addPayment1() {
    setIsSubmitting(true);
    try {
      const totalPrice = cartItems?.reduce((total, item) => {
        const itemPrice =
          item.price * item.quantity +
          (item.accessories?.length > 0
            ? item.accessories.reduce(
                (acc, accessory) => acc + accessory.price * accessory.quantity,
                0
              )
            : 0);
        return total + itemPrice;
      }, 0);
      const cartData = localStorage.getItem(Config.cartItems);
      const parsedCartItems = JSON.parse(cartData);
      const allAccessories = [];
      parsedCartItems?.forEach((item) => {
        item?.accessories?.forEach((accessory) => {
          if (accessory?.checked) {
            allAccessories.push(accessory);
          }
        });
      });
      const form = new FormData();
      form.append("amount", totalPrice);
      if (selectedPaymentMethod) {
        form.append("payment_type", selectedPaymentMethod);
      }
      form.append(
        "comments",
        location?.state?.comment ? location?.state?.comment : null
      );
      form.append("discount", 0);
      form.append("cartItems", JSON.stringify(parsedCartItems));
      if (getLocalStorage(Config.tableId)) {
        form.append("table_id", getLocalStorage(Config.tableId));
      }
      if (getLocalStorage(Config.tableId)) {
        form.append("table_number", getLocalStorage(Config.tableNumber));
      }
      form.append("name", name);
      form.append("phone", phoneNumber);
      if (getLocalStorage(Config.userTableToken)) {
        const response = await fetch(
          `${Config.serverUrl}api/user-table-add-order`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                Config?.userTableToken
              )}`,
              Accept: "application/json",
            },
            body: form,
          }
        );
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success) {
            localStorage.removeItem(Config.cartItems);
            navigate("/OrderPlaced");
          } else {
            console.error("Unsuccessful response:", responseData);
            setMessage("An error occurred while processing your request.");
            setShowSnackbar(true);
          }
        } else {
          console.error("Error:", response.status);
          setMessage("An error occurred while communicating with the server.");
          setShowSnackbar(true);
        }
      } else {
        addTableOrder(form)
          .unwrap()
          .then((response) => {
            if (response?.success) {
              setLocalStorage(
                Config.userTableToken,
                response?.data?.guest_user_token
              );
              localStorage.removeItem(Config.cartItems);
              navigate("/OrderPlaced");
              const loginTime = Date.now();
              setLocalStorage("loginTime", loginTime);
              setIsSubmitting(false);
            } else {
              console.error("Unsuccessful response:", response);
              setIsSubmitting(false);
              setMessage(response?.data?.message);
              setShowSnackbar(true);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setMessage(
              "An error occurred while communicating with the server."
            );
            setShowSnackbar(true);
          });
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred while processing your request.");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
    }
  }
  return (
    <>
      <Snackbar
        message={message}
        setShowSnackbar={setShowSnackbar}
        showSnackbar={showSnackbar}
      />
      <GuestUserForm
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        name={name}
        setName={setName}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        handlePopupSubmit={handlePopupSubmit}
        isSubmitting={isSubmitting}
      />
      <div className="cart__section">
        <div className="cart__items">
          <h3 className="sub__heading">{t("Cart.your_order")}</h3>

          <div className="cart__items">
            {cartItems?.length === 0 ? (
              <>
                <img src="img/cartempty.png" alt="empty cart img" />
                <p className="paragraph">{t("Cart.you_have")}</p>
                <Link to="/menu" className="btn">
                  {t("Cart.explore_menu")}
                </Link>
              </>
            ) : (
              cartItems?.map((item) => (
                <CartItem
                  key={item?.id}
                  item={item}
                  sizename={
                    item?.selectedsizename?.[getLocalStorage("i18nextLng")]
                      ? item?.selectedsizename?.[getLocalStorage("i18nextLng")]
                      : item?.selectedsizename?.en
                  }
                  size={item?.size}
                  size_group_id={item?.size_group_id}
                  cartItems={cartItems}
                  setCartItems={setCartItems}
                />
              ))
            )}
          </div>
        </div>
        <div className="side__cart">
          {typeDelivery === "delivery" && <DawaAutoAddress />}
          <MenuCartAddress
            handleSetAddressId={handleSetAddressId}
            setAddressId={setAddressId}
            typeDelivery={typeDelivery}
            addressId={addressId}
          />
          <div className="tab__changer">
            <div
              className={`exchange__tab ${
                typeDelivery === "pickup" && "activ"
              }`}
              onClick={() => toggleDeliveryType("pickup")}
            >
              <i className="ri-shopping-bag-3-fill"></i>
              <span>{t("CartCard.Pickup")}</span>
            </div>
            <div
              className={`exchange__tab ${
                typeDelivery === "delivery" && "activ"
              }`}
              onClick={() => {
                !(restaurantDetail?.data?.features?.delivery == "0") &&
                restaurantDetail?.data &&
                getLocalStorage(Config.userToken)
                  ? toggleDeliveryType("delivery")
                  : !(restaurantDetail?.data?.features?.delivery == "0") &&
                    navigate("/login");
              }}
            >
              <i className="ri-e-bike-2-fill"></i>
              <br></br>
              <span>
                {t("CartCard.Delivery")}
                {restaurantDetail?.data?.features?.delivery == "0" &&
                  restaurantDetail?.data?.features && (
                    <span className="note">{t("CartCard.NotAvailable")}</span>
                  )}
              </span>
            </div>
          </div>

          <div className="calculate">
            <div className="subtotal">
              <span className="text">{t("Cart.sub_total")}</span>
              <span className="price">
                {Config.currency_symbol} {totalPrice}
              </span>
            </div>

            {!(resData?.web_discount == 0) && resData?.web_discount && (
              <div className="subtotal">
                <span className="price__name">{t("CartCard.Discount")}</span>
                <span className="price__price">
                  {Config.currency_symbol}{" "}
                  {resData?.web_discount
                    ? ((resData?.web_discount / 100) * totalPrice).toFixed(2)
                    : 0}
                </span>
              </div>
            )}

            {typeDelivery == "delivery" && (
              <div className="subtotal">
                <span className="text">{t("Cart.delivery_fee")}</span>
                <span className="price">
                  {Config.currency_symbol} {deliveryPrice}
                </span>
              </div>
            )}
            <hr />
            <div className="total">
              <span className="text">{t("CartCard.Total")}</span>
              <span className="price">
                {Config.currency_symbol}
                {totalPrice
                  ? (
                      totalPrice -
                      (resData?.web_discount
                        ? (resData?.web_discount / 100) * totalPrice
                        : 0)
                    ).toFixed(2)
                  : 0}
              </span>
            </div>
          </div>
          {cartItems?.length > 0 ? (
            <>
              {getLocalStorage(Config.tableId) ? (
                <button
                  onClick={() => checkout()}
                  className="btn checkout__btn"
                >
                  {isSubmitting ? (
                    <img
                      style={{
                        height: "28px",
                        paddingLeft: "8px",
                        paddingRight: "8px",
                      }}
                      src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                      alt=""
                    />
                  ) : (
                    t("Cart.checkout")
                  )}
                </button>
              ) : (
                <button
                  onClick={() => addPayment()}
                  className="btn checkout__btn"
                >
                  {t("Cart.checkout")}
                </button>
              )}
            </>
          ) : (
            <button
              className="btn checkout__btn"
              style={{ cursor: "not-allowed", backgroundColor: "gray" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                  flexDirection: "column",
                }}
              >
                {t("Cart.checkout")}
                <span>{t("Cart.you_have")}</span>
              </div>
            </button>
          )}
          {/* coming soon end */}
          {/* <button
            className="btn checkout__btn"
            style={{ cursor: "not-allowed", backgroundColor: "gray" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                flexDirection: "column",
              }}
            >
              {t("Cart.checkout")}
              <span>{t("Cart.you_have")}</span>
            </div>
          </button> */}
        </div>
      </div>
    </>
  );
}

export default Cart;
