import React, { useEffect, useState } from "react";
import "./gdpr.css";
import { t } from "i18next";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
const Gdpr = () => {
  const [gdpr, setGdpr] = useState(0);
  const setCookie = () => {
    console.log("setCookie");
    Cookies.set("gdpr", "allow");
    setGdpr(1);
  };
  useEffect(() => {
    if (Cookies.get("gdpr")) {
      setGdpr(1);
    }
  }, []);
  return (
    <div>
      {gdpr}
      {Cookies.get("gdpr") ? (
        <></>
      ) : (
        <div className={"gdpr-container"}>
          <div>
            {Cookies.get("gdpr")}
            {t("gdpr.text")}
            &nbsp;
            <a href="https://2gdpr.com/cookies" target="_blank">
              {t("gdpr.read_more")}
            </a>
            <div className="button-container">
              <Button
                className="button"
                variant="contained"
                onClick={setCookie}
              >
                {" "}
                {t("gdpr.set")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Gdpr;
