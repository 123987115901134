import React from "react";

const ProgressBar = ({activeSection}) => {
  return (
    <div className="progress__bar">
      <span className="progress__number progress__done">1</span>

      <div className={`progress__line ${activeSection === "selectPayment" || activeSection === "orderCompleted" ? "progress__done" : ""}`}></div>

      <span className={`progress__number ${activeSection === "selectPayment" || activeSection === "orderCompleted" ? "progress__done" : ""}`}>2</span>

      <div  className={`progress__line ${activeSection === "orderCompleted" ? "progress__done" : ""}`}></div>

      <span className={`progress__number ${activeSection === "orderCompleted" ? "progress__done" : ""}`}>3</span>
    </div>
  );
};

export default ProgressBar;
