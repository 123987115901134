import React, { useState } from "react";
import "./login.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Config } from "../../constant/Index";
import { setLocalStorage } from "../../Component/local_storage";
import { useTranslation } from "react-i18next";
import Snackbar from "../../Component/snackbar/Snackbar";
import { useLoginWithFacebookMutation } from "../../redux/services/DZapi";
import FacebookLogin from "react-facebook-login";
import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { LoginSocialFacebook } from "reactjs-social-login";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
// const firebaseConfig = {
//   apiKey: "AIzaSyB6wyYJrNuyjVdw8GL5DwsboBauqdcZ3Ow",
//   authDomain: "dz-foods.firebaseapp.com",
//   projectId: "dz-foods",
//   storageBucket: "dz-foods.appspot.com",
//   messagingSenderId: "222586656529",
//   appId: "1:222586656529:web:a158ae8f13867c12681eb0",
//   measurementId: "G-0ND0TKP95T",
// };
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const provider = new GoogleAuthProvider();
// provider.setCustomParameters({ prompt: "select_account" });

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [addFacebookUser] = useLoginWithFacebookMutation();
  const [message, setMessage] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isValidEmail = (email) => {
    return email.includes("@");
  };
  const responseFacebook = async (response) => {
    if (response.accessToken) {
      try {
        const facebookResponse = await addFacebookUser({
          token: response.accessToken,
        });
        if (facebookResponse.data.message === "successfully") {
          setLocalStorage(
            Config.userToken,
            facebookResponse?.data?.data?.access_token
          );
          if (location.state && location.state.from) {
            navigate(location.state.from);
          } else {
            navigate("/menu");
          }
        } else {
        }
      } catch (error) {
      }
    }
  };
  // function GoogleLogin1() {
  //   return (
  //     <button className="google__button" onClick={signInWithGoogle}>
  //       <i className="fab fa-google"></i> Sign in with google
  //     </button>
  //   );
  // }
  const signInWithGoogle = async (data) => {
    // e.preventDefault();
    try {
      // const result = await signInWithPopup(auth, provider);
      // const user = result.user;
      const response = await fetch(`${Config.serverUrl}api/google-login-web`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      });
      if (response.ok) {
        const data = await response.json();
       
        // if (location?.state && location?.state?.from) {
        //   navigate(location?.state?.from);
        // } else {
          if (data?.data?.access_token) {
            setLocalStorage(Config.userToken, data?.data?.access_token);
            navigate("/menu");
          }
        // }
      } else {
        console.error("API request failed");
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };
  const log1 = async (e) => {
    e.preventDefault();
    if (data.email && data.password) {
      setLoader(true);
      const response = await fetch(`${Config.serverUrl}api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: data?.email,
          password: data?.password,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === "successfully") {
          setLocalStorage(Config.userToken, responseData?.data?.access_token);
          if (location?.state && location?.state?.from) {
            navigate(location.state.from);
          } else {
            navigate("/menu");
          }
        } else {
          setShowPopup(true);
        }
      } else {
        console.error("Error:", response.status);
      }
    } else {
      if (!data.email) {
        setMessage("Fill The Required Field");
        setShowSnackbar(true);
      } else if (!data.password) {
        setMessage(t("popup.FillTheRequiredFiled"));
        setShowSnackbar(true);
      } else if (!isValidEmail(data.email)) {
        setMessage(t("popup.AddCorrectEmail"));
        setShowSnackbar(true);
      }
    }
    setLoader(false);
  };
  return (
    <>
      <Snackbar
        setShowSnackbar={setShowSnackbar}
        showSnackbar={showSnackbar}
        message={message}
      />
      <div className="login__section">
        {/* <div className="login__img">
          <img src="img/loginimg.png" alt="Food 3d" />
        </div> */}
        <form className="login__form">
          <h1 className="title login__title">
            {t("Login.WelcomeBackTo")}
            <br />
            <span>{process.env.REACT_APP_NAME}</span>
        
          </h1>
          <div className="form__inputs">
            <div className="input">
              <label htmlFor="input">
                <div>
                  {t("Login.Email")}
                  <span>*</span>
                </div>
              </label>
              <input
                id="emailInput"
                type="text"
                name="email"
                value={data.email}
                placeholder={t("Login.Email")}
                onChange={handleInputChange}
              />
            </div>
            <div className="input relative__input">
              <label htmlFor="input">
                <div>
                  {t("Login.Password")}
                  <span>*</span>
                </div>
                <Link to="/forgetPassword" className="underline">
                  {t("Login.ForgetPassword")}
                </Link>
              </label>
              <input
                id="passwordInput"
                type={showPassword ? "text" : "password"}
                name="password"
                value={data.password}
                placeholder={t("Login.Password")}
                onChange={handleInputChange}
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="show-hide-button hideshow"
              >
                {showPassword ? (
                  <i className="ri-eye-line"></i>
                ) : (
                  <i className="ri-eye-off-line"></i>
                )}
              </button>
            </div>
            <div className="checkbox">
              <input type="checkbox" />
              {t("Login.Stay")}
              <span className="underline">{t("Login.LoggedIn")}</span>
            </div>
          </div>
          <button className="btn" onClick={log1}>
            {loader ? (
              <img
                style={{
                  height: "22px",
                  paddingLeft: "12px",
                  paddingRight: "12px",
                }}
                src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                alt="noImg"
              />
            ) : (
              t("Login.Login")
            )}
          </button>
          <span className="sign__up">
            {t("Login.HaveAnAccount")}
            <Link to="/signup" className="underline">
              {t("Login.SignUp")}
            </Link>
          </span>
          <div className="api__btn">
            {/* <LoginSocialFacebook
              appId="406005341780315"
              onResolve={(response) => {
                // setProfile(response.data);
              }}
              onReject={(err) => {
              }}
            >
              <h3>{"Facebook Login"}
 </h3>         
            </LoginSocialFacebook> */}
            <GoogleLogin
      onSuccess={credentialResponse => {
        const response = jwtDecode(credentialResponse.credential);
        if(response){
          signInWithGoogle(response);
        }
      }}
      onError={() => {
        console.log('Login Failed');
      }}
    />
            {/* <GoogleLogin />
            <FacebookLogin
              appId="194428200375416"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              cssclassName="facebook__btn"
              icon="ri-facebook-box-fill"
              textButton={"Facebook Login"}
            /> */}
          </div>
        </form>
      </div>
      {showPopup && (
        <>
          <div className="universal__popup">
            <h2 className="small__heading">
              <span>{t("Login.InvalidCredentials")}</span>
            </h2>
            <p className="paragraph">{t("Login.error1")}</p>
            <button className="btn" onClick={() => setShowPopup(false)}>
              {t("Login.OK")}
            </button>
          </div>
        </>
      )}
    </>
  );
}
export default Login;
