import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Config } from "../../constant/Index";
import { getLocalStorage } from "../../Component/local_storage";
function OrderDetail({ selectedOrder, selectedStatus }) {
  const { t } = useTranslation();

  const [products, setProducts] = useState();
  const [commonColor, setCommonColor] = useState("#089D49");
  const completedCircleRef = useRef(null);
  const readyCircleRef = useRef(null);
  const pendingCircleRef = useRef(null);
  const cancelledCircleRef = useRef(null);

  const setBackgroundColor = (ref, color) => {
    if (ref.current) {
      ref.current.style.backgroundColor = color;
    }
  };

  useEffect(() => {
    setProducts(selectedOrder?.order_detail);
    switch (selectedOrder?.order_status) {
      case "completed":
        setCommonColor("#089D49");
        break;
      case "ready":
        setCommonColor("#157FFF");
        break;
      case "in-process":
        setCommonColor("#157FFF");
        break;
      case "pending":
        setCommonColor("#792E1E");
        break;
      case "cancelled":
        setCommonColor("#851111");
        break;
      default:
        setCommonColor("#089D49");
    }

    // Set background color for circles
    setBackgroundColor(
      completedCircleRef,
      selectedOrder?.order_status === "completed" ? "#089D49" : ""
    );
    setBackgroundColor(
      readyCircleRef,
      selectedOrder?.order_status === "ready" ||
        selectedOrder?.order_status === "in-process"
        ? "#157FFF"
        : ""
    );
    setBackgroundColor(
      pendingCircleRef,
      selectedOrder?.order_status === "pending" ? "#792E1E" : ""
    );
    setBackgroundColor(
      cancelledCircleRef,
      selectedOrder?.order_status === "cancelled" ? "#851111" : ""
    );
  }, [selectedOrder]);

  if (!selectedOrder) {
    return (
      <div className="order__detail2" style={{ width: "70%" }}>
        {t("OrderDetail.NoOrder")}
      </div>
    );
  }

  const getStatusIcon = (orderStatus) => {
    switch (orderStatus) {
      case "pending":
        return "img/pending.png";
      case "completed":
        return "img/ordercomplete.png";
      case "cancelled":
        return "img/cancelled.png";
      case "ready":
      case "in-process":
        return "img/ready.png";
      case "deleted":
        return "img/deleteorder.png";
      default:
        return null;
    }
  };

  const formattedDate = new Date(selectedOrder.created_at).toLocaleString(
    undefined,
    {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }
  );

  return (
    <div
      className="order__detail2"
      style={{
        border: `1px solid ${commonColor}`,
      }}
    >
      <span
        className="order__id__detail padding"
        style={{ background: commonColor, color: "var(--white-color)" }}
      >
        {t("OrderDetail.OrderId")} :{" "}
        {selectedOrder?.id.toString().padStart(6, "0")}
      </span>
      <img src={getStatusIcon(selectedOrder?.order_status)} alt="noImg" />

      <div className="order__alldata">
        <div className="status__support">
          <span className="order__id__detail">
            {t("OrderDetail.Preparing")}
          </span>
          <a  href="@" className="btn">
            {t("OrderDetail.ContactSupport")}
          </a>
        </div>

        <div className="status__detail" style={{ backgroundColor: "var(--background-color)" }}>
          <div className="detailed__status__header">
            <span className="order__id__detail" style={{ color: commonColor }}>
              {selectedOrder?.order_status}
            </span>
            <span className="small__title">{formattedDate}</span>
          </div>
        </div>

        <div className="general__info">
          <span className="order__id__detail">
            {t("OrderDetail.GeneralInfo")}
          </span>
          <div className="row__infos">
            <div className="row__info row__info__hr">
              <span className="small__heading" style={{ fontSize: "20px" }}>
                {t("OrderDetail.OrderId")}
              </span>

              <span className="order__id__detail">
                {selectedOrder?.id.toString().padStart(6, "0")}
              </span>
            </div>
            <div className="row__info">
              <span className="small__heading" style={{ fontSize: "20px" }}>
                {t("OrderDetail.OrderDate")}
              </span>
              <span className="order__id__detail">{formattedDate}</span>
            </div>
          </div>
        </div>
        {selectedOrder?.comments !== "null" && (
          <div className="additional__comments">
            <span className="comment__additional__header">
              {t("OrderDetail.COMMENT")}
            </span>
            <p className="additional__paragraph">{selectedOrder?.comments}</p>
          </div>
        )}

        <div className="product__info">
          <span className="order__id__detail">
            {t("OrderDetail.ProductInfo")}
          </span>
          <div className="product">
            {products?.[0] ? (
              products?.map((dataa) => {
                const data = dataa?.product;
                return (
                  <div className="product__accessory" key={data?.id}>
                    <div className="produt">
                      <div className="product__img">
                        <img
                          className="produt__img__order"
                          // onClick={() => props.showPopup(props.id)}
                          src={`${Config.digitalOceanLinkProductLargeImg}${data?.image}`}
                          alt="product img"
                          onError={(event) =>
                          (event.target.src =
                            "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png")
                          }
                        />
                        <div className="product__name">
                          {data?.title?.[getLocalStorage("i18nextLng")]
                            ? data?.title?.[getLocalStorage("i18nextLng")]
                            : data?.title?.en}
                        </div>
                      </div>
                      <span className="type">
                        {dataa?.size?.title?.[getLocalStorage("i18nextLng")]
                          ? dataa?.size?.title?.[
                          getLocalStorage("i18nextLng")
                          ]
                          : dataa?.size?.title?.en}
                      </span>
                      <div className="product__type">
                        <span className="price">
                          <span>
                            {dataa.number_of_items} x &nbsp;
                            {dataa?.price}
                          </span>
                          <span>
                            {dataa?.price * dataa?.number_of_items}
                          </span>
                        </span>
                      </div>
                    </div>
                    {dataa?.order_accessory.map((data) => {
                      return (
                        <div className="produt__accesorie">
                          <span className="price">
                            {data?.accessory.title?.[
                              getLocalStorage("i18nextLng")
                            ]
                              ? data?.accessory.title?.[
                              getLocalStorage("i18nextLng")
                              ]
                              : data?.accessory?.title?.en}
                          </span>
                          <span className="price">
                            {data.price}
                            {Config.currency_symbol} x {data.number}{" "}
                            <span>
                              {data.price * data.number}
                              {Config.currency_symbol}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <div product__accessory>
                {t("OrderDetail.NOProductAvailable")}
              </div>
            )}
          </div>
        </div>

        <div className="payment__info">
          <span className="order__id__detail">
            {t("OrderDetail.PaymentInfo")}
          </span>
          <div className="detal">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 147 107" fill="white">
              <path d="M3 78V22C3 19.2386 5.23858 17 8 17H100C102.761 17 105 19.2386 105 22V78C105 80.7614 102.761 83 100 83H8C5.23858 83 3 80.7614 3 78Z" stroke="white" stroke-width="5" />
              <path d="M13 64V8C13 5.23858 15.2386 3 18 3H110C112.761 3 115 5.23858 115 8V64C115 66.7614 112.761 69 110 69H18C15.2386 69 13 66.7614 13 64Z" fill="#222222" stroke="white" stroke-width="5" />
              <path d="M13.5 17H114.5" stroke="white" stroke-width="5" />
              <path d="M13.5 31H114.5" stroke="white" stroke-width="5" />
              <path d="M24 44H64" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M24 55H44" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
              <rect x="85.5" y="45.5" width="59" height="59" rx="29.5" fill="#222222" stroke="white" stroke-width="5" />
              <path d="M111.667 61.4H115V75H118.333C122.015 75 125 78.0445 125 81.8C125 85.5555 122.015 88.6 118.333 88.6H115V75H111.667C107.985 75 105 71.9555 105 68.2C105 64.4445 107.985 61.4 111.667 61.4Z" fill="#222222" />
              <path d="M105 81.8C105 85.5555 107.985 88.6 111.667 88.6H118.333C122.015 88.6 125 85.5555 125 81.8C125 78.0445 122.015 75 118.333 75H111.667C107.985 75 105 71.9555 105 68.2C105 64.4445 107.985 61.4 111.667 61.4H118.333C122.015 61.4 125 64.4445 125 68.2M115 58V92" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <div className="detil__list">
              <span className="li__heading">
                {t("OrderDetail.PaymentMethod")}
              </span>
              <span className="order__id__detail ">
                {selectedOrder?.payment_type ? (
                  selectedOrder?.payment_type?.toUpperCase()
                ) : (
                  <>{t("OrderDetail.nomethodAvailable")}</>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="product__pirce">
          <div className="li">
            <span className="li__name">{t("OrderDetail.ActualPrice")}</span>
            <span className="li__price">
              {selectedOrder?.currency}
              {parseFloat(selectedOrder?.amount) +
                parseFloat(selectedOrder?.discount) +
                ".00"}
            </span>
          </div>
          {selectedOrder?.discount && (
            <div className="li">
              <span className="li__name">{t("OrderDetail.Discount")}</span>
              <span className="li__price">
                {selectedOrder?.currency}
                {selectedOrder?.discount}
              </span>
            </div>
          )}
          <div className="li">
            <span className="li__name">{t("OrderDetail.TotalPrice")}</span>
            <span className="li__price">
              {selectedOrder?.currency}
              {selectedOrder?.amount}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;
