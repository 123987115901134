import React, { Fragment, useEffect, useState } from "react";
import CartItemAccessories from "./CartItemAccessories";
import { getLocalStorage } from "../local_storage";
import { Config } from "../../constant/Index";
import PopupMessages from "../popupMassages/PopupMessages";
function CartPItem({
  item,
  setCartItems,
  cartItems,
  key,
  index,
  handleRemoveItem,
  cartItemCount,
}) {
  const [quantity, setQuantity] = useState(item?.quantity || 1);
  const [showPopup, setShowPopup] = useState(false);
  const handleDeleteButtonClick = () => {
    setShowPopup(true);
  };
  useEffect(() => {
    setQuantity(item?.quantity || 1);
  }, [cartItemCount]);

  const handleDecreaseQuantity = (itemId, sizegroupid) => {
    const updatedQuantity = quantity - 1;
    if (updatedQuantity > 0) {
      setQuantity(updatedQuantity);
    } else {
      handleDeleteButtonClick();
    }
    if (updatedQuantity > 0) {
      updateCartItems(itemId, updatedQuantity, sizegroupid);
    }
  };

  const handleIncreaseQuantity = (itemId, sizegroupid) => {
    const updatedQuantity = quantity + 1;
    setQuantity(updatedQuantity);
    updateCartItems(itemId, updatedQuantity, sizegroupid);
  };

  const updateCartItems = (itemId, updatedQuantity, sizegroupid) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item?.fakeId === itemId || item?.size === sizegroupid) {
        return {
          ...item,
          quantity: updatedQuantity,
        };
      }
      return item;
    });

    setCartItems(updatedCartItems);
    localStorage?.setItem(Config.cartItems, JSON.stringify(updatedCartItems));
  };

  return (
    <Fragment key={index}>
      {quantity && (
        <div className="menu__cart__card" >
          <div className="card__name__title__price">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "6px",
              }}
            >
              <div className="plus__or__add">
                <i
                  className="ri-add-line rotated"
                  onClick={handleDeleteButtonClick}
                ></i>
              </div>
              <span className="card__name__title">
                {quantity} x{" "}
                {(
                  item?.title?.[getLocalStorage("i18nextLng")] ||
                  item?.title?.en
                ).slice(0, 11) + "..."}
              </span>
            </div>

            <div className="add__card__product">
              <div className="add__and__minus">
                <div
                  className="plus__or__add"
                  onClick={() =>
                    handleIncreaseQuantity(item?.fakeId, item?.size)
                  }
                >
                  <i className="ri-add-line"></i>
                </div>
                <div
                  className="plus__or__add"
                  onClick={() =>
                    handleDecreaseQuantity(item?.fakeId, item?.size)
                  }
                >
                  <i className="ri-subtract-line"></i>
                </div>
              </div>
              <span className="card__product__price">
                {Config?.currency_symbol} {item?.price * quantity}
              </span>
            </div>
          </div>
          {item?.accessories && item?.accessories.length > 0 && (
            <div className="menu__cart__card__accessories">
              {item?.accessories
                ?.filter((data) => data?.quantity > 0)
                ?.map((data, index) => {
                  return (
                    <>
                      {data && (
                        <CartItemAccessories
                          item={item}
                          accessory={data}
                          setCartItems={setCartItems}
                          cartItems={cartItems}
                          index={index}
                          key={index}
                        />
                      )}
                    </>
                  );
                })}
            </div>
          )}
        </div>
      )}
      <PopupMessages
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        confirm={() => {
          handleRemoveItem(item.fakeId, item?.size);
          setShowPopup(false);
        }}
      />
    </Fragment>
  );
}

export default CartPItem;
