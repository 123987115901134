import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./user.css";
import {
  useGetLoginUserQuery,
  useUserImageUploadMutation,
  useUserPersnoalDataUpdateMutation,
  useUpdateuserpasswordMutation,
} from "../../redux/services/DZapi";

import { useTranslation } from "react-i18next";
import { Config } from "../../constant/Index";
import ReloadSnackbar from "../../Component/reloadSnackbar/ReloadSnackbar";
import ProfileTabe from "../../Component/profileTabe/ProfileTabe";
function User() {
  const { t } = useTranslation();
  const [updateMessage, setUpdateMessage] = useState("");
  const { data: getuser } = useGetLoginUserQuery();
  const [updatepassword] = useUpdateuserpasswordMutation();
  const [imageUploadMutation] = useUserImageUploadMutation();
  const [updateUPData] = useUserPersnoalDataUpdateMutation();
  const [userData, setUserData] = useState();
  const [profileImage, setProfileImage] = useState();
  const [loader, setLoader] = useState(false);
  const [showReloadSnackbar, setShowReloadSnackbar] = useState(false);
  const [data, setData] = useState({
    image: "", 
    name: "",
    email: "",
    mobile: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [imageFile, setImageFile] = useState(null);

  const showUpdateMessage = (message) => {
    setUpdateMessage(message);
    setTimeout(() => {
      setUpdateMessage("");
    }, 3000);
  };

  useEffect(() => {
    setUserData(getuser?.data?.user);
    setData({
      image: userData?.image || "",
      name: userData?.name || "",
      email: userData?.email || "",
      mobile: userData?.mobile || "",
    });
  }, [getuser, userData]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  function handleupdatepersnoaldata() {
    const psendata = {
      name: data.name,
      mobile: data.mobile,
    };
    updateUPData(psendata)
      .unwrap()
      .then((response) => {
        showUpdateMessage("User Data Updated!");
      })
      .catch((error) => {});
  }

  function handleupdatepassword() {
    const psendata = {
      password: data.oldPassword,
      new_password: data.newPassword,
      confirm_password: data.confirmPassword,
    };
    updatepassword(psendata)
      .unwrap()
      .then((response) => {
        showUpdateMessage(`${response.message}`);
      })
      .catch((error) => {
        showUpdateMessage(`${error.data.message}`);
      });
  }

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async () => {
    setShowReloadSnackbar(false)
    if (imageFile) {
      try {
        const formData = new FormData();
        formData.append("image", imageFile);
  
        const response = await imageUploadMutation(formData).unwrap();
  
        if (response?.data?.image) {
          setData({ ...data, image: response.data.image });
          showUpdateMessage(response.message);
          setShowReloadSnackbar(true)
        } else {
          showUpdateMessage('Image upload failed.');
        }
      } catch (error) {
        showUpdateMessage(`Error uploading image: ${error?.data?.message}`);
        console.error("Error uploading image:", error);
      }
    } else {
      console.warn("No image selected.");
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      if (userData?.name !== data.name || userData?.mobile !== data.mobile) {
        await handleupdatepersnoaldata();
      }
      if (imageFile) {
        await handleImageUpload();
      }
      if (data.newPassword?.length > 6) {
        await handleupdatepassword();
      }
      if (!imageFile && !data.newPassword && userData?.name === data.name && userData?.mobile === data.mobile) {
        showUpdateMessage("You may not do any Updated!");
      }
    } catch (error) {
      showUpdateMessage("Form submission failed!");
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
    <ReloadSnackbar important={true} showReloadSnackbar={showReloadSnackbar} setShowReloadSnackbar={setShowReloadSnackbar}/>
      <div className="profile__page">
        <div className="profile__section">
          <ProfileTabe/>
          <div className="section">
            <div className="image__field">
              <input
                className="file__upload"
                type="file"
                onChange={handleImageChange}
              />
              <img
                src={
                  profileImage ||
                  (data?.image?.startsWith("https://")
                    ? data?.image
                    : `${Config.digitalOceanLinkUserMediumImg}${data.image}`)
                }
                alt="product img"
                onError={(event) =>
                  (event.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png")
                }
                loading="lazy"
              />
              <i
                className="fa-regular fa-pen-to-square"
                onClick={handleImageUpload}
              />
            </div>
            <div className="inputs">
              <div className="input">
                <label htmlFor="nameInput" required="">
                  {t("Profile.name")}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="nameInput"
                  name="name"
                  placeholder={t("Profile.name")}
                  value={data.name}
                  onChange={handleChange}
                />
              </div>
              <div className="input">
                <label htmlFor="emailInput" required="">
                  {t("Profile.email")}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  id="emailInput"
                  name="email"
                  placeholder={t("Profile.email")}
                  value={data.email}
                />
              </div>
              <div className="input">
                <label htmlFor="mobileInput" required="">
                  {t("Profile.mobile_number")}
                  <span>*</span>
                </label>
                <input
                  type="mobile"
                  id="mobileInput"
                  name="mobile"
                  placeholder={t("Profile.mobile_number")}
                  value={data.mobile}
                  onChange={handleChange}
                />
              </div>
              <div className="input">
                <label htmlFor="oldPasswordInput" required="">
                  {t("Profile.old_password")}
                  <span>*</span>
                </label>
                <input
                  type="password"
                  id="oldPasswordInput"
                  name="oldPassword"
                  placeholder={t("Profile.old_password")}
                  value={data.oldPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="input">
                <label htmlFor="newPasswordInput" required="">
                  {t("Profile.new_password")}
                  <span>*</span>
                </label>
                <input
                  type="password"
                  id="newPasswordInput"
                  name="newPassword"
                  placeholder={t("Profile.new_password")}
                  value={data.newPassword}
                  onChange={handleChange}
                />
              </div>
              <div className="input">
                <label htmlFor="confirmPasswordInput" required="">
                  {t("Profile.confirm_password")}
                  <span>*</span>
                </label>
                <input
                  type="password"
                  id="confirmPasswordInput"
                  name="confirmPassword"
                  placeholder={t("Profile.confirm_password")}
                  value={data.confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="btns">
            <button className="btn" onClick={handleSubmit}>
              {loader ? (
                <img
                  style={{
                    height: "22px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911"
                  alt="noImg"
                />
              ) : (
                t("Profile.submit")
              )}
            </button>
            <Link to="/" className="btn">
              {t("Profile.cancel")}
            </Link>
          </div>
        </div>
      </div>
      {updateMessage && (
        <div className="universal__popup">
          <span className="success">{updateMessage}</span>
        </div>
      )}
    </>
  );
}

export default User;
