import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./paymentMethod.css";
import PaymentMethodsCard from "./PaymentMethodsCard";
import { useAddUserPaymentMethodsMutation } from "../../redux/services/DZapi";
import ProfileTabe from "../../Component/profileTabe/ProfileTabe";
function PaymentMethod() {
  const { t } = useTranslation();
  const [addUserPaymentMethod, responseCardSave] =
    useAddUserPaymentMethodsMutation();
  const saveCard = async () => {
    try {
      const response = await addUserPaymentMethod();

      if (response?.data?.success) {
        const link = response?.data?.data?.url;
        // Redirect the user to the specified link
        window.location.href = link;
      }
    } catch (error) {
      // setMessage(t("FormFillMassages.cardError2"), response.error);
      // setShowSnackbar(true);
      // console.error("Error updating/adding payment method:", error);
      // Handle errors if needed
    }
  };
  return (
    <>
      <div className="profile__page">
        <div className="profile__section">
          <ProfileTabe/>
          <PaymentMethodsCard
            saveCard={saveCard}
            responseCardSave={responseCardSave}
            cardHeight="500px"
            t={t}
          />
        </div>
      </div>
    </>
  );
}

export default PaymentMethod;
