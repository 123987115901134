import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  useGetLoginUserQuery,
  useGetAllProductsQuery,
  useGetRestaurantDetailQuery,
} from "../../redux/services/DZapi";
import { useTranslation } from "react-i18next";
import { usePriceForSize } from "../../Hooks/usePriceForSize";
import { getLocalStorage } from "../../Component/local_storage";
import Popup from "../../Component/popups/Popup";
import Card from "../../Component/productCard/Card.js";
import "./Home.css";
import GoogleMap from "../../Component/googlemap/GoogleMap.js";
import GoogleMapMark from "../../Component/google_map/GoogleMapMark.js";
import GallerySection from "../../Component/gallerySection/index.jsx";
import Gallery from "../../Component/gallery/Gallery.js";
import MetaTags from "../../Component/SEO/MetaTags.js";

function Index() {
  const [getPriceForSize] = usePriceForSize();
  const { t } = useTranslation();
  const { data: RestaurantDetail } = useGetRestaurantDetailQuery();

  const [proDetPopup, setProDetPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchBarActive, setSearchBarActive] = useState(false);
  const [restaurant, setRestaurant] = useState();
  const navigate = useNavigate();
  const [id, setId] = useState();
  const { data: Deals, refetch } = useGetAllProductsQuery();
  const isLoggedIn = useGetLoginUserQuery();
  const searchParams = new URLSearchParams(location.search);

  const description =`
  Satisfy your cravings in no time! Enjoy our speedy and free delivery service, 
  bringing delicious meals straight to your doorstep. Order now for a delightful dining experience.
  `


  const openPopup = (sid) => {
    setId(sid);
    setProDetPopup(true);
  };

  useEffect(() => {
    setRestaurant(RestaurantDetail?.data);
  }, [RestaurantDetail]);

  const handleSearchBarFocus = () => {
    setSearchBarActive(true);
  };

  const handleSearchBarBlur = () => {
    setSearchBarActive(false);
  };

  const redirectToMenuWithSearch = () => {
    navigate("/menu", {
      state: {
        value: searchValue,
      },
    });
  };

  function changeSearchValue(e) {
    setSearchValue(e.target.value);
  }

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const markers = [
    {
      id: 1,
      name: "Restaurant",
      position: {
        lat: RestaurantDetail?.data?.lat,
        lng: RestaurantDetail?.data?.long,
      },
    },
  ];
  return (
    <>
    
      <MetaTags  pageName={"Home"} content={description} metaTitle={"Fast Food Delivery - Get Delicious Meals Delivered Quickly | DZ Food"}  />
      {proDetPopup && <Popup sendid={id} setProDetPopup={setProDetPopup} />}
      <div className="hero__section">
        <div className="hero__img mobile">
          <img src="img/hero_img.png" alt="img" />
        </div>
        <div className="hero__content">
          <div className="sub__title">{t("Home_Page.Tagline1")}</div>
          <h1 className="title">
            {t("Home_Page.Tagline2", {
              app_name: process.env.REACT_APP_NAME,
              app_city_name: process.env.REACT_APP_CITY_NAME,
            })}
          </h1>
          <p className="paragraph">{t("Home_Page.Tagline3")}</p>
          <div className="searchbar__button">
            <div
              className={
                searchBarActive
                  ? "search__bar search__bar__active"
                  : "search__bar"
              }
            >
              <input
                type="text"
                placeholder={t("Home_Page.SearchPrompt")}
                value={searchValue}
                onChange={(e) => changeSearchValue(e)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") redirectToMenuWithSearch();
                }}
                onFocus={handleSearchBarFocus}
                onBlur={handleSearchBarBlur}
              />
              {searchValue && (
                <i
                  className="ri-close-line"
                  style={{
                    marginRight: "5px",
                    fontSize: "25px",
                    padding: "5px",
                  }}
                  onClick={() => setSearchValue("")}
                ></i>
              )}
              <i
                className="fa-solid fa-magnifying-glass"
                onClick={redirectToMenuWithSearch}
              ></i>
            </div>

            <Link to="/menu" className="btn">
              {t("Home_Page.GetStartedButton")}
            </Link>
          </div>
        </div>




        <div className="hero__img pc">
          <img src="img/hero_img.png" alt="img" />
        </div>
      </div>

      <div className="menu__tion">
        <div className="sub__title">{t("Home_Page.MenuTitle")}</div>

        {/* <h1 className="title menu__title" style={{textAlign: 'center'}}>{t("Home_Page.MenuDescription")}</h1> */}

        <div className="home__cards">
          {Deals?.data?.map((data, index) =>
            data?.state === "active" && index < 8 ? (
              <Card
                name={
                  data?.title[getLocalStorage("i18nextLng")]
                    ? data?.title[getLocalStorage("i18nextLng")]
                    : data?.title?.en
                }
                price={getPriceForSize(data)}
                image={data?.image}
                stars={data?.stars}
                id={data?.id}
                deal={data.deal}
                showPopup={openPopup}
                state={data.state}
                index={index}
              />
            ) : null
          )}
        </div>
      </div>

      <Gallery />

      <div className="contact__section">
        <div className="contact__data">
          {restaurant?.phone && !(restaurant?.phone == "null") && (
            <div className="info">
              <i className="bx bxs-phone"></i>
              <p className="contact">{restaurant?.phone}</p>
            </div>
          )}

          {restaurant?.email && !(restaurant?.email == "null") && (
            <div className="info">
              <i className="bx bxs-envelope"></i>
              <p className="contact">{restaurant?.email}</p>
            </div>
          )}

          {restaurant?.address && !(restaurant?.address == "null") && (
            <div className="info">
              <i className="bx bx-current-location"></i>
              <p className="contact">{restaurant?.address}</p>
            </div>
          )}

          {restaurant?.address && !(restaurant?.address == "null") && (
            <div className="info">
              <a
                class="btn_light"
                role="link"
                onClick={() =>
                  openInNewTab(
                    "https://www.google.com/maps/place/" + restaurant?.address
                  )
                }
              >
                {t("Home_Page.maplink")}
              </a>
            </div>
          )}
        </div>




        <div style={{ height: "100%", width: "100%" }}>
          {markers && <GoogleMapMark markers={markers} />}
        </div>
        {/* {
          restaurant && <GoogleMap restaurant={restaurant} />
        } */}
      </div>

    </>
  );
}

export default Index;