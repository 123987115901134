import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../../local_storage";
import i18next from "i18next";
import { useGetLanguagesQuery } from "../../../redux/services/DZapi";

function LangIcon({ userDropRef, showLanDrop, setShowLanDrop, placeuse }) {
  const { data: languages } = useGetLanguagesQuery();
  const [languagesData, setLanguagesData] = useState();
  const toggleLanDrop = () => {
    setShowLanDrop(!showLanDrop);
  };
  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18next.changeLanguage(getLanguageCode(storedLanguage));
    }
    const languagesArray = Object?.values(languages?.data || {});
    if (Array.isArray(languagesArray)) {
      setLanguagesData(languages?.data?.languages);
    }
  }, [languages]);
  function getLanguageCode(language) {
    const languageCodeMap = {
      English: "en",
      Danish: "dk",
      Arabic: "ar",
      Turkish: "tr",
    };
    return languageCodeMap[language];
  }
  function handleClick(lang) {

    console.log('handleClick lang');
    console.log(lang);

    // check direction and set it if ltr or rtl
    // if(lang?.direction) 

    i18next.changeLanguage(lang?.language_symbol);

    const dir = i18next.dir(i18next.language);
    document.documentElement.dir = dir;
  }
  return (
    <div className={`user__icon ${placeuse}`}  ref={userDropRef}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
        onClick={toggleLanDrop}
      >
        {placeuse == "link__lang" && (
          <span
            style={{
              color: "var(--navbar-link-color)",
            }}
          >
            Language
          </span>
        )}
        <i
          className={`cart__icon bx bx-world ${showLanDrop && "active_icon"}`}
        ></i>
      </div>
      {showLanDrop && (
        <div className="user__drop">
          {languagesData &&
            languagesData?.map((language) => {
              console.log(language?.name)
              return (
                <button
                  key={language.id}
                  className={`span ${
                    getLocalStorage("i18nextLng") === language?.language_symbol
                      ? "user__drop__active"
                      : ""
                  }`}
                  onClick={() => handleClick(language)}
                >
                  {language?.name}
                </button>
              );
            })}
        </div>
      )}
    </div>
  );
}
export default LangIcon;