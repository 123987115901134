import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCartItemCount } from "../../redux/slices/cartSlice";
import { useTranslation } from "react-i18next";
import { Config } from "../../constant/Index";
import { useGetProductByIdQuery } from "../../redux/services/DZapi";
import { usePriceForSize } from "../../Hooks/usePriceForSize";
import "./popup.css";
import { getLocalStorage } from "../local_storage";
import Loader from "../loader/Loader";
import SuggestedProduct from "../suggestedProduct/SuggestedProduct";
import Accessories from "./Accessories";
import TotalPrice from "./TotalPrice"; 
import SizeChange from "./SizeChange";

const { v4: uuidv4 } = require("uuid");
function Popup({ sendid, cartItemCount, setProDetPopup }) {
  const [showProductDetails, setShowProductDetails] = useState(true);
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [data, setData] = useState(null);
  const [img, setImg] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [selectedSizePrice, setSelectedSizePrice] = useState(0);
  const { data: productData, isLoading } = useGetProductByIdQuery(sendid);
  const accessories = productData?.data?.accessories;
  const [accessoryQuantities, setAccessoryQuantities] = useState(); 
  const [productSize, setProductSize] = useState(null);
  const [getPriceForSize] = usePriceForSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [totalPrices, setTotalPrices] = useState();

  useEffect(() => {
    if (!isLoading && productData) {
      const product = productData.data;
      setShowLoader(false);
      setData(product);
      setImg(
        productData?.data?.image
          ? Config.digitalOceanLinkProductLargeImg + productData?.data?.image
          : "https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
      );
      setProductSize(getPriceForSize(product, "size"));
    }
  }, [isLoading, productData]);

  useEffect(() => {
    setAccessoryQuantities(
      Object.fromEntries(
        accessories
          ?.filter((acc) => acc?.number > 0)
          ?.map((acc) => [acc?.accessory_id, acc?.number]) ?? []
      )
    );
  }, [productData]);

  useEffect(() => {
    const storedCartItems =
      JSON.parse(localStorage.getItem(Config.cartItems)) || [];
    setCartItems(storedCartItems);
  }, []);

  useEffect(() => {
    if (data?.prices && data?.prices.length > 0) {
      setSelectedSizePrice(getPriceForSize(data));
    } else {
      setSelectedSizePrice(0);
    }
  }, [data?.prices]);

  useEffect(() => {
    let totalPrice = selectedSizePrice * quantity;
    data?.accessories?.forEach((accessory) => {
      totalPrice += accessory.price * (accessoryQuantities[accessory?.accessory_id] ?? 0);
    });
    setTotalPrices(totalPrice);
  }, [selectedSizePrice, quantity, accessoryQuantities]);

  function closePopup() {
    setProDetPopup(false);
  }

  function handleQuantityDecrease() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  function handleQuantityIncrease() {
    setQuantity(quantity + 1);
  }

  function handleAccessoryQuantityDecrease(accessoryId) {
    if (accessoryQuantities[accessoryId] > 0) {
      setAccessoryQuantities((prevQuantities) => ({
        ...prevQuantities,
        [accessoryId]: prevQuantities[accessoryId] - 1,
      }));
    }
  }

  function handleAccessoryQuantityIncrease(accessoryId, max) {
    if (!accessoryQuantities[accessoryId] || accessoryQuantities[accessoryId] < max) {
      setAccessoryQuantities((prevQuantities) => ({
        ...prevQuantities,
        [accessoryId]: (prevQuantities[accessoryId] || 0) + 1,
      }));
    }
  }
  function handleSizeChange(value) {
    const selectedSizeId = parseInt(value);
    const selectedSizePrice =
      data?.prices?.find((item) => item.size_id === selectedSizeId)?.price || 0;
    setSelectedSizePrice(selectedSizePrice);
    setProductSize(selectedSizeId);
  }

  function addToCart() {
    const cartItems = JSON.parse(localStorage.getItem(Config.cartItems)) || [];

    const AllAccessoriesArray = data.accessories.map((item) => ({
      id: item?.accessory_id,
      title: item?.accessory?.title,
      price: item?.price,
      quantity: accessoryQuantities[item?.accessory_id],
    }));
    const AccessoriesArray = AllAccessoriesArray?.filter(
      (data) => data.quantity > 0 
    );
    const uniqueId = uuidv4();
    const newItem = {
      id: data.id,
      fakeId: `${data.id}-${uniqueId}`,
      title: data?.title,
      price: selectedSizePrice,
      quantity: quantity,
      selectedsizename: data.prices.find((item) => item.size_id === productSize)
        ?.size?.title,
      size: productSize,
      size_group_id: data.size_group_id,
      image: img,
      accessories: AccessoriesArray,
    };

    const existingItem = cartItems.find((item) => {
      const isMatchingID = item.id == data.id;
      const isMatchingSize = item.size == productSize;
      const isMatchingQuantity = item.quantity == quantity;

      if (!isMatchingID || !isMatchingSize || !isMatchingQuantity) {
        return false;
      }

      if (item.accessories.length !== AccessoriesArray.length) {
        return false;
      }

      const matchingAccessories = item.accessories.every((accItem) => {
        const existingAccessory = AccessoriesArray.find(
          (acc) => acc.id === accItem.id
        );
        if (!existingAccessory) {
          return false;
        }

        const existingAccessoryQuantity =
          accessoryQuantities[existingAccessory.id] || 0;
        return existingAccessoryQuantity == accItem.quantity;
      });

      return matchingAccessories;
    });

    if (existingItem) {
      return closePopup(false);
    }

    cartItems.push(newItem);
    localStorage.setItem(Config.cartItems, JSON.stringify(cartItems));
    const newCartItemCount = cartItemCount + 1;
    dispatch(setCartItemCount(newCartItemCount));
    setShowProductDetails(false);
  }

  return (
    <>
      <div className="popup__card">
        {showLoader ? (
          <Loader />
        ) : (
          <>
            {showProductDetails ? (
              <div className="product__detail">
                <h1 className="popup__title">
                  {data?.title[getLocalStorage("i18nextLng")]
                    ? data?.title[getLocalStorage("i18nextLng")]
                    : data?.title?.en}
                </h1>

                <p style={{ color: "var(--popup-text-color)" }}>
                  {data?.detail[getLocalStorage("i18nextLng")]
                    ? data?.detail[getLocalStorage("i18nextLng")]
                    : data?.detail?.en}
                </p>

                <SizeChange
                  data={data}
                  productSize={productSize}
                  handleSizeChange={handleSizeChange}
                />

                <div className="popup__details">
                  {data?.accessories?.[0] && (
                    <Accessories
                      t={t}
                      accessories={data?.accessories}
                      handleAccessoryQuantityDecrease={
                        handleAccessoryQuantityDecrease
                      }
                      handleAccessoryQuantityIncrease={
                        handleAccessoryQuantityIncrease
                      }
                      accessoryQuantities={accessoryQuantities}
                    />
                  )} 
                  <TotalPrice
                    t={t}
                    selectedSizePrice={selectedSizePrice}
                    totalPrices={totalPrices}
                    handleQuantityDecrease={handleQuantityDecrease}
                    handleQuantityIncrease={handleQuantityIncrease}
                    quantity={quantity}
                    data={data}
                  />
                </div>

                <div className="btns">
                  <button className="btn" onClick={addToCart}>
                    {t("prodcutPopup.addToCart")}
                  </button>
                  <button className="btn" onClick={closePopup}>
                    {t("prodcutPopup.cancel")}
                  </button>
                </div>
              </div>
            ) : (
              <SuggestedProduct
                closePopup={closePopup}
                setShowProductDetails={setShowProductDetails}
                data={data}
                cartItemCount={cartItemCount}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Popup;
