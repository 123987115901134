import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loader from "../Component/loader/Loader";
import {
  getLocalStorage,
  removeLocalStorage,
} from "../Component/local_storage";
import { Config } from "../constant/Index";
import useAuthCheck from "./useAuthCheck";

const PrivateRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const status = useAuthCheck();

  useEffect(() => {
    if (status !== null) {
      setIsLoading(false);
    }
  }, [status]);
  if (getLocalStorage(Config.userToken)) {
    if (isLoading) {
      return <Loader />;
    }
  }

  if (getLocalStorage(Config.userToken)) {
    if (status === 200) {
      return <Outlet />;
    } else {
      const activeRoute = window.location.pathname;
      removeLocalStorage(Config.userToken);
      return <Navigate to={{ pathname: "/", state: { from: activeRoute } }} />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
