import React, { useRef, useState } from "react";
import { getLocalStorage, removeLocalStorage } from "../../local_storage";
import { Config } from "../../../constant/Index";
import { Link, useNavigate } from "react-router-dom";
import { useGetLoginUserQuery } from "../../../redux/services/DZapi";
import { getAuth, signOut } from "firebase/auth";
import FavIcon from "../favicon/FavIcon";
import OrderIcon from "../ordericon/OrderIcon";
import useGetRestaurantFeatures from "../../../Hooks/useGetRestaurantFeatures"
function UserImg({
  t,
  userDropRef,
  showUserDrop,
  setShowUserDrop,
  activeRoute,
  orderDrop,
  setOrderDrop,
}) {
  const {delivery} = useGetRestaurantFeatures()
  const { data: user, isUserLoading } = useGetLoginUserQuery();
  const auth = getAuth();

  const toggleUserDrop = () => {
    setShowUserDrop(!showUserDrop);
  };
  const navigate = useNavigate();
  const logout = async () => {
    try {
      // signOut(auth);
      const response = await fetch(`${Config.serverUrl}api/logout`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(Config.userToken)}`,
        },
      });
      if (response?.status === 200) {
        localStorage.removeItem(Config.userToken);
        navigate("/");
        window.location.reload();
      } else {
        console.error("Logout Error:", response);
      }
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };
  const logoutGuest = () => {
    removeLocalStorage(Config.userTableToken);
    removeLocalStorage(Config.tableId);
    removeLocalStorage(Config.tableNumber);
    removeLocalStorage("customerName");
    removeLocalStorage("customerPhoneNumber");
    navigate("/");
  };
  const renderUserImage = (userData) => {
    const defaultImageUrl = "img/Male_Avatar.jpg";
    const imageUrl = userData?.data?.user?.image?.startsWith("https://")
      ? userData?.data?.user?.image
      : userData?.data?.user?.image
      ? `${Config.digitalOceanLinkUserMediumImg}${userData?.data?.user?.image}`
      : defaultImageUrl;

    return (
      <img
        src={imageUrl}
        className="porfile__img"
        alt="User Profile"
        onError={(event) => (event.target.src = defaultImageUrl)}
      />
    );
  };
  const navigateToLogin = () => {
    const pActiveRoute = window.location.pathname;
    removeLocalStorage(Config.userToken);
    navigate("/login", { state: { from: pActiveRoute } });
  }
  return (
    <>
      {getLocalStorage(Config.userToken) ? (
        <div className="user__icon profile__igm">
          <div className="nav__profile__img" onClick={toggleUserDrop}>
            {isUserLoading ? (
              <img
                className="porfile__img"
                src="img/loader.gif"
                alt="Loading..."
                height="100px"
              />
            ) : (
              renderUserImage(user)
            )}
          </div>

          {showUserDrop && (
            <div className="user__drop" ref={userDropRef}>
              <Link
                to="/user"
                className={`span ${
                  activeRoute == "/user" && "user__drop__active"
                } `}
              >
                <i className="fa-solid fa-user"></i>
                <p>{t("Navbar.profile")}</p>
              </Link>

              <Link
                to="/orderinfo"
                className={`span ${
                  activeRoute == "/orderinfo" && "user__drop__active"
                }`}
              >
                <i style={{fontSize: '20px'}} class="ri-git-repository-line"></i>
                <p>{t("Navbar.order_info")}</p>
              </Link>
              <FavIcon activeRoute={activeRoute} t={t} />
              <Link to="/" className="span" onClick={() => logout()}>
                <i className="fa-solid fa-arrow-right-to-bracket"></i>
                <p>{t("Navbar.logout")}</p>
              </Link>
            </div>
          )}
        </div>
      ) : getLocalStorage(Config.userTableToken) ? (
        <>
          <Link
            to="/tableorderinfo"
            className={`span `}
            style={{
              color: activeRoute == "/tableorderinfo" && "var(--primary-color)",
              fontSize: "var(--icon-size)",
            }}
          >
            <i className="ri-list-ordered"/>
          </Link>

          <Link
            to="/"
            style={{ color: "var(--primary-color)" }}
            onClick={logoutGuest}
          >
            <iconify-icon height="30px" icon="line-md:logout"/>
          </Link>
        </>
      ) : (
        <div onClick={navigateToLogin}   style={{ color: "var(--primary-color)", cursor: 'pointer' }}>
          <iconify-icon height="30px" icon="line-md:login"/>
        </div>
      )}
    </>
  );
}

export default UserImg;
