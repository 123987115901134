import React, { useEffect } from "react";
import PaymentMethodsCard from "../../../../Component/PaymentMethod/PaymentMethodsCard";
import { useTranslation } from "react-i18next";
import useGetRestaurantFeatures from "../../../../Hooks/useGetRestaurantFeatures";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../../Component/local_storage";
import { Config } from "../../../../constant/Index";
// import PaymentMethodsCard from './../../../PaymentMethod/PaymentMethodsCard';
const SelectPayment = ({
  setCreaditCardId,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  addPayment,
}) => {
  const { payLater, payment } = useGetRestaurantFeatures();
  const { t } = useTranslation();
  useEffect(() => {
    const storedSelectedPaymentMethod = getLocalStorage(
      Config.selectedPaymentMethod
    );
    if (payLater == 0) {
      setSelectedPaymentMethod("card");
    } else if (payment == 0) {
      setSelectedPaymentMethod("cod");
    } else {
      if (storedSelectedPaymentMethod) {
        setSelectedPaymentMethod(storedSelectedPaymentMethod);
      } else {
        setSelectedPaymentMethod("cod");
      }
    }
  });
  const handleSelectedPaymentMethod = (value) => {
    setSelectedPaymentMethod(value);
    setLocalStorage(Config.selectedPaymentMethod, value);
  };
  return (
    <>
      <div className="selector__containers row">
        {payLater == 1 && (
          <label htmlFor="pickUp" className="radio__selector">
            <input
              type="radio"
              name="cod"
              onClick={() => handleSelectedPaymentMethod("cod")}
              checked={selectedPaymentMethod === "cod"}
              id="pickUp"
            />
            <div className="radio__content">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"var(--primary-color)"} fill={"none"}>
                <path d="M21 7V12M3 7C3 10.0645 3 16.7742 3 17.1613C3 18.5438 4.94564 19.3657 8.83693 21.0095C10.4002 21.6698 11.1818 22 12 22L12 11.3548" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15 19C15 19 15.875 19 16.75 21C16.75 21 19.5294 16 22 15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.32592 9.69138L5.40472 8.27785C3.80157 7.5021 3 7.11423 3 6.5C3 5.88577 3.80157 5.4979 5.40472 4.72215L8.32592 3.30862C10.1288 2.43621 11.0303 2 12 2C12.9697 2 13.8712 2.4362 15.6741 3.30862L18.5953 4.72215C20.1984 5.4979 21 5.88577 21 6.5C21 7.11423 20.1984 7.5021 18.5953 8.27785L15.6741 9.69138C13.8712 10.5638 12.9697 11 12 11C11.0303 11 10.1288 10.5638 8.32592 9.69138Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 12L8 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17 4L7 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="radio__heading">{t("Checkout.POD")}</span>

              <div className="radio__description">
                {t("Checkout.PODMessage")}
              </div>
            </div>
          </label>
        )}
        {payment == 1 && (
          <label htmlFor="delivery" className="radio__selector">
            <input
              type="radio"
              name="card"
              onClick={() => handleSelectedPaymentMethod("card")}
              checked={selectedPaymentMethod === "card"}
              id="delivery"
            />
            <div className="radio__content">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"var(--primary-color)"} fill={"none"}>
                <path d="M2 12C2 8.46252 2 6.69377 3.0528 5.5129C3.22119 5.32403 3.40678 5.14935 3.60746 4.99087C4.86213 4 6.74142 4 10.5 4H13.5C17.2586 4 19.1379 4 20.3925 4.99087C20.5932 5.14935 20.7788 5.32403 20.9472 5.5129C22 6.69377 22 8.46252 22 12C22 15.5375 22 17.3062 20.9472 18.4871C20.7788 18.676 20.5932 18.8506 20.3925 19.0091C19.1379 20 17.2586 20 13.5 20H10.5C6.74142 20 4.86213 20 3.60746 19.0091C3.40678 18.8506 3.22119 18.676 3.0528 18.4871C2 17.3062 2 15.5375 2 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 16H11.5" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.5 16L18 16" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 9H22" stroke="currentColor" strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
              <span className="radio__heading">{t("Checkout.Card")}</span>

              <div className="radio__description">
                {t("Checkout.CardMessage")}
              </div>
            </div>
          </label>
        )}
      </div>
      {selectedPaymentMethod == "card" && (
        <PaymentMethodsCard
          options={true}
          setCreaditCardId={setCreaditCardId}
          addPayment={addPayment}
          t={t}
        />
      )}
    </>
  );
};
export default SelectPayment;
