
export function usePriceForSize() {

  function getPriceForSize(data, size) {
    const prices = data?.prices
    const sortedprice = prices?.filter((item)=> (item?.size?.default == "1" ))
    if(size){
      return sortedprice?.[0] ? sortedprice?.[0]?.size_id : prices?.[0]?.size_id
    }else{
      return sortedprice?.[0] ? sortedprice?.[0]?.price : prices?.[0]?.price
    }
  }

  return [getPriceForSize];
}
