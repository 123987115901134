import React, { useState, useEffect } from "react";
import Popup from "../../Component/popups/Popup";
import {
  useGetUserFavoritesQuery,
  useRemoveFromFavoritesMutation,
} from "../../redux/services/DZapi";
import Card from "../../Component/productCard/Card";
import { getLocalStorage } from "../../Component/local_storage";
import { useTranslation } from "react-i18next";
import Loader from "../../Component/loader/Loader";
import { usePriceForSize } from "../../Hooks/usePriceForSize";
function Favourite() {
  const { t } = useTranslation();
  const [fdata, setFdata] = useState([]);
  const [id, setId] = useState(null);
  const { data: favorite, isLoading: favLoading } = useGetUserFavoritesQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [gridLoaderVisible, setGridLoaderVisible] = useState(false);
  const [proDetPopup, setProDetPopup] = useState();
  const [getPriceForSize] = usePriceForSize();

  useEffect(() => {
    setFdata(favorite?.data);
  }, [favorite]);

  const openPopup = (sid) => {
    setId(sid);
    setProDetPopup(true);
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = fdata?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(fdata?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      {proDetPopup && <Popup sendid={id} setProDetPopup={setProDetPopup} />}
      <div className="menu__section">
        <div
          className="fav__title__grid"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1 style={{ color: "var(--primary-color)" }}>{t("Favourite.favourite")}</h1>
        </div>
        <div className="menu__cards">
          {favLoading || gridLoaderVisible ? (
            <Loader />
          ) : (
            currentItems?.map((item, index) => {
              const data = item.product;
              if (!data) {
                return null;
              }
              return (
                <Card
                  grid={"vertical"}
                  key={index}
                  name={
                    data?.title[getLocalStorage("i18nextLng")]
                      ? data?.title[getLocalStorage("i18nextLng")]
                      : data?.title?.en
                  }
                  price={getPriceForSize(data)}
                  image={data?.image}
                  stars={data?.stars}
                  id={data?.id}
                  deal={data.deal}
                  showPopup={openPopup}
                  state={data.state}
                />
              );
            })
          )}
        </div>
        <div className="pagination">
          {pageNumbers.map((number) => (
            <span
              key={number}
              className={`${
                currentPage === number ? "active__pagination" : ""
              }`}
              onClick={() => paginate(number)}
            >
              {number}
            </span>
          ))}
        </div>
      </div>
    </>
  );
}

export default Favourite;
