import React from "react";
import { getLocalStorage } from "../local_storage";
import { Config } from "../../constant/Index";

const Accessories = ({
  accessories,
  handleAccessoryQuantityDecrease,
  handleAccessoryQuantityIncrease,
  accessoryQuantities,
  t
}) => {
  return (
    <div className="accessories__list">
      <div className="adder__title">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
        >
          <path
            d="M18.4063 13.0625H15.5938C15.0172 13.3438 14.4688 13.5041 14.4688 14.1453V24.875C14.4688 25.0242 14.528 25.1673 14.6335 25.2728C14.739 25.3782 14.8821 25.4375 15.0313 25.4375H18.9688C19.1179 25.4375 19.261 25.3782 19.3665 25.2728C19.472 25.1673 19.5313 25.0242 19.5313 24.875V14.1453C19.5313 13.5041 18.9828 13.3438 18.4063 13.0625Z"
            fill="url(#paint0_linear_620_3006)"
          />
          <path
            d="M18.6875 11.9375H15.3125V13.0625H18.6875V11.9375Z"
            fill="url(#paint1_linear_620_3006)"
          />
          <path
            d="M17.8438 11.9375H16.1562L16.7188 8.5625H17.2813L17.8438 11.9375Z"
            fill="url(#paint2_linear_620_3006)"
          />
          <path
            d="M24.5938 13.0625H21.7813C21.2188 13.3438 20.6563 13.5041 20.6563 14.1453V24.875C20.6563 25.0242 20.7156 25.1673 20.8211 25.2728C20.9266 25.3782 21.0696 25.4375 21.2188 25.4375H25.1563C25.3055 25.4375 25.4486 25.3782 25.5541 25.2728C25.6596 25.1673 25.7188 25.0242 25.7188 24.875V14.1453C25.7188 13.5041 25.1704 13.3438 24.5938 13.0625Z"
            fill="url(#paint3_linear_620_3006)"
          />
          <path
            d="M24.875 11.9375H21.5V13.0625H24.875V11.9375Z"
            fill="url(#paint4_linear_620_3006)"
          />
          <path
            d="M24.0313 11.9375H22.3438L22.9063 8.5625H23.4688L24.0313 11.9375Z"
            fill="url(#paint5_linear_620_3006)"
          />
          <path
            d="M13.3438 14.1453V24.875C13.3438 25.0242 13.2845 25.1673 13.179 25.2728C13.0735 25.3782 12.9305 25.4375 12.7813 25.4375H8.84376C8.69458 25.4375 8.5515 25.3782 8.44601 25.2728C8.34053 25.1673 8.28126 25.0242 8.28126 24.875C8.28126 13.1469 8.20532 13.9428 8.46407 13.625C8.6272 13.4141 8.81845 13.3578 9.40626 13.0625H12.2188C12.7813 13.3438 13.3438 13.5041 13.3438 14.1453Z"
            fill="url(#paint6_linear_620_3006)"
          />
          <path
            d="M19.5313 24.0312V24.875C19.5313 25.0242 19.472 25.1673 19.3665 25.2727C19.261 25.3782 19.1179 25.4375 18.9688 25.4375H15.0313C14.8821 25.4375 14.739 25.3782 14.6335 25.2727C14.528 25.1673 14.4688 25.0242 14.4688 24.875V24.0312C14.4688 24.1804 14.528 24.3235 14.6335 24.429C14.739 24.5345 14.8821 24.5938 15.0313 24.5938H18.9688C19.1179 24.5938 19.261 24.5345 19.3665 24.429C19.472 24.3235 19.5313 24.1804 19.5313 24.0312Z"
            fill="#CF4055"
          />
          <path
            d="M25.7188 24.0312V24.875C25.7188 25.0242 25.6596 25.1673 25.5541 25.2727C25.4486 25.3782 25.3055 25.4375 25.1563 25.4375H21.2188C21.0696 25.4375 20.9266 25.3782 20.8211 25.2727C20.7156 25.1673 20.6563 25.0242 20.6563 24.875V24.0312C20.6563 24.1804 20.7156 24.3235 20.8211 24.429C20.9266 24.5345 21.0696 24.5938 21.2188 24.5938H25.1563C25.3055 24.5938 25.4486 24.5345 25.5541 24.429C25.6596 24.3235 25.7188 24.1804 25.7188 24.0312Z"
            fill="#C7CDD8"
          />
          <path
            d="M13.3438 24.0312V24.875C13.3438 25.0242 13.2845 25.1673 13.179 25.2727C13.0735 25.3782 12.9304 25.4375 12.7813 25.4375H8.84375C8.69457 25.4375 8.55149 25.3782 8.446 25.2727C8.34051 25.1673 8.28125 25.0242 8.28125 24.875V24.0312C8.28125 24.1804 8.34051 24.3235 8.446 24.429C8.55149 24.5345 8.69457 24.5938 8.84375 24.5938H12.7813C12.9304 24.5938 13.0735 24.5345 13.179 24.429C13.2845 24.3235 13.3438 24.1804 13.3438 24.0312Z"
            fill="#F6B756"
          />
          <path
            d="M12.5 11.9375H9.125V13.0625H12.5V11.9375Z"
            fill="url(#paint7_linear_620_3006)"
          />
          <path
            d="M13.1609 13.625H8.46405C8.62718 13.4141 8.81843 13.3578 9.40624 13.0625H12.2187C12.905 13.4056 13.0175 13.4366 13.1609 13.625Z"
            fill="#FFD39F"
          />
          <path
            d="M19.3484 13.625H14.6516C14.8147 13.4141 15.0059 13.3578 15.5937 13.0625H18.4062C19.0925 13.4056 19.205 13.4366 19.3484 13.625Z"
            fill="#E8677D"
          />
          <path
            d="M25.5359 13.625H20.8391C21.0022 13.4141 21.1934 13.3578 21.7812 13.0625H24.5937C25.28 13.4056 25.3925 13.4366 25.5359 13.625Z"
            fill="#DFE3EA"
          />
          <path
            d="M11.6563 11.9375H9.96875L10.5313 8.5625H11.0938L11.6563 11.9375Z"
            fill="url(#paint8_linear_620_3006)"
          />
          <path
            d="M19.1909 13.1413L18.9688 13.0288V11.9375C18.9688 11.8629 18.9391 11.7914 18.8864 11.7387C18.8336 11.6859 18.7621 11.6563 18.6875 11.6563H18.0828L17.5625 8.51752C17.5517 8.45097 17.5174 8.39051 17.4658 8.34715C17.4141 8.30378 17.3487 8.28041 17.2813 8.28127H16.7188C16.6513 8.28041 16.5859 8.30378 16.5342 8.34715C16.4826 8.39051 16.4483 8.45097 16.4375 8.51752L15.9172 11.6563H15.3125C15.2379 11.6563 15.1664 11.6859 15.1136 11.7387C15.0609 11.7914 15.0313 11.8629 15.0313 11.9375V13.0288L14.8091 13.1413C14.6226 13.2346 14.4657 13.3779 14.3559 13.5552C14.2462 13.7325 14.1879 13.9368 14.1875 14.1453V24.875C14.1875 25.0988 14.2764 25.3134 14.4346 25.4717C14.5929 25.6299 14.8075 25.7188 15.0313 25.7188H18.9688C19.1925 25.7188 19.4071 25.6299 19.5654 25.4717C19.7236 25.3134 19.8125 25.0988 19.8125 24.875V14.1453C19.8121 13.9368 19.7538 13.7325 19.6441 13.5552C19.5343 13.3779 19.3774 13.2346 19.1909 13.1413ZM16.9578 8.84377C17.0816 8.84377 16.9916 8.52877 17.5203 11.6563H16.4881L16.9578 8.84377ZM15.5938 12.2188H18.4063V12.7813H15.5938V12.2188ZM19.25 24.875C19.25 24.9496 19.2204 25.0212 19.1676 25.0739C19.1149 25.1267 19.0433 25.1563 18.9688 25.1563H15.0313C14.9567 25.1563 14.8851 25.1267 14.8324 25.0739C14.7796 25.0212 14.75 24.9496 14.75 24.875V14.1453C14.75 13.7094 15.11 13.625 15.6613 13.3438H18.3388C18.8563 13.6025 19.25 13.7066 19.25 14.1453V24.875Z"
            fill="#231F20"
          />
          <path
            d="M25.3785 13.1413L25.1563 13.0288V11.9375C25.1563 11.8629 25.1267 11.7914 25.0739 11.7387C25.0212 11.6859 24.9497 11.6563 24.8751 11.6563H24.2704L23.7501 8.51752C23.7393 8.45097 23.705 8.39051 23.6533 8.34715C23.6017 8.30378 23.5362 8.28041 23.4688 8.28127H22.9063C22.8389 8.28041 22.7734 8.30378 22.7218 8.34715C22.6702 8.39051 22.6358 8.45097 22.6251 8.51752L22.1048 11.6563H21.5001C21.4255 11.6563 21.3539 11.6859 21.3012 11.7387C21.2484 11.7914 21.2188 11.8629 21.2188 11.9375V13.0288L20.9966 13.1413C20.8101 13.2346 20.6533 13.3779 20.5435 13.5552C20.4338 13.7325 20.3754 13.9368 20.3751 14.1453V24.875C20.3751 25.0988 20.464 25.3134 20.6222 25.4717C20.7804 25.6299 20.995 25.7188 21.2188 25.7188H25.1563C25.3801 25.7188 25.5947 25.6299 25.7529 25.4717C25.9112 25.3134 26.0001 25.0988 26.0001 24.875V14.1453C25.9997 13.9368 25.9414 13.7325 25.8316 13.5552C25.7219 13.3779 25.565 13.2346 25.3785 13.1413ZM23.1454 8.84377C23.2691 8.84377 23.1791 8.52877 23.7079 11.6563H22.6757L23.1454 8.84377ZM21.7813 12.2188H24.5938V12.7813H21.7813V12.2188ZM25.4376 24.875C25.4376 24.9496 25.4079 25.0212 25.3552 25.0739C25.3024 25.1267 25.2309 25.1563 25.1563 25.1563H21.2188C21.1442 25.1563 21.0727 25.1267 21.0199 25.0739C20.9672 25.0212 20.9376 24.9496 20.9376 24.875V14.1453C20.9376 13.7094 21.2976 13.625 21.8488 13.3438H24.5263C25.0438 13.6025 25.4376 13.7066 25.4376 14.1453V24.875Z"
            fill="#231F20"
          />
          <path
            d="M13.0034 13.1413L12.7813 13.0288V11.9375C12.7813 11.8629 12.7516 11.7914 12.6989 11.7387C12.6461 11.6859 12.5746 11.6563 12.5 11.6563H11.8953L11.375 8.51752C11.3642 8.45097 11.3299 8.39051 11.2783 8.34715C11.2266 8.30378 11.1612 8.28041 11.0938 8.28127H10.5313C10.4638 8.28041 10.3984 8.30378 10.3467 8.34715C10.2951 8.39051 10.2608 8.45097 10.25 8.51752L9.72969 11.6563H9.125C9.05041 11.6563 8.97887 11.6859 8.92613 11.7387C8.87338 11.7914 8.84375 11.8629 8.84375 11.9375V13.0288L8.62156 13.1413C8.43508 13.2346 8.27821 13.3779 8.16845 13.5552C8.05869 13.7325 8.00037 13.9368 8 14.1453V24.875C8 25.0988 8.08889 25.3134 8.24713 25.4717C8.40536 25.6299 8.61997 25.7188 8.84375 25.7188H12.7813C13.005 25.7188 13.2196 25.6299 13.3779 25.4717C13.5361 25.3134 13.625 25.0988 13.625 24.875V14.1453C13.6246 13.9368 13.5663 13.7325 13.4566 13.5552C13.3468 13.3779 13.1899 13.2346 13.0034 13.1413ZM10.7703 8.84377C10.8941 8.84377 10.8041 8.52877 11.3328 11.6563H10.3006L10.7703 8.84377ZM9.40625 12.2188H12.2188V12.7813H9.40625V12.2188ZM13.0625 24.875C13.0625 24.9496 13.0329 25.0212 12.9801 25.0739C12.9274 25.1267 12.8558 25.1563 12.7813 25.1563H8.84375C8.76916 25.1563 8.69762 25.1267 8.64488 25.0739C8.59213 25.0212 8.5625 24.9496 8.5625 24.875V14.1453C8.5625 13.7094 8.9225 13.625 9.47375 13.3438H12.1513C12.6688 13.6025 13.0625 13.7066 13.0625 14.1453V24.875Z"
            fill="#231F20"
          />
          <defs>
            <linearGradient
              id="paint0_linear_620_3006"
              x1="17"
              y1="25.4375"
              x2="17"
              y2="13.0625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DA5466" />
              <stop offset="1" stop-color="#F4929C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_620_3006"
              x1="7.99999"
              y1="13.0625"
              x2="8.28124"
              y2="11.9375"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DA5466" />
              <stop offset="1" stop-color="#F4929C" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_620_3006"
              x1="7.99999"
              y1="11.9375"
              x2="8.28124"
              y2="8.5625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#DA5466" />
              <stop offset="1" stop-color="#F4929C" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_620_3006"
              x1="23.1876"
              y1="25.4375"
              x2="23.1876"
              y2="13.0625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D4D8E1" />
              <stop offset="1" stop-color="#F3F4F5" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_620_3006"
              x1="7.99999"
              y1="13.0625"
              x2="8.28124"
              y2="11.9375"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D4D8E1" />
              <stop offset="1" stop-color="#F3F4F5" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_620_3006"
              x1="7.99999"
              y1="11.9375"
              x2="8.28124"
              y2="8.5625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D4D8E1" />
              <stop offset="1" stop-color="#F3F4F5" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_620_3006"
              x1="10.8069"
              y1="25.4375"
              x2="10.8069"
              y2="13.0625"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FBCC88" />
              <stop offset="1" stop-color="#FFE5C3" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_620_3006"
              x1="10.8125"
              y1="13.0625"
              x2="10.8125"
              y2="11.9375"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FBCC88" />
              <stop offset="1" stop-color="#FFE5C3" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_620_3006"
              x1="9.96875"
              y1="10.25"
              x2="11.6563"
              y2="10.25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FBCC88" />
              <stop offset="1" stop-color="#FFE5C3" />
            </linearGradient>
          </defs>
        </svg>
        <span className="adder__text">{t("prodcutPopup.accessories")}</span>
      </div>
      {accessories?.map((item) => (
        <div className="accessories__li" key={item?.id}>
          <span className="accessories__name">
            {item?.accessory?.title?.[getLocalStorage("i18nextLng")]
              ? item?.accessory?.title?.[getLocalStorage("i18nextLng")]
              : item?.accessory?.title?.en}
          </span>
          <span className="price__text">
            <span>{Config.currency_symbol}.</span>
            {item?.price}
          </span>
          <div className="plus__minus">
            <div
              className="minus"
              onClick={() => handleAccessoryQuantityDecrease(item.accessory_id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="var(--dark-color)"
              >
                <path
                  d="M2.91663 7H11.0833"
                  stroke="var(--dark-color)"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="number">
              {accessoryQuantities[item?.accessory_id] ?? 0}
            </span>
            <div
              className="plus"
              onClick={() => handleAccessoryQuantityIncrease(item?.accessory_id, item?.max)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="var(--dark-color)"
              >
                <path
                  d="M6.99996 2.91699V11.0837M2.91663 7.00033H11.0833"
                  stroke="var(--dark-color)"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accessories;
